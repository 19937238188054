import React from "react";

/**
 * Message box component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-29
 *
 * @param {LoadingBoxProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
function LoadingBox(props: LoadingBoxProps): JSX.Element {
  return (
    <div className={`loading ${props.className}`}>
      <i className="fa fa-spinner fa-spin"></i>
    </div>
  );
}

type LoadingBoxProps = {
  className?: string;
};

export default LoadingBox;
