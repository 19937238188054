import React from "react";
import { Link } from "react-router-dom";

/**
 * Notice box component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-04
 *
 * @param {NoticeBoxProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function NoticeBox(props: MessageBoxProps): JSX.Element {
  return (
    <div className={`alert alert-${props.variant || "info"} break-words`}>
      <div className="flex justify-between">
        <div>{props.text}</div>
        {props.link && (
          <div>
            <Link
              to={props.link}
              className="font-bold hover:text-white border-l
                                    pl-3"
            >
              {props.linkTitle}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

type MessageBoxProps = {
  variant?: string;
  text?: string;
  link?: string;
  linkTitle?: string;
};
