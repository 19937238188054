import React, { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import HeaderInfo from "../../headerinfo/header-info";
import HeaderLogo from "../../headerlogo/header-logo";
import SearchBox from "../../headersearchbox/search-box";
import HeaderCallUs from "../../headercallus/header-call-us";
import HeaderGroupActions from "../../headergroupactions/header-group-actions";
import HeaderCategoryMenu from "../../headercategorymenu/header-category-menu";
import HeaderHorizontalMenu from "../../headermenu/header-horizontal-menu";
import ContainStickyHeader from "../stickyheader/contain-sticky-header";
import useLocalStorage from "../../../../hooks/use-localstorage";

/**
 * Principal header component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function PrincipalHeader(): JSX.Element {
  const [cartItems] = useLocalStorage("cartItems", []);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    /**
     * Handle resize
     *
     * @author Valentin magde <valentinmagde@gmail.com>
     * @since 2023-06-28
     *
     * @returns {void}
     */
    function handleResize(): void {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  return (
    <Fragment>
      <div className="bg-white sm:hidden xs:hidden md:hidden lg:block xl:block">
        <Transition
          appear={true}
          show={true}
          enter="transition ease-out duration-1000 transform"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          leave="transition ease-in-out duration-1000 dilay-1000 transform"
          leaveFrom="translate-y-0"
          leaveTo="-translate-y-full"
        >
          <header className="relative bg-white hidden lg:block xl:block">
            {/* Header info */}
            <HeaderInfo />
            <div
              className="flex my-8 items-center grid grid-cols-12 gap-2 px-4
              sm:px-6 lg:px-8"
            >
              {/* Logo */}
              <div className="col-span-3 xl:col-span-2">
                <HeaderLogo />
              </div>
              <div className="col-span-6 w-full xl:col-span-4">
                <SearchBox />
              </div>
              <div
                className="col-span-3 flex justify-end w-full hidden
                xl:flex"
              >
                <HeaderCallUs />
              </div>
              <div
                className="col-span-3 flex justify-end items-center
                xl:col-span-3"
              >
                <HeaderGroupActions />
                <div
                  className="hidden xl:flex justify-end items-end mt-auto
                  ml-4"
                >
                  <p className="text-black font-bold">
                    {cartItems.reduce(
                      (a: any, c: any) => a + c.price * c.qty,
                      0
                    )}{" "}
                    FCFA
                  </p>
                </div>
              </div>
            </div>
            <nav aria-label="Top" className="bg-primary">
              <div className="px-4 py-2 sm:px-6 lg:px-8">
                <div className="flex items-center space-x-8">
                  {/* All Category */}
                  <HeaderCategoryMenu />

                  {/* Menus */}
                  <HeaderHorizontalMenu textColor="text-white" />
                </div>
              </div>
            </nav>
          </header>
        </Transition>
      </div>

      <div className="sm:block xs:block md:block lg:hidden xl:hidden">
        <Transition
          appear={true}
          show={width <= 1024}
          enter="transition ease-out duration-1000 transform"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          leave="transition ease-in-out duration-1000 dilay-1000 transform"
          leaveFrom="translate-y-0"
          leaveTo="-translate-y-full"
        >
          <ContainStickyHeader />
        </Transition>
      </div>
    </Fragment>
  );
}
