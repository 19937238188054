import React from "react";
import UserRoutes from "./user.route";
import SellerRoutes from "./seller.route";
import AdminRoutes from "./admin.route";

/**
 * Route component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-30
 *
 * @returns {JSX.Element} the javaScriptXML element
 */
export default function Routers(): JSX.Element {
  return (
    <>
      <UserRoutes />
      <SellerRoutes />
      <AdminRoutes />
    </>
  );
}
