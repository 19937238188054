import { useEffect, useState } from "react";

/**
 * Use localstorage hook
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-04
 *
 * @param {string} key the localstorage key
 * @param {any} initValue the localstorage initial value
 * @returns {any} of localstorage value
 */
export default function useLocalStorage(key: string, initValue: any): any {
  const [state, setState] = useState(() => {
    const value = localStorage.getItem(key);

    if (value !== null) {
      try {
        return JSON.parse(value);
      } catch (e) {
        return value;
      }
    }

    return initValue;
  });

  useEffect(() => {
    /**
     * Listen storage change
     *
     * @author Valentin magde <valentinmagde@gmail.com>
     * * @since 2023-07-04
     *
     * @return {any} of localstorage value
     */
    const listenStorageChange = (): any => {
      setState(() => {
        const value = localStorage.getItem(key);
        if (value !== null) {
          return JSON.parse(value);
        }

        return initValue;
      });
    };

    window.addEventListener("storage", listenStorageChange);

    return () => window.removeEventListener("storage", listenStorageChange);
  }, []);

  return [state, setState];
}
