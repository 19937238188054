import React from "react";
import { Route, Routes } from "react-router-dom";
import ShopPage from "../pages/shop.page";

/**
 * Shop route
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function ShopRoute(): JSX.Element {
  return (
    <Routes>
      <Route path="/shop" key="shop" element={<ShopPage />} />
      {[
        "/shop",
        "/shop/page/:page",
        "/shop/category/:category",
        "/shop/category/:category/page/:page",
        "/shop/tag/:tag",
        "/shop/tag/:tag/page/:page",
      ].map((path) => (
        <Route path={path} key={path} element={<ShopPage />} />
      ))}
      <Route
        path="/shop/page/:page/category/:category/tag/
        :tag/name/:name/order/:order/min/:min/max/:max"
        element={<ShopPage />}
      />
      <Route path="/search/name" element={<ShopPage />}></Route>
      <Route path="/search/name/:name" element={<ShopPage />} />
    </Routes>
  );
}
