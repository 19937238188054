import React from "react";
import Banner from "../../../shared/components/banners/banner";
import { Outlet } from "react-router-dom";
import "../styles/checkout.style.scss";

/**
 * Checkout page
 *
 * @author Valentin magde <valentinmage@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function CheckoutPage(): JSX.Element {
  return (
    <div>
      <Banner title="Commander" />
      <Outlet />
    </div>
  );
}
