import React from "react";
import { Link } from "react-router-dom";
import ProductQuickviews from "../../../shared/components/product/product-quick-views";
import AddToWhishlist from "../../../shared/components/product/add-to-wishlist";
import AddToCompare from "../../../shared/components/product/add-to-compare";
import AddToCart from "../../../shared/components/product/add-to-cart";
import ProductType from "../../../shared/api/product-service/product/product.type";

/**
 * Product items component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @param {ProductItemsProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function ProductItems(props: ProductItemsProps): JSX.Element {
  const { data, layout } = props;

  return layout == "grid" ? (
    <div
      className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-4
        sm:grid-cols-3 grid-cols-1 gap-4"
    >
      {data.map((product: ProductType) => (
        // <div
        //     className="col-span-1 home-multi-carousel-image"
        //     key={product._id}>
        //   {!product.current_stock ? (
        //     <button
        //       className="absolute text-white text-xs bg-red-600 uppercase
        //         rounded-full pt-px px-2 hover:bg-red-600 z-10"
        //       disabled>
        //       <p className="pt-px">Rupture de stock</p>
        //     </button>
        //   ) : null}
        //   <Link to={`/product/${product._id}`}>
        //     <img
        //       src={product.image}
        //       alt={product.name}
        //       className="border border-gray-100"
        //     />
        //   </Link>
        //   <div className="-mt-12 flex justify-center
        // home-multi-carousel-action
        //     invisible duration-1000">
        //     <div className="m-px">
        //       <AddToWhishlistButton product={product} />
        //     </div>
        //     <div className="m-px">
        //       <AddToCompareButton product={product} />
        //     </div>
        //     <div className="m-px">
        //       <ProductQuickviews product={product} />
        //     </div>
        //   </div>
        //   <Link to={`/product/${product._id}`}>
        //     <p className="mt-10 text-center line-clamp-1">{product.name}</p>
        //   </Link>
        //   <p className="mt-2 text-secondary font-bold text-center">
        //     {product.price} FCFA
        //   </p>
        //   <p
        //     className="mt-4 h-px w-12 bg-gray-400 mx-auto price-border
        //         duration-1000"
        //     aria-hidden="true"
        //   />
        //   {/* <button
        //     onClick={() => {
        //      if (!product.current_stock) navigate(`/product/${product._id}`);
        //       else addToCartHandler(product._id);
        //     }}
        //     className={classNames(
        //       isLoading && selectedProuct == product._id
        //         ? "text-gray-400"
        //         : "hover:text-secondary",
        //         `flex justify-center items-center mt-4 mb-8 mx-auto
        //         bg-transparent font-bold text-xs text-center uppercase`
        //     )}
        //     disabled={
        //       isLoading && selectedProuct == product._id ? true : false
        //     }>
        //     {isLoading && selectedProuct == product._id ? (
        //       <div className="absolute text-black">
        //         <LoadingBox />
        //       </div>
        //     ) : null}
        //     {!product.current_stock ? "En savoir plus" : "AJOUTER AU PANIER"}

        //     <ChevronDoubleRight className="h-3 w-3 chevron -mt-px pb-px
        //         duration-500" />
        //   </button> */}
        //   <AddToCart product={product} />
        // </div>
        <div className="px-4 home-multi-carousel-image" key={product._id}>
          {product.current_stock <= 0 ? (
            <button
              className="absolute text-white text-xs bg-red-600 uppercase
                      rounded-full pt-px px-2 z-10"
              disabled
            >
              <p className="pt-px">Stock out</p>
            </button>
          ) : product.promotional ? (
            <button
              className="absolute text-white text-xs bg-secondary
                      uppercase rounded-full pt-px px-2 z-10"
              disabled
            >
              <p className="pt-px">Promo</p>
            </button>
          ) : null}
          <Link to={`/product/${product._id}`}>
            <img
              src={product.image}
              alt={product.name}
              className="border border-gray-100"
            />
          </Link>
          <div
            className="-mt-12 flex justify-center
              home-multi-carousel-action invisible duration-1000"
          >
            <div className="m-px">
              <AddToWhishlist product={product} />
            </div>
            <div className="m-px">
              <AddToCompare product={product} />
            </div>
            <div className="m-px">
              <ProductQuickviews product={product} />
            </div>
          </div>
          <Link to={`/product/${product._id}`}>
            <p className="mt-10 text-center truncate">{product.name}</p>
          </Link>
          {product.promotional ? (
            <div
              className="flex items-center mt-2
                  justify-center"
            >
              <p className="text-sm text-primary mr-4">
                <s>{product.price}&nbsp;FCFA</s>
              </p>
              <p className="text-secondary font-bold">
                {product.promotional_price}&nbsp;FCFA
              </p>
            </div>
          ) : (
            <p className="mt-2 text-secondary font-bold text-center">
              {product.price} FCFA
            </p>
          )}
          <p
            className="mt-4 h-px w-12 bg-gray-400 mx-auto price-border
                duration-1000"
            aria-hidden="true"
          />
          <AddToCart product={product} />
        </div>
      ))}
    </div>
  ) : (
    data.map((product: ProductType) => (
      <>
        <div
          className="grid w-full mb-8 grid-cols-1 gap-x-0 sm:grid-cols-12
            lg:gap-x-0"
        >
          <div
            className="h-auto home-multi-carousel-image xl:col-span-3
                lg:col-span-4 md:col-span-3 sm:col-span-4"
          >
            {product.current_stock <= 0 ? (
              <button
                className="absolute text-white text-xs bg-red-600 uppercase
                      rounded-full pt-px px-2 z-10"
                disabled
              >
                <p className="pt-px">Stock out</p>
              </button>
            ) : product.promotional ? (
              <button
                className="absolute text-white text-xs bg-secondary
                      uppercase rounded-full pt-px px-2 z-10"
                disabled
              >
                <p className="pt-px">Promo</p>
              </button>
            ) : null}
            <Link to={`/product/${product._id}`}>
              <img
                src={product.image}
                alt={product.name}
                className="h-auto object-cover object-center border
                    border-gray-100"
              />
            </Link>
            <div
              className="-mt-12 flex justify-center invisible duration-1000
                home-multi-carousel-action"
            >
              <div className="m-px">
                <AddToWhishlist product={product} />
              </div>
              <div className="m-px">
                <AddToCompare product={product} />
              </div>
              <div className="m-px">
                <ProductQuickviews product={product} />
              </div>
            </div>
          </div>
          <div
            className="xl:col-span-9 lg:col-span-8 md:col-span-9
            sm:col-span-8 ml-8"
          >
            <section
              aria-labelledby="information-heading"
              className="flex items-center h-full"
            >
              <div
                className="xl:text-left lg:text-left md:text-left
                sm:text-left text-center home-multi-carousel-image"
              >
                <Link to={`/product/${product._id}`}>
                  <p className="text-lg font-bold line-clamp-1">
                    {product.name}
                  </p>
                </Link>
                {product.promotional ? (
                  <div className="flex items-center mt-2">
                    <p className="text-sm text-primary mr-4">
                      <s>{product.price}&nbsp;FCFA</s>
                    </p>
                    <p className="text-lg text-secondary font-bold">
                      {product.promotional_price}&nbsp;FCFA
                    </p>
                  </div>
                ) : (
                  <p className="text-lg mt-2 text-secondary font-bold">
                    {product.price} FCFA
                  </p>
                )}
                {/* <p className="text-lg mt-2 text-secondary font-bold">
                  {product.price} FCFA
                </p> */}
                <p className="mt-4"> {product.short_description}</p>
                <p
                  className="mt-6 h-px w-12 bg-gray-400 price-border
                    duration-1000 xl:mx-0 lg:mx-0 md:mx-0 sm:mx-0 mx-auto"
                  aria-hidden="true"
                />
                {/* <button
                  onClick={() => addToCartHandler(product._id)}
                  className={classNames(
                    isLoading && selectedProuct == product._id
                        ? "text-gray-400"
                        : "hover:text-secondary",
                        `flex justify-center mx-auto items-center mt-6
                        bg-transparent font-bold text-xs xl:mx-0 lg:mx-0
                        md:mx-0 sm:mx-0 mx-auto`
                  )}
                  disabled={
                    isLoading && selectedProuct == product._id ? true : false
                  }>
                  {isLoading && selectedProuct == product._id ? (
                    <div className="absolute text-black">
                      <LoadingBox />
                    </div>
                  ) : null}
                  AJOUTER AU PANIER
                  <ChevronDoubleRight
                    className="h-3 w-3 chevron -mt-px pb-px duration-500" />
                </button> */}
                <AddToCart
                  product={product}
                  className="flex justify-center items-center mt-4 mb-8
                  bg-transparent font-bold text-xs text-center"
                />
              </div>
            </section>
          </div>
        </div>
      </>
    ))
  );
}

type ProductItemsProps = {
  data: any;
  layout: string;
};
