import { Observable, defer, map } from "rxjs";
import { AxiosResponse } from "axios";
import UserType from "./user.type";
import axiosInstance from "../../../utils/http-client.util";

/**
 * User service
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-05
 *
 * class UserService
 */
class UserService {
  public lang = "en";

  /**
   * Get all users
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-05
   *
   * @returns {Promise<void>} the eventual completion or failure
   */
  public showAll(): Observable<any> {
    return defer(() => axiosInstance.get(`/v1/${this.lang}/users`)).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Create new user
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-05
   *
   * @param {string} username the user's name
   * @param {string} email the user's email
   * @param {string} password the user's password
   * @returns {Observable<any>} the eventual completion or failure
   */
  public register(username: any, email: any, password: any): Observable<any> {
    return defer(() =>
      axiosInstance.post(`/v1/${this.lang}/users`, {
        username,
        email,
        password,
      })
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        // localStorage.setItem(
        //     "userInfo",
        //     JSON.stringify(axiosResponse.data.data)
        // );

        return axiosResponse.data;
      })
    );
  }

  /**
   * Subscribe to newsletter
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-30
   *
   * @param {string} email the user's email
   * @returns {Observable<any>} the eventual completion or failure
   */
  public subscribe(email: string): Observable<any> {
    return defer(() =>
      axiosInstance.post(`/v1/${this.lang}/users/subscribe`, { email })
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Get user profile
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-05
   *
   * @param {string} userId the user's id
   * @returns {Observable<any>} the eventual completion or failure
   */
  public profile(userId: string): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/user/${userId}`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Get user details by username
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-10-10
   *
   * @param {string} userName the username
   * @returns {Observable<any>} the eventual completion or failure
   */
  public showByUsername(userName: string): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/user/username/${userName}`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Update user
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-12
   *
   * @param {any} user the user's data
   * @returns {Observable<any>} the eventual completion or failure
   */
  public updateUser(user: any): Observable<any> {
    return defer(() =>
      axiosInstance.put(`/v1/${this.lang}/user/${user._id}`, user)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        const data = axiosResponse.data;

        const userInfo = {
          _id: data._id,
          name: data.username,
          email: data.email,
          gender: data.gender,
          roles: data.roles,
        };

        // Store user information
        localStorage.setItem("userInfo", JSON.stringify(userInfo));

        window.dispatchEvent(new Event("storage"));

        return axiosResponse.data;
      })
    );
  }

  /**
   * Get all genders
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-11
   *
   * @returns {Observable<any>} the eventual completion or failure
   */
  public getAllGenders(): Observable<any> {
    return defer(() => axiosInstance.get(`/v1/${this.lang}/genders`)).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Create new user
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-22
   *
   * @param {string} email the user's email
   * @returns {Observable<any>} the eventual completion or failure
   */
  public sendResetPasswordLink(email: string): Observable<any> {
    return defer(() =>
      axiosInstance.post(`/v1/${this.lang}/user/sendResetPasswordLink`, {
        email,
      })
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Reset password
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-27
   *
   * @returns {Observable<any>} the eventual completion or failure
   */
  public resetPassword({
    _id = "",
    new_password = "",
    confirm_password = "",
  } = {}): Observable<any> {
    return defer(() =>
      axiosInstance.post(`/v1/${this.lang}/user/${_id}/resetPassword`, {
        new_password,
        confirm_password,
      })
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  // export const updateUserProfile =
  // (user: any) => async (dispatch: any, getState: any) => {
  //     dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
  //     const {
  //     userSignin: { userInfo },
  //     } = getState();
  //     try {
  //     const { data } = await Axios.put(`/api/v1/users/profile`, user, {
  //         headers: { Authorization: `Bearer ${userInfo.token}` },
  //     });
  //     dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
  //     dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
  //     localStorage.setItem("userInfo", JSON.stringify(data));
  //     } catch (error: any) {
  //     const message =
  //         error.response && error.response.data.message
  //         ? error.response.data.message
  //         : error.message;
  //     dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload: message });
  //     }
  // };
  // export const updateUser =
  // (user: any) => async (dispatch: any, getState: any) => {
  //     dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
  //     const {
  //     userSignin: { userInfo },
  //     } = getState();
  //     try {
  //     const { data } = await Axios.put(`/api/v1/users/${user._id}`, user, {
  //         headers: { Authorization: `Bearer ${userInfo.token}` },
  //     });
  //     dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
  //     } catch (error: any) {
  //     const message =
  //         error.response && error.response.data.message
  //         ? error.response.data.message
  //         : error.message;
  //     dispatch({ type: USER_UPDATE_FAIL, payload: message });
  //     }
  // };
  // export const listUsers = () => async (dispatch: any, getState: any) => {
  // dispatch({ type: USER_LIST_REQUEST });
  // try {
  //     const {
  //     userSignin: { userInfo },
  //     } = getState();
  //     const { data } = await Axios.get("/api/v1/users", {
  //     headers: {
  //         Authorization: `Bearer ${userInfo.token}`,
  //     },
  //     });
  //     dispatch({ type: USER_LIST_SUCCESS, payload: data });
  // } catch (error: any) {
  //     const message =
  //     error.response && error.response.data.message
  //         ? error.response.data.message
  //         : error.message;
  //     dispatch({ type: USER_LIST_FAIL, payload: message });
  // }
  // };
  // export const deleteUser =
  // (userId: any) => async (dispatch: any, getState: any) => {
  //     dispatch({ type: USER_DELETE_REQUEST, payload: userId });
  //     const {
  //     userSignin: { userInfo },
  //     } = getState();
  //     try {
  //     const { data } = await Axios.delete(`/api/v1/users/${userId}`, {
  //         headers: { Authorization: `Bearer ${userInfo.token}` },
  //     });
  //     dispatch({ type: USER_DELETE_SUCCESS, payload: data });
  //     } catch (error: any) {
  //     const message =
  //         error.response && error.response.data.message
  //         ? error.response.data.message
  //         : error.message;
  //     dispatch({ type: USER_DELETE_FAIL, payload: message });
  //     }
  // };
  // export const listTopSellers = () => async (dispatch: any) => {
  // dispatch({ type: USER_TOPSELLERS_LIST_REQUEST });
  // try {
  //     const { data } = await Axios.get("/api/v1/users/top-sellers");
  //     dispatch({ type: USER_TOPSELLERS_LIST_SUCCESS, payload: data });
  // } catch (error: any) {
  //     const message =
  //     error.response && error.response.data.message
  //         ? error.response.data.message
  //         : error.message;
  //     dispatch({ type: USER_TOPSELLERS_LIST_FAIL, payload: message });
  // }
  // };
}

const userService = new UserService();
export default userService;
