import React, { Fragment, useRef, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ShoppingBagIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import useOutsideClick from "../../../hooks/use-outside-click";
import { classNames } from "../../../utils/helper.util";
import useLocalStorage from "../../../hooks/use-localstorage";
import RemoveFromCartButton from "../../product/remove-from-cart-button";
import CartType from "../../../api/product-service/cart/cart.type";

/**
 * Header cart component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @param {any} props the report handler
 * @returns {JSX.Element} the javascript xml element
 */
export default function HeaderCart(props: any): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [cartItems] = useLocalStorage("cartItems", []);
  const wrapperRef = useRef(null);
  const navigate = useNavigate();

  // Create a copy of the props
  const tempProps = JSON.parse(JSON.stringify(props));

  /**
   * Handle click outside
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} closed the react mouse event
   * @returns {void}
   */
  const handleClickOutside = (closed: any): void => {
    setIsOpen(!closed);
  };

  tempProps.onHandleClickOutside = handleClickOutside;
  useOutsideClick(wrapperRef, tempProps);

  /**
   * Handle button click
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @returns {void}
   */
  const buttonClicked = (): void => {
    setIsOpen(!isOpen);
  };

  /**
   * Navigate to cart page
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @returns {void}
   */
  const navigateToCartHandler = (): void => {
    navigate("/cart");
  };

  /**
   * Navigate to checkout page
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @returns {void}
   */
  const navigateToCheckoutHandler = (): void => {
    navigate("/checkout");
  };

  return (
    <Menu as="div" ref={wrapperRef} className="relative flex text-left">
      <Menu.Button className="outline-none" onClick={buttonClicked}>
        <div className="col-span-1 flex justify-center">
          <div
            className="ml-10 -mt-1 inline-block flex items-center
            justify-center h-4 w-4 bg-default font-bold rounded-full
            text-white p-3 absolute"
          >
            <span className="mt-1">
              {cartItems.reduce(
                (a: any, c: any) => parseInt(a) + parseInt(c.qty),
                0
              )}
            </span>
          </div>
          <ShoppingBagIcon
            className={classNames(
              isOpen
                ? `inline-block h-11 w-11 text-white bg-secondary rounded-full
                  p-3 cursor-pointer duration-500`
                : `inline-block h-11 w-11 text-gray-500 bg-default-rgba
                  hover:bg-secondary hover:text-white rounded-full p-3
                  cursor-pointer duration-500`
            )}
            aria-hidden="true"
          />
        </div>
      </Menu.Button>

      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute mt-5 border-2 border-x-0 border-b-0
          border-t-secondary right-0 z-10 mt-2 w-80 origin-top-right bg-white
          shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none top-9"
        >
          {cartItems.length ? (
            <div
              className="flex max-h-96 bg-white shadow-2xl overflow-auto
              duration-500"
            >
              <div className="flex-1">
                <div>
                  <div className="flow-root">
                    <ul role="list" className="divide-y divide-gray-200">
                      {cartItems.map((product: CartType) => (
                        <li
                          key={product._id}
                          className="flex py-4 px-6 items-center"
                        >
                          <RemoveFromCartButton product={product} />

                          <div className="h-23 w-23 flex-shrink-0">
                            <Link
                              to={`/product/${product._id}`}
                              onClick={() => buttonClicked()}
                            >
                              <img
                                src={product.image}
                                alt={product.name}
                                className="h-full w-full object-cover
                                  object-center"
                              />
                            </Link>
                          </div>

                          <div className="flex flex-1 flex-col">
                            <div>
                              <div className="ml-4 text-gray-900">
                                <Link
                                  className="p-0"
                                  to={`/product/${product._id}`}
                                  onClick={() => buttonClicked()}
                                >
                                  <h3 className="line-clamp-2">
                                    {product.name}
                                  </h3>
                                  <p>
                                    {product.qty} x
                                    <span
                                      className="font-bold text-secondary
                                      hover:text-ternary"
                                    >
                                      {" "}
                                      {product.price} FCFA
                                    </span>
                                  </p>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                      <div className="flex justify-between text-gray-900">
                        <p>Sous-total</p>
                        <p className="text-primary font-bold">
                          {cartItems.reduce(
                            (a: any, c: any) => a + c.price * c.qty,
                            0
                          )}{" "}
                          FCFA
                        </p>
                      </div>
                      <div className="mt-6">
                        <button
                          type="submit"
                          onClick={() => {
                            buttonClicked();
                            navigateToCheckoutHandler();
                          }}
                          className="group relative flex w-full justify-center
                            rounded-md border border-transparent bg-secondary
                            py-2 px-4 font-medium text-white hover:bg-ternary
                            focus:outline-none focus:ring-0 focus:ring-primary
                            focus:ring-offset-2"
                        >
                          Commander
                        </button>
                      </div>
                      <div
                        className="mt-6 flex justify-center text-center
                        text-sm text-gray-500"
                      >
                        <p>
                          ou &nbsp;
                          <button
                            type="button"
                            onClick={() => {
                              buttonClicked();
                              navigateToCartHandler();
                            }}
                            className="font-medium text-primary
                              hover:text-ternary"
                            // onClick={() => setOpen(false)}
                          >
                            Voir le panier
                            <span aria-hidden="true"> &rarr;</span>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex-1 px-4 py-4 sm:px-6">
              <p className="flex py-4 px-4 items-center">
                Aucun produit dans le panier !
              </p>
            </div>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
