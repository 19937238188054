import React from "react";
import { toast } from "react-toastify";
import { XCircleIcon } from "@heroicons/react/24/outline";
import cartService from "../../api/product-service/cart/cart.service";
import CartType from "../../api/product-service/cart/cart.type";

/**
 * Remove from cart button component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-29
 *
 * @param {RemoveFromCartButtonProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function RemoveFromCartButton(
  props: RemoveFromCartButtonProps
): JSX.Element {
  const product = props.product;

  /**
   * Remove product from cart
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-27
   *
   * @param {string} id the product's id
   * @return {void}
   */
  const removeFromCartHandler = (id: string): void => {
    // delete action
    cartService.removeFromCart(id).subscribe({
      next: () => {
        toast.success("Produit retiré avec succès au panier!", {
          theme: "colored",
        });
      },
      error: () => {
        toast.error("Error !", {
          theme: "colored",
        });
      },
    });
  };

  return (
    <button type="button" onClick={() => removeFromCartHandler(product._id)}>
      <XCircleIcon
        className={
          props.className ||
          `h-4 w-4 text-gray-500
          hover:text-secondary`
        }
        aria-hidden="true"
      />
    </button>
  );
}

type RemoveFromCartButtonProps = {
  product: CartType;
  className?: string;
};
