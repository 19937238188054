import React from "react";
import { Route, Routes } from "react-router-dom";
import CheckoutPage from "../pages/checkout.page";
import PrivateRoute from "../../../shared/components/route/private-route";
import PlaceOrderPage from "../place-order/pages/place-order.page";
import OrderReceivePage from "../order-receive/pages/order-receive.page";
import ScrollToTop from "../../../shared/components/others/scroll-to-top";

/**
 * Checkout route
 *
 * @author Valentin magde <valentinmage@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function CheckoutRoute(): JSX.Element {
  return (
    <Routes>
      <Route
        path="/checkout"
        element={
          <PrivateRoute path="/checkout">
            <CheckoutPage />
          </PrivateRoute>
        }
      >
        <Route index element={<PlaceOrderPage />} />
        <Route path="order-receive/:id" element={<OrderReceivePage />} />
      </Route>
    </Routes>
  );
}
