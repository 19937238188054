import React from "react";
import { classNames } from "../../../shared/utils/helper.util";
import { Link, useLocation } from "react-router-dom";
import FaqMenuItems from "../../../resources/navigations/faq-menu-items.json";
import DynamicHeroIcon from "../../../shared/components/others/dynamic-heroIcon";

/**
 * Faq menu component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-07
 *
 * @param {FaqMenuProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function FaqMenu(props: FaqMenuProps): JSX.Element {
  let { hash } = useLocation();
  hash = hash && hash.substring(1);

  return (
    <div>
      {FaqMenuItems.map((item: any) => (
        <Link
          key={item.name}
          to={item.url}
          onClick={() => props.slug(item.slug)}
          className={classNames(
            hash === item.slug || (hash === "" && item.slug === "order")
              ? "text-secondary"
              : "text-gray-500",
            `flex items-center block py-3 border border-x-0
              border-t-0 border-b-gray-200`
          )}
        >
          <div className="w-full flex">
            <div className="w-full">{item.name}</div>
            <div
              className="float-right flex-1 justify-end
                    items-center right-0"
            >
              {item.icon && (
                <DynamicHeroIcon icon={item.icon} className="w-4 h-4" />
              )}
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}

type FaqMenuProps = {
  slug: any;
};
