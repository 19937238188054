import React from "react";
import HomeRoute from "../modules/home/routes/home.route";
import ShopRoute from "../modules/shop/routes/shop.route";
import ProductRoute from "../modules/product/routes/product.route";
import RegisterRoute from "../modules/register/routes/register.route";
import CartRoute from "../modules/cart/routes/cart.route";
import CheckoutRoute from "../modules/checkout/routes/checkout.route";
// import OrderRoute from "../modules/order/routes/order.route";
import SigninRoute from "../modules/signin/routes/signin.route";
import CustomerRoute from "../modules/customer/routes/customer.route";
import ForgotpasswordRoute from "../modules/forgot-password/routes/forgot-password.route";
import ResetpasswordRoute from "../modules/reset-password/routes/reset-password.route";
import WishlistRoute from "../modules/wishlist/routes/wishlist.route";
import BlogRoute from "../modules/blog/routes/blog.route";
import ContactRoute from "../modules/contact-us/routes/contact.route";
import AboutRoute from "../modules/about-us/routes/about.route";
import FaqRoute from "../modules/faq/routes/faq.route";
import SchoolProgramRoute from "../modules/school-program/routes/school-program.route";

/**
 * User route component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-27
 *
 * @returns {JSX.Element} the javaScriptXML element
 */
export default function UserRoutes(): JSX.Element {
  return (
    <>
      <HomeRoute />
      <ShopRoute />
      <SchoolProgramRoute />
      <ProductRoute />
      <SigninRoute />
      <RegisterRoute />
      <ForgotpasswordRoute />
      <ResetpasswordRoute />
      <CartRoute />
      <WishlistRoute />
      <CheckoutRoute />
      {/* <OrderRoute /> */}
      <CustomerRoute />
      <BlogRoute />
      <ContactRoute />
      <AboutRoute />
      <FaqRoute />
    </>
  );
}
