import { Buffer } from "buffer";

/**
 * Join classes
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-27
 *
 * @param {any} classes the string class
 * @returns {string} of classes
 */
export function classNames(...classes: any): string {
  return classes.filter(Boolean).join(" ");
}

/**
 * Rounds the string to a specified number of decimals
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-27
 *
 * @param {number} num the number value
 * @param {number} decimal the decimal value
 *
 * @returns {number} of value
 */
export const toPrice = (num: number, decimal: number): number => {
  return Number(num.toFixed(decimal));
};

/**
 * Get window size
 *
 * @author Valentin mage <valentinmage@gmail.com>
 * @since 2023-06-28
 *
 * @returns {any} of innerWidth and innerHeight
 */
export function getWindowSize(): any {
  const { innerWidth, innerHeight } = window;

  return { innerWidth, innerHeight };
}

/**
 * Decode token
 *
 * @author Valentin Magde <valentinmagde@gmail.com>
 * @since 2023-06-02
 *
 * @param {string} token the user token
 * @returns {any} the token payload
 */
export const parseJwt = (token: string): any => {
  return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
};

/**
 * This method returns the different custom error messages for the form fields.
 *
 * @author Valentin Magde <valentinmagde@gmail.com>
 * @since 2023-07-15
 *
 * @param {any} touchedFields the form fields propreties
 * @param {any} isFieldInError This method indicates if a specific field has an
 * error.
 * @param {any} getErrorsInField This method returns the error messages bound
 * @param {boolean} checkTouchedFields This parameter determines whether or not
 * form field properties are touched.
 * @returns {Array<string>} Form field error messages
 */
export const getFieldsCustomErrorMessages = (
  touchedFields: any,
  isFieldInError: any,
  getErrorsInField: any,
  checkTouchedFields = true
): Array<string> => {
  const errorMessages: Array<string> = [];

  for (const key in touchedFields) {
    if (checkTouchedFields) {
      if (touchedFields[key] && isFieldInError(key)) {
        getErrorsInField(key)
          .join("\n")
          .split("\n")
          .map((s: string) => errorMessages.push(s));
      }
    } else {
      getErrorsInField(key)
        .join("\n")
        .split("\n")
        .map((s: string) => errorMessages.push(s));
    }
  }

  return errorMessages;
};

/**
 * Format a date as a string value appropriate to the host
 * environment's current locale.
 *
 * @author Valentin Magde <valentinmagde@gmail.com>
 * @since 2023-10-01
 *
 * @param {any} date the date to formate
 * @returns {strind} of a date as a string value appropriate
 * to the host environment's current locale.
 */
export const _date = (date: any) => {
  const locale = "fr";

  const event = new Date(date);
  const options: any = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return event.toLocaleDateString(locale === "fr" ? "fr" : "en-Us", options);
};

/**
 * Format numbers as currency strings
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-01
 * @param {number} number the amount to format
 * @param {string} devise the currency
 * @returns {string} of number formated
 */
export const numberFormatter = (number: number, devise?: string): string => {
  const locale = "fr";

  if (number == null || isNaN(number)) number = 0;

  return number.toLocaleString(locale === "fr" ? "fr" : "en-Us", {
    style: "currency",
    currency: devise || "XAF",
    minimumFractionDigits: 2,
  });
};
