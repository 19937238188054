import React from "react";
import { Link } from "react-router-dom";
import AddToWhishlist from "../../../shared/components/product/add-to-wishlist";
import AddToCompare from "../../../shared/components/product/add-to-compare";
import ProductQuickviews from "../../../shared/components/product/product-quick-views";
import AddToCart from "../../../shared/components/product/add-to-cart";
import ProductType from "../../../shared/api/product-service/product/product.type";

/**
 * Related product component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @param {RelatedProductProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function RelatedProduct(
  props: RelatedProductProps
): JSX.Element {
  const product = props.product;

  return (
    <div>
      <div
        className="flex items-center justify-center xl:justify-between
          lg:justify-between md:justify-between sm:justify-between"
      >
        <div
          className="w-full flex items-center hidden xl:block lg:block
          md:block sm:block pr-8"
        >
          <p className="w-full border-b border-gray-200"></p>
        </div>
        <div className="flex items-center">
          <p
            className="whitespace-nowrap xl:text-xl lg:text-xl md:text-xl
              sm:text-xl text-xl text-primary font-bold"
          >
            Produits connexes
          </p>
        </div>
        <div
          className="w-full flex items-center hidden xl:block lg:block
          md:block sm:block pl-8"
        >
          <p className="w-full border-b border-gray-200"></p>
        </div>
      </div>

      <div
        className="mt-6 grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-4
        sm:grid-cols-3 grid-cols-1 gap-8"
      >
        {product.related_products.slice(0, 5).map((product: ProductType) => (
          // <div
          //   className="col-span-1 home-multi-carousel-imagde"
          //   key={product._id}>
          //   <Link to={`/product/${product._id}`}>
          //     <img
          //       src={product.image}
          //       alt={product.name}
          //       className="border border-gray-100"
          //     />
          //   </Link>
          //   <div className="-mt-12 flex justify-center invisible
          //    duration-1000
          //     home-multi-carousel-action">
          //     <div className="m-px">
          //       <AddToWhishlist product={product} />
          //     </div>
          //     <div className="m-px">
          //       <AddToCompare product={product} />
          //     </div>
          //     <div className="m-px">
          //       <ProductQuickviews product={product} />
          //     </div>
          //   </div>
          //   <Link to={`/product/${product._id}`}>
          //     <p className="mt-10 text-center line-clamp-1">
          //       {product.name}
          //    </p>
          //   </Link>
          //   <p className="mt-2 text-secondary font-bold text-center">
          //     {product.price} FCFA
          //   </p>
          //   <p
          //     className="mt-4 h-px w-12 bg-gray-400 mx-auto price-border
          //       duration-1000"
          //     aria-hidden="true"
          //   />
          //   <button
          //     onClick={() => addToCartHandler(product._id)}
          //     className={classNames(
          //       isLoading && selectedProuct == product._id
          //         ? "text-gray-400"
          //         : "hover:text-secondary",
          //         `flex justify-center items-center mt-4 mb-8 mx-auto
          //         bg-transparent font-bold text-xs text-center`
          //     )}
          //     disabled={
          //       isLoading && selectedProuct == product._id ? true : false
          //     }>
          //     {isLoading && selectedProuct == product._id ? (
          //       <div className="absolute text-black">
          //         <LoadingBox />
          //       </div>
          //     ) : null}
          //     AJOUTER AU PANIER
          //     <ChevronDoubleRight className="h-3 w-3 chevron -mt-px pb-px
          //       duration-500" />
          //   </button>
          // </div>
          <div className="px-4 home-multi-carousel-image" key={product._id}>
            {!product.current_stock ? (
              <button
                className="absolute text-white text-xs bg-red-600 uppercase
                      rounded-full pt-px px-2 z-10"
                disabled
              >
                <p className="pt-px">Stock out</p>
              </button>
            ) : product.promotional ? (
              <button
                className="absolute text-white text-xs bg-secondary
                      uppercase rounded-full pt-px px-2 z-10"
                disabled
              >
                <p className="pt-px">Promo</p>
              </button>
            ) : null}
            <Link to={`/product/${product._id}`}>
              <img
                src={product.image}
                alt={product.name}
                className="border border-gray-100"
              />
            </Link>
            <div
              className="-mt-12 flex justify-center
              home-multi-carousel-action invisible duration-1000"
            >
              <div className="m-px">
                <AddToWhishlist product={product} />
              </div>
              <div className="m-px">
                <AddToCompare product={product} />
              </div>
              <div className="m-px">
                <ProductQuickviews product={product} />
              </div>
            </div>
            <Link to={`/product/${product._id}`}>
              <p className="mt-10 text-center truncate">{product.name}</p>
            </Link>
            {product.promotional ? (
              <div
                className="flex items-center mt-2
                  justify-center"
              >
                <p className="text-sm text-primary mr-4">
                  <s>{product.price}&nbsp;FCFA</s>
                </p>
                <p className="text-secondary font-bold">
                  {product.promotional_price}&nbsp;FCFA
                </p>
              </div>
            ) : (
              <p className="mt-2 text-secondary font-bold text-center">
                {product.price} FCFA
              </p>
            )}
            <p
              className="mt-4 h-px w-12 bg-gray-400 mx-auto price-border
                duration-1000"
              aria-hidden="true"
            />
            <AddToCart product={product} />
          </div>
        ))}
      </div>
    </div>
  );
}

type RelatedProductProps = {
  product: ProductType;
};
