import { PhoneIcon } from "@heroicons/react/24/outline";
import React from "react";

/**
 * Header call us component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function HeaderCallUs(): JSX.Element {
  return (
    <div
      className="flex items-center grid grid-cols-1 gap-2 h-11
            xl:grid-rows-2 lg:grid-rows-2 md:grid-rows-2 xl:grid-flow-col
            lg:grid-flow-col md:grid-flow-col"
    >
      <div className="row-span-3 flex justify-center items-center mr-1">
        <PhoneIcon className="h-7 w-7 cursor-pointer text-secondary" />
      </div>
      <div className="col-span-2 font-bold text-xs mask-path">
        APPELEZ-NOUS 24/7
      </div>
      <div className="row-span-2 col-span-2 text-secondary text-lg">
        (+237) 698 782 873
      </div>
    </div>
  );
}
