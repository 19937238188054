import React from "react";

/**
 * Footer payment methodcomponent
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function FooterPaymentMethod(): JSX.Element {
  return (
    <div>
      <img src="/assets/images/others/payment_1.png" />
    </div>
  );
}
