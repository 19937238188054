import { useEffect } from "react";

/**
 * Use outside click hook
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-29
 *
 * @param {any} ref the element reference
 * @param {any} props the shared properties
 * @returns {JSX.Element} the javaScriptXML element
 */
export default function useOutsideClick(ref: any, props: any) {
  useEffect(() => {
    /**
     * Set given props to true if clicked on outside of element
     *
     * @author Valentin magde <valentinmagde@gmail.com>
     * @since 2023-06-29
     *
     * @param {any} event the react mouse event
     * @returns {void}
     */
    function handleClickOutside(event: any): void {
      if (ref.current && !ref.current.contains(event.target)) {
        props.onHandleClickOutside(true);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
