import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routers from "./routes/app.route";
import Footer from "./shared/components/footer/footer";
import Header from "./shared/components/header/header";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "./shared/components/others/scroll-to-top";

/**
 * Main application
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-27
 *
 * @returns {JSX.Element} th jsx element
 */
export default function App(): JSX.Element {
  return (
    <BrowserRouter>
      <div className="grid-container">
        {/* Header */}
        <Header />

        {/* Main */}
        <main>
          <ScrollToTop>
            <Routers />
          </ScrollToTop>

          {/* Notify */}
          <ToastContainer />
        </main>

        {/* Footer */}
        <Footer />
      </div>
    </BrowserRouter>
  );
}
