import React, { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

/**
 * Search box component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
function SearchBox(): JSX.Element {
  const navigate = useNavigate();
  const [queryName, setQueryName] = useState("");

  const { category, tag, page } = useParams();
  const [search] = useSearchParams();
  const name = search.get("name") || "";
  const rating = search.get("rating") as any;
  const order = search.get("order") || "";

  /**
   * Submit handler
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-10-07
   *
   * @param {any} e the react mouse event
   * @returns {void}
   */
  const submitHandler = (e: any): void => {
    e.preventDefault();

    navigate(getFilterUrl({ name: queryName }));
  };

  /**
   * Get filter url
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} filter the filter parameters
   * @returns {string} the shop page url
   */
  const getFilterUrl = (filter: any): string => {
    console.log("name" in filter);
    const filterName = filter.name !== undefined ? filter.name : name;
    const filterRating = filter.rating || rating;
    const sortOrder = filter.order || order;

    const filterPage =
      filter.category || filter.tag ? undefined : filter.page || page;

    const filterCategory = filter.tag ? "" : filter.category || category;

    const filterTag = filter.category ? "" : filter.tag || tag;

    let url = "/blog/post";
    let params = "";

    params +=
      (filterName &&
        (params ? `&name=${filterName}` : `?name=${filterName}`)) ||
      "";

    params +=
      (sortOrder && (params ? `&order=${sortOrder}` : `?order=${sortOrder}`)) ||
      "";

    params +=
      (filterRating &&
        (params ? `&rating=${filterRating}` : `?rating=${filterRating}`)) ||
      "";

    url += (filterCategory && `/category/${filterCategory}`) || "";
    url += (filterTag && `/tag/${filterTag}`) || "";
    url += (filterPage && `/page/${filterPage}`) || "";

    url += params;

    return url;
  };

  return (
    <div className="grid grid-cols-4 gap-4 relative">
      <div className="col-span-4">
        <form className="search" onSubmit={submitHandler}>
          <div className="flex justify-start items-center">
            <div className="w-full flex justify-between">
              <input
                type="text"
                name="q"
                id="q"
                placeholder="Recherche..."
                autoComplete="off"
                className="bg-gray-100 border-0 h-11 w-full rounded-full pl-12
                  pb-1 text-gray-900 placeholder-gray-400 focus:ring-secondary
                  focus:ring-1 mask-path"
                onFocus={() => {
                  // setClose(false);
                }}
                onChange={(e) => {
                  setQueryName(e.target.value);
                }}
              />
              <button
                className="absolute right-0 bg-gray-100 border border-secondary
                  border-0 hover:border-l-0 hover:text-secondary text-black
                  font-bold h-11 rounded-full rounded-l-none pt-1"
                type="submit"
              >
                <span className="flex items-center px-4">
                  <span className="flex items-center">Recherche</span>
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SearchBox;
