import React from "react";
import { Route, Routes } from "react-router-dom";
import WishlistPage from "../pages/wishlist.page";

/**
 * Wishlist routes
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-27
 *
 * @returns {JSX.Element} the jsx element
 */
export default function WishlistRoute(): JSX.Element {
  return (
    <Routes>
      <Route path="/wishlist" element={<WishlistPage />} />
    </Routes>
  );
}
