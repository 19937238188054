import React from "react";
import NoticeBox from "../../../shared/components/others/notice-box";

/**
 * Payment method component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-11
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function PaymentMethod(): JSX.Element {
  return (
    <div>
      <NoticeBox
        text="Aucun moyen de paiement actuellement disponible."
        link="/shop"
        linkTitle="Parcourir les produits"
      />
    </div>
  );
}
