import React, { useEffect, useState } from "react";
import {
  CalendarIcon,
  ChatBubbleLeftRightIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { Link, useParams } from "react-router-dom";
import LoadingBox from "../../../shared/components/others/loading-box";
import MessageBox from "../../../shared/components/others/message-box";
import { _date } from "../../../shared/utils/helper.util";
import postService from "../../../shared/api/blog-service/post/post.service";
import PostType from "../../../shared/api/blog-service/post/post.type";
import Reviews from "./reviews.component";

/**
 * Post filter component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-07
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function PostDetails(): JSX.Element {
  const [post, setPost] = useState<PostType>();
  const [postError, setPostError] = useState("");
  const [postIsLoading, setPostIsLoading] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    postService.getById(id).subscribe({
      next: (data) => {
        setPostIsLoading(false);
        setPost(data);
      },
      error: (e) => {
        setPostIsLoading(false);
        setPostError(e);
        console.error(e);
      },
    });
  }, [id]);

  return (
    <>
      {postIsLoading ? (
        <LoadingBox></LoadingBox>
      ) : postError ? (
        <MessageBox variant="danger">{postError}</MessageBox>
      ) : (
        <div className="mb-12">
          {post?.categories.map((category: any) => (
            <Link
              to={`/blog/post/category/${category.slug}`}
              key={category._id}
            >
              <span
                className="inline-flex items-center mr-2
                                    rounded-sm bg-secondary px-2 py-1 text-xs
                                    font-medium text-white ring-1 ring-inset
                                    ring-gray-500/10 hover:bg-ternary"
              >
                {category.name}
              </span>
            </Link>
          ))}
          <div className="flex text-gray-500 text-xs mt-4">
            <CalendarIcon className="w-4" />
            <span className="ml-1 pt-1 underline">
              {_date(post?.created_at)}
            </span>
            &nbsp;<span className="pt-1"> /</span>&nbsp;
            <UserIcon className="h-4 w-4" />
            <span className="ml-1 pt-1 underline">Posté par {post?.user}</span>
            &nbsp; <span className="pt-1"> /</span> &nbsp;
            <ChatBubbleLeftRightIcon className="w-4" />
            <span className="ml-1 pt-1">0</span>
          </div>
          <p
            className="mt-4 text-primary text-3xl font-bold
                            pb-4 border-b border-dotted border-b-gray-200"
          >
            {post?.name}
          </p>
          <img src={post?.image} alt={post?.name} className="w-full mt-8" />
          <div className="mt-6">
            <p className="mt-6 text-justify leading-8">{post?.description}</p>
          </div>
          <div className="mt-6">
            <p className="text-primary font-bold text-lg mb-4">
              Laisser un commentaire
            </p>
            <Reviews post={post} />
          </div>
        </div>
      )}
    </>
  );
}
