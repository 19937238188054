import { Observable, defer, map } from "rxjs";
import { AxiosResponse } from "axios";
import axiosInstance from "../../../utils/http-client.util";

/**
 * Cart service
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-02
 *
 * class CartService
 */
class CartService {
  public lang = "en";

  /**
   * Add to cart
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-02
   *
   * @param {any} productId the product's id
   * @param {any} qty the product's quantity
   * @returns {Observable<any>} the eventual completion or failure
   */
  public addToCart(productId: any, qty: any): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/product/${productId}`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        const data = axiosResponse.data;
        const payload = {
          name: data.name,
          image: data.image,
          price: data.price,
          vendor: data.vendor,
          current_stock: data.current_stock,
          _id: data._id,
          qty,
        };

        let cartItems: Array<any> = [];

        const cart: Array<any> = localStorage.getItem("cartItems")
          ? JSON.parse(localStorage.getItem("cartItems") as any)
          : [];

        if (cart.some((elt) => elt._id == productId)) {
          cartItems = cart.map((item: any) => {
            if (item._id == productId) item.qty = qty;

            return item;
          });
        } else cartItems = [...cart, payload];

        //Set products to local storage
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
        window.dispatchEvent(new Event("storage"));

        return cartItems;
      })
    );
  }

  /**
   * Remove a product from cart
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-02
   *
   * @param {any} productId the product's id
   * @returns {Observable<any>} the eventual completion or failure
   */
  public removeFromCart(productId: any): Observable<any> {
    //Get products from local storage
    const cart: any = localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems") as any)
      : [];

    // Remove product from storage
    const cartItems = cart.filter((item: any) => item._id != productId);

    // Update products from storage
    if (!cartItems.length) localStorage.removeItem("cartItems");
    else localStorage.setItem("cartItems", JSON.stringify(cartItems));

    window.dispatchEvent(new Event("storage"));

    return new Observable((subscriber) => {
      subscriber.next(cartItems);
    });
  }

  /**
   * Remove all products from cart
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-09-29
   *
   * @returns {Observable<any>} the eventual completion or failure
   */
  public removeAllFromCart(): Observable<any> {
    // Remove products from storage
    localStorage.removeItem("cartItems");

    window.dispatchEvent(new Event("storage"));

    return new Observable((subscriber) => {
      subscriber.next([]);
    });
  }
}

const cartService = new CartService();
export default cartService;
