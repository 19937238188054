import React from "react";
import { Route, Routes } from "react-router-dom";
import { AuthLayout } from "../../../shared/components/others/auth-layout";
import LoggedRoute from "../../../shared/components/route/logged-route";
import ResetPasswordPage from "../pages/reset-password.page";

/**
 * Reset password route
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-27
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function ResetpasswordRoute(): JSX.Element {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route
          path="/reset-password/:id"
          element={
            <LoggedRoute>
              <ResetPasswordPage />
            </LoggedRoute>
          }
        />
      </Route>
    </Routes>
  );
}
