import React, { Fragment, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import MessageBox from "../../../shared/components/others/message-box";
import {
  classNames,
  getFieldsCustomErrorMessages,
} from "../../../shared/utils/helper.util";
import useLocalStorage from "../../../shared/hooks/use-localstorage";
import countryService from "../../../shared/api/user-service/country/country.service";
import { useValidation } from "react-simple-form-validator";
import LoadingBox from "../../../shared/components/others/loading-box";
import { useLocation } from "react-router-dom";
import userService from "../../../shared/api/user-service/user/user.service";
import { toast } from "react-toastify";

/**
 * Address form
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-05
 *
 * @returns {JSX.Element} the javaScriptXML element
 */
export default function AddressForm(): JSX.Element {
  const [userInfo] = useLocalStorage("userInfo", null);
  const [user, setUser] = useState<any>();
  const { pathname } = useLocation();
  const pathNames = pathname.split("/");
  const lastPathName = pathNames.slice(-1).toString();

  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState(userInfo ? userInfo.email : "");
  const [state, setState] = useState("") as any;
  const [selectedCountry, setSelectedCountry] = useState("") as any;
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const [countryLoading, setCountryIsLoading] = useState(false);
  const [countries, setCountries] = useState<any>([]);
  const [countryQuery, setCountryQuery] = useState("");
  const [stateQuery, setStateQuery] = useState("");
  const [fieldErrorMessages, setFieldErrorMessages] = useState<Array<string>>(
    []
  );

  const [touchedFields, setTouchedFields] = useState({
    firstName: false,
    lastName: false,
    country: false,
    state: false,
    address: false,
    city: false,
    postalCode: false,
    phone: false,
    email: false,
  });

  const { isFieldInError, getErrorsInField, isFormValid } = useValidation({
    fieldsRules: {
      firstName: { required: true },
      lastName: { required: true },
      country: { required: true },
      state: {
        required:
          selectedCountry &&
          selectedCountry.states &&
          selectedCountry.states.length
            ? true
            : false,
      },
      address: { required: true },
      city: { required: true },
      postalCode: { required: true },
      phone: { required: true },
      email: { required: true },
    },
    state: {
      firstName,
      lastName,
      country,
      state,
      address,
      city,
      postalCode,
      phone,
      email,
    },
    locale: "en",
  });

  const filteredCountry =
    countryQuery === ""
      ? countries
      : countries.filter((country: any) =>
          country.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(countryQuery.toLowerCase().replace(/\s+/g, ""))
        );

  const filteredState =
    stateQuery === ""
      ? selectedCountry?.states || []
      : selectedCountry?.states.filter((state: any) =>
          state.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(stateQuery.toLowerCase().replace(/\s+/g, ""))
        );

  /**
   * Handle country onchange
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-09-17
   *
   * @param {any} country the selected country
   * @returns {void}
   */
  const handleCountryOnchange = (country: any): void => {
    setSelectedCountry(country);
    setCountry(country._id);

    if (country !== selectedCountry) {
      setState("");
    }
  };

  /**
   * Submit the form
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-27
   *
   * @param {any} e the react mouse event
   * @returns {void}
   */
  const submitHandler = (e: any): void => {
    e.preventDefault();

    if (isFormValid) {
      // Start loader
      setLoadingUpdate(true);

      let data = null;

      const delivery_address = {
        first_name: firstName,
        last_name: lastName,
        company,
        state: state._id,
        address1: address,
        city,
        postal_code: postalCode,
        country: selectedCountry._id,
        phone,
        email,
      };

      const billing_address = {
        first_name: firstName,
        last_name: lastName,
        company,
        state: state._id,
        address1: address,
        city,
        postal_code: postalCode,
        country: selectedCountry._id,
        phone,
        email,
      };

      data =
        lastPathName === "edit-billing"
          ? { billing_address, _id: userInfo._id }
          : { delivery_address, _id: userInfo._id };

      userService.updateUser(data).subscribe({
        next: () => {
          // Stop loader
          setLoadingUpdate(false);

          toast.success("Address successfully updated!", {
            theme: "colored",
          });
        },
        error: (e) => {
          // Stop loader
          setLoadingUpdate(false);

          toast.error(e, {
            theme: "colored",
          });
        },
      });
    } else {
      setFieldErrorMessages(
        getFieldsCustomErrorMessages(
          touchedFields,
          isFieldInError,
          getErrorsInField,
          false
        )
      );

      setTouchedFields({
        firstName: true,
        lastName: true,
        country: true,
        state: true,
        address: true,
        city: true,
        postalCode: true,
        phone: true,
        email: true,
      });
    }
  };

  /**
   * OnBlurHandler
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-10
   *
   * @param {React.FormEvent<HTMLElement>} event the react mouse event
   * @param {string} field the field on blur
   * @returns {void}
   */
  const onBlurHandler = (
    event: React.FormEvent<HTMLElement>,
    field: string
  ): void => {
    setTouchedFields((prevFields) => ({ ...prevFields, [field]: true }));
  };

  /**
   * OnBlurHandler
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-10
   *
   * @param {React.FormEvent<HTMLElement>} e the react mouse event
   * @param {string} field the form input
   * @returns {void}
   */
  const handleBlur = (e: React.FormEvent<HTMLElement>, field: string): void => {
    const currentTarget = e.currentTarget;

    // Check the newly focused element in the next tick of the event loop
    setTimeout(() => {
      // Check if the new activeElement is a child of the original container
      if (!currentTarget.contains(document.activeElement)) {
        // You can invoke a callback or add custom logic here
        onBlurHandler(e, field);
      }
    }, 0);
  };

  // Get all countries
  useEffect(() => {
    countryService.getCountries(1, 250).subscribe({
      next: (data) => setCountries(data.countries),
      error: (e) => console.error(e),
    });
  }, []);

  useEffect(() => {
    setFieldErrorMessages(
      getFieldsCustomErrorMessages(
        touchedFields,
        isFieldInError,
        getErrorsInField
      )
    );
  }, [touchedFields]);

  // Get user details
  useEffect(() => {
    if (userInfo) {
      userService.profile(userInfo._id).subscribe({
        next: (data) => setUser(data),
        error: (e) => console.error(e),
      });
    }
  }, [userInfo]);

  // Fill form
  useEffect(() => {
    if (user) {
      const details =
        lastPathName === "edit-billing"
          ? user?.billing_address
          : user?.delivery_address;

      setFirstName(details?.first_name);
      setLastName(details?.last_name);
      setCompany(details?.company);
      setState(details?.state);
      setAddress(details?.address1);
      setCity(details?.city);
      setPostalCode(details?.postal_code);
      setCountry(details?.country);
      setSelectedCountry(details?.country);
      setPhone(details?.phone);
      setEmail(details?.email);
    }
  }, [lastPathName, user]);

  return (
    <form onSubmit={submitHandler} className="w-full">
      {fieldErrorMessages.length ? (
        <div className="mb-4">
          <MessageBox variant="danger">
            {fieldErrorMessages.map(
              (s) =>
                s !== "" && (
                  <span key={s}>
                    {s}
                    <br />
                  </span>
                )
            )}
          </MessageBox>
        </div>
      ) : null}
      <div className="">
        <div className="mb-4">
          {lastPathName === "edit-billing" ? (
            <h2 className="text-primary text-xl font-bold ">
              Adresse de facturation
            </h2>
          ) : (
            <h2 className="text-primary text-xl font-bold">
              Adresse de livraison
            </h2>
          )}
        </div>
        {/* {isLoading && <LoadingBox />}*/}
        <input type="hidden" name="remember" defaultValue="true" />
        <div className="rounded-md shadow-sm">
          <div className="flex justify-between gap-4 mb-4">
            <div className="w-full">
              <label htmlFor="firstName" className="text-gray-900">
                Prénom <span className="text-red-500"> *</span>
              </label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                autoComplete="firstName"
                // required
                value={firstName}
                className={classNames(
                  touchedFields.firstName && isFieldInError("firstName")
                    ? `border-red-500 focus:border-red-500 focus:outline-none
                      focus:ring-0 focus:ring-secondary`
                    : `border-gray-300 focus:border-secondary
                      focus:outline-none focus:ring-0 focus:ring-secondary`,
                  `relative block w-full appearance-none rounded-md border
                    px-3 py-2 text-gray-900 placeholder-gray-500`
                )}
                placeholder="Saisir le prénom"
                onChange={(e) => setFirstName(e.target.value)}
                onBlur={(e) => onBlurHandler(e, "firstName")}
              />
            </div>
            <div className="w-full">
              <label htmlFor="lastName" className="text-gray-900">
                Nom <span className="text-red-500"> *</span>
              </label>
              <input
                id="lastName"
                name="lastName"
                type="text"
                autoComplete="lastName"
                // required
                value={lastName}
                className={classNames(
                  touchedFields.lastName && isFieldInError("lastName")
                    ? `border-red-500 focus:border-red-500 focus:outline-none
                      focus:ring-0 focus:ring-secondary`
                    : `border-gray-300 focus:border-secondary
                      focus:outline-none focus:ring-0 focus:ring-secondary`,
                  `relative block w-full appearance-none rounded-md border
                    px-3 py-2 text-gray-900 placeholder-gray-500`
                )}
                placeholder="Saisir le nom"
                onChange={(e) => setLastName(e.target.value)}
                onBlur={(e) => onBlurHandler(e, "lastName")}
              />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="company" className="text-gray-900">
              Eentreprise (facultatif)
            </label>
            <input
              id="company"
              name="company"
              type="text"
              autoComplete="company"
              value={company}
              className="relative block w-full appearance-none rounded-md
                border border-gray-300 px-3 py-2 text-gray-900
                placeholder-gray-500 focus:border-secondary focus:ring-0
                focus:outline-none focus:ring-secondary"
              placeholder="Saisir le nom de l'entreprise"
              onChange={(e) => setCompany(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="country" className="text-gray-900">
              Pays <span className="text-red-500"> *</span>
            </label>
            <Combobox
              value={selectedCountry}
              onChange={(e) => handleCountryOnchange(e)}
            >
              <div
                className="relative mt-1"
                tabIndex={1}
                onBlur={(e) => handleBlur(e, "country")}
              >
                <div
                  className="relative w-full cursor-default overflow-hidden
                  bg-white text-left focus:outline-none
                  focus-visible:ring-2 focus-visible:ring-white
                  focus-visible:ring-opacity-75 focus-visible:ring-offset-2
                  focus-visible:ring-offset-teal-300 sm:text-sm"
                >
                  <Combobox.Input
                    className={classNames(
                      touchedFields.country && isFieldInError("country")
                        ? `border-red-500 focus:border-red-500
                          focus:outline-none focus:ring-0
                          focus:ring-secondary`
                        : `border-gray-300 focus:border-secondary
                          focus:outline-none focus:ring-0
                          focus:ring-secondary`,
                      `relative w-full cursor-default rounded-md border
                        bg-white py-2.5 pl-3 pr-10 text-left shadow-sm
                        text-gray-900 sm:text-sm`
                    )}
                    placeholder="Choisissez un pays"
                    displayValue={(country: any) => country.name}
                    onChange={(event) => setCountryQuery(event.target.value)}
                    autoComplete="off"
                  />
                  <Combobox.Button
                    className="absolute inset-y-0 right-0 flex items-center
                    pr-2"
                  >
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Combobox.Button>
                </div>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  afterLeave={() => setCountryQuery("")}
                >
                  <Combobox.Options
                    className="absolute z-40 mt-1 max-h-56 w-full
                    overflow-auto rounded-md bg-white py-1 text-base
                    shadow-lg ring-1 ring-black ring-opacity-5
                    focus:outline-none sm:text-sm"
                  >
                    {filteredCountry.length === 0 ? (
                      <div
                        className="relative cursor-default select-none py-2
                      px-4 text-gray-700"
                      >
                        Aucun élément.
                      </div>
                    ) : (
                      <>
                        {filteredCountry.map((country: any) => (
                          <Combobox.Option
                            key={country._id}
                            className={({ active }) =>
                              `relative cursor-default select-none
                                py-2 pl-3 pr-9 text-gray-900
                                ${
                                  active
                                    ? "bg-secondary text-white"
                                    : "text-gray-900"
                                }`
                            }
                            value={country}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {country.name}
                                </span>
                                {selected ? (
                                  <span
                                    className={`absolute inset-y-0 right-0
                                      flex items-center pl-3 ${
                                        active ? "text-white" : "text-secondary"
                                      }`}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                        {countryLoading && (
                          <div className="flex justify-center items-center py-2">
                            <LoadingBox />
                          </div>
                        )}
                      </>
                    )}
                  </Combobox.Options>
                </Transition>
              </div>
            </Combobox>
          </div>
          {selectedCountry &&
          selectedCountry.states &&
          selectedCountry.states.length ? (
            <div className="mb-4">
              <label htmlFor="country" className="text-gray-900">
                États / Régions <span className="text-red-500"> *</span>
              </label>
              <Combobox value={state} onChange={(e) => setState(e)}>
                <div
                  className="relative mt-1"
                  tabIndex={1}
                  onBlur={(e) => handleBlur(e, "state")}
                >
                  <div
                    className="relative w-full cursor-default overflow-hidden
                    bg-white text-left focus:outline-none
                    focus-visible:ring-2 focus-visible:ring-white
                    focus-visible:ring-opacity-75 focus-visible:ring-offset-2
                    focus-visible:ring-offset-teal-300 sm:text-sm"
                  >
                    <Combobox.Input
                      className={classNames(
                        touchedFields.state && isFieldInError("state")
                          ? `border-red-500 focus:border-red-500
                            focus:outline-none focus:ring-0
                            focus:ring-secondary`
                          : `border-gray-300 focus:border-secondary
                            focus:outline-none focus:ring-0
                            focus:ring-secondary`,
                        `relative w-full cursor-default rounded-md border
                          bg-white py-2.5 pl-3 pr-10 text-left shadow-sm
                          text-gray-900 sm:text-sm`
                      )}
                      placeholder="Choisissez un état ou région"
                      displayValue={(state: any) => state.name}
                      onChange={(event) => setStateQuery(event.target.value)}
                      autoComplete="off"
                    />
                    <Combobox.Button
                      className="absolute inset-y-0 right-0 flex items-center
                      pr-2"
                    >
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Combobox.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setStateQuery("")}
                  >
                    <Combobox.Options
                      className="absolute z-40 mt-1 max-h-56 w-full
                      overflow-auto rounded-md bg-white py-1 text-base
                      shadow-lg ring-1 ring-black ring-opacity-5
                      focus:outline-none sm:text-sm"
                    >
                      {filteredState.length === 0 ? (
                        <div
                          className="relative cursor-default select-none py-2
                        px-4 text-gray-700"
                        >
                          Aucun élément.
                        </div>
                      ) : (
                        <>
                          {filteredState.map((state: any) => (
                            <Combobox.Option
                              key={state._id}
                              className={({ active }) =>
                                `relative cursor-default select-none
                                  py-2 pl-3 pr-9 text-gray-900
                                  ${
                                    active
                                      ? "bg-secondary text-white"
                                      : "text-gray-900"
                                  }`
                              }
                              value={state}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}
                                  >
                                    {state.name}
                                  </span>
                                  {selected ? (
                                    <span
                                      className={`absolute inset-y-0 right-0
                                        flex items-center pl-3 ${
                                          active
                                            ? "text-white"
                                            : "text-secondary"
                                        }`}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                          {countryLoading && (
                            <div
                              className="flex justify-center items-center
                                py-2"
                            >
                              <LoadingBox />
                            </div>
                          )}
                        </>
                      )}
                    </Combobox.Options>
                  </Transition>
                </div>
              </Combobox>
            </div>
          ) : null}

          <div className="mb-4">
            <label htmlFor="address" className="text-gray-900">
              Adresse <span className="text-red-500"> *</span>
            </label>
            <input
              id="address"
              name="address"
              type="text"
              autoComplete="address"
              // required
              value={address}
              className={classNames(
                touchedFields.address && isFieldInError("address")
                  ? `border-red-500 focus:border-red-500 focus:outline-none
                    focus:ring-0 focus:ring-secondary`
                  : `border-gray-300 focus:border-secondary
                    focus:outline-none focus:ring-0 focus:ring-secondary`,
                `relative block w-full appearance-none rounded-md border 
                  px-3 py-2 text-gray-900 placeholder-gray-500`
              )}
              placeholder="Saisir l'adresse"
              onChange={(e) => setAddress(e.target.value)}
              onBlur={(e) => onBlurHandler(e, "address")}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="city" className="text-gray-900">
              Ville / Quartier <span className="text-red-500"> *</span>
            </label>
            <input
              id="city"
              name="city"
              type="text"
              autoComplete="city"
              // required
              value={city}
              className={classNames(
                touchedFields.city && isFieldInError("city")
                  ? `border-red-500 focus:border-red-500 focus:outline-none
                  focus:ring-0 focus:ring-secondary`
                  : `border-gray-300 focus:border-secondary
                    focus:outline-none focus:ring-0 focus:ring-secondary`,
                `relative block w-full appearance-none rounded-md border 
                  px-3 py-2 text-gray-900 placeholder-gray-500`
              )}
              placeholder="Saisir la ville"
              onChange={(e) => setCity(e.target.value)}
              onBlur={(e) => onBlurHandler(e, "city")}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="postalcode" className="text-gray-900">
              Code postale <span className="text-red-500"> *</span>
            </label>
            <input
              id="postalcode"
              name="postalcode"
              type="text"
              autoComplete="postalcode"
              // required
              value={postalCode}
              className={classNames(
                touchedFields.postalCode && isFieldInError("postalCode")
                  ? `border-red-500 focus:border-red-500 focus:outline-none
                    focus:ring-0 focus:ring-secondary`
                  : `border-gray-300 focus:border-secondary
                    focus:outline-none focus:ring-0 focus:ring-secondary`,
                `relative block w-full appearance-none rounded-md border 
                  px-3 py-2 text-gray-900 placeholder-gray-500`
              )}
              placeholder="Saisir le code postal"
              onChange={(e) => setPostalCode(e.target.value)}
              onBlur={(e) => onBlurHandler(e, "postalCode")}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="phone" className="text-gray-900">
              Téléphone <span className="text-red-500"> *</span>
            </label>
            <input
              id="phone"
              name="phone"
              type="text"
              autoComplete="phone"
              // required
              value={phone}
              className={classNames(
                touchedFields.phone && isFieldInError("phone")
                  ? `border-red-500 focus:border-red-500 focus:outline-none
                    focus:ring-0 focus:ring-secondary`
                  : `border-gray-300 focus:border-secondary
                    focus:outline-none focus:ring-0 focus:ring-secondary`,
                `relative block w-full appearance-none rounded-md border 
                px-3 py-2 text-gray-900 placeholder-gray-500`
              )}
              placeholder="Saisir le numéro de téléphone"
              onChange={(e) => setPhone(e.target.value)}
              onBlur={(e) => onBlurHandler(e, "phone")}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="text-gray-900">
              Adresse email <span className="text-red-500"> *</span>
            </label>
            <input
              id="email"
              name="email"
              type="text"
              autoComplete="email"
              // required
              value={email}
              className={classNames(
                touchedFields.email && isFieldInError("email")
                  ? `border-red-500 focus:border-red-500 focus:outline-none
                  focus:ring-0 focus:ring-secondary`
                  : `border-gray-300 focus:border-secondary
                    focus:outline-none focus:ring-0 focus:ring-secondary`,
                `relative block w-full appearance-none rounded-md border 
                  px-3 py-2 text-gray-900 placeholder-gray-500`
              )}
              placeholder="Saisir l'adresse email"
              onChange={(e) => setEmail(e.target.value)}
              onBlur={(e) => onBlurHandler(e, "email")}
            />
          </div>
          <div>
            <button
              type="submit"
              className={classNames(
                loadingUpdate
                  ? "bg-gray-400 text-gray-400 hover:bg-gray-400"
                  : `bg-secondary hover:bg-ternary focus:outline-none
                    focus:ring-primary text-white`,
                `group flex justify-center rounded border
                  border-transparent py-2 px-4 font-medium focus:ring-0 relative
                  focus:ring-offset-2`
              )}
              disabled={loadingUpdate || !isFormValid}
            >
              {loadingUpdate ? (
                <div className="absolute text-black">
                  <LoadingBox />
                </div>
              ) : null}
              Enregistrer les modifications
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
