import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingBox from "../others/loading-box";
import { toast } from "react-toastify";
import { classNames } from "../../utils/helper.util";
import cartService from "../../api/product-service/cart/cart.service";
import ProductType from "../../api/product-service/product/product.type";

/**
 * Add to cart button component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-29
 *
 * @param {AddToCartButtonProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function AddToCartButton(
  props: AddToCartButtonProps
): JSX.Element {
  const product = props.product;
  const qty = props.qty || 1;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProuct, setSelectedProuct] = useState(0) as any;
  const navigate = useNavigate();
  /**
   * Add product to cart
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-28
   *
   * @param {string} id the product's id
   * @returns {void}
   */
  const addToCartHandler = (id: string): void => {
    // Start loader
    setIsLoading(true);

    setSelectedProuct(id as any);

    cartService.addToCart(id, qty).subscribe({
      next: () => {
        // toast.success('Produit ajouté avec succès au panier!', {
        //   theme: "colored",
        // });

        setIsLoading(false);
      },
      error: () => {
        setIsLoading(false);
        toast.error("Error !", {
          theme: "colored",
        });
      },
    });
  };
  return (
    <button
      onClick={() => {
        if (!product.current_stock) navigate(`/product/${product._id}`);
        else addToCartHandler(product._id);
      }}
      className={classNames(
        isLoading && selectedProuct == product._id
          ? "bg-gray-400 hover:bg-gray-400"
          : `bg-secondary hover:bg-ternary
          focus:outline-none
          focus:ring-primary `,
        props.className ||
          `group flex text-white justify-center
        rounded-full border 
        border-transparent py-2 px-4 w-full
        font-medium focus:ring-0 relative
        focus:ring-offset-2`
      )}
      disabled={isLoading && selectedProuct == product._id ? true : false}
    >
      {isLoading && selectedProuct == product._id ? (
        <div className="absolute text-black">
          <LoadingBox />
        </div>
      ) : null}
      <span className="">
        {!product.current_stock ? "En savoir plus" : "Ajouter au panier"}
      </span>
    </button>
  );
}

type AddToCartButtonProps = {
  product: ProductType;
  qty?: number;
  className?: string;
};
