import React from "react";
import { Route, Routes } from "react-router-dom";
import ProductPage from "../pages/product.page";

/**
 * Product route
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function ProductRoute(): JSX.Element {
  return (
    <Routes>
      <Route path="/product/:id" element={<ProductPage />} />
    </Routes>
  );
}
