import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import LoadingBox from "../others/loading-box";
import wishlistService from "../../api/product-service/wishlist/wishlist.service";
import ProductType from "../../api/product-service/product/product.type";

/**
 * Add to wishlist button component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @param {AddToWishlistButtonProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function AddToWishlistButton(
  props: AddToWishlistButtonProps
): JSX.Element {
  const product = props.product;
  const [open, setOpen] = useState(false);
  const [wishListAddState, setWishListAddState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [wishlistItems, setWishlistItems] = useState<any>(
    localStorage.getItem("wishlistItems")
      ? JSON.parse(localStorage.getItem("wishlistItems") as any)
      : []
  );

  /**
   * Add to wishlist
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {string} id the product's id
   * @returns {void}
   */
  const addToWishlistHandler = (id: string): void => {
    // Start loader
    setIsLoading(true);

    if (wishlistItems.some((prod: ProductType) => prod.name == product.name)) {
      wishlistService.removeFromWishlist(id).subscribe({
        next: (res) => {
          setWishlistItems(res);

          // Open Dialog Panel
          setOpen(true);

          // Stop loader
          setIsLoading(false);

          setWishListAddState(1);
        },
        error: () => {
          setIsLoading(false);
          // setError(e);
        },
      });
    } else {
      wishlistService.addToWishlist(id).subscribe({
        next: (res) => {
          setWishlistItems(res);

          // Open Dialog Panel
          setOpen(true);

          // Stop loader
          setIsLoading(false);

          setWishListAddState(0);
        },
        error: () => {
          setIsLoading(false);
          // setError(e);
        },
      });
    }
  };
  return (
    <Fragment>
      <button
        onClick={() => addToWishlistHandler(product._id)}
        className="flex items-center bg-transparent hover:text-secondary"
      >
        {isLoading ? (
          <LoadingBox />
        ) : wishlistItems.some(
            (prod: ProductType) => prod.name == product.name
          ) ? (
          <i className="fa fa-heart inline-block mb-px" aria-hidden="true"></i>
        ) : (
          <i
            className="fa fa-heart-o inline-block mb-px"
            aria-hidden="true"
          ></i>
        )}
        <span className="ml-1">Ajouter à la wishlist</span>
      </button>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 hidden bg-black bg-opacity-75
                transition-opacity md:block"
            />
          </Transition.Child>

          <div className="fixed inset-0 z-30 overflow-y-auto">
            <div
              className="flex min-h-full items-stretch justify-center
                text-center md:items-center md:px-2 lg:px-4"
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                enterTo="opacity-100 translate-y-0 md:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 md:scale-100"
                leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              >
                <Dialog.Panel
                  className="flex w-full transform text-left
                    text-base transition md:max-w-2xl lg:max-w-2xl"
                >
                  <div
                    className="relative flex w-full items-center
                    overflow-hidden bg-white shadow-2xl sm:px-4 sm:pt-4
                    md:p-4 lg:p-4"
                  >
                    <button
                      type="button"
                      className="absolute top-4 right-4 text-gray-400
                        hover:text-gray-500 sm:right-6 md:right-6 lg:right-8"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    <div className="flex justify-center w-full pr-4">
                      <p>
                        {wishListAddState ? (
                          <span className="text-primary">
                            Le produit a été retiré de la liste de souhaits.
                          </span>
                        ) : (
                          <span className="text-primary">
                            Le produit a été ajouté à la liste de souhaits.
                          </span>
                        )}
                        <Link to="/wishlist" className="ml-1">
                          Voir la liste de souhaits
                        </Link>
                        .
                      </p>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
}

type AddToWishlistButtonProps = {
  product: ProductType;
};
