import { Listbox, Transition } from "@headlessui/react";
import { XMarkIcon, ShoppingCartIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../../shared/utils/helper.util";
import { toast } from "react-toastify";
import cartService from "../../../shared/api/product-service/cart/cart.service";

/**
 * Empty cart component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-03
 *
 * @returns {JSX.Element} the jsx element
 */
export default function EmptyCart(): JSX.Element {
  const navigate = useNavigate();

  /**
   * Add product to cart
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-10-03
   *
   * @returns {void}
   */
  const redirectToShop = (): void => {
    navigate("/shop");
  };

  return (
    <div>
      <div className="flex justify-center">
        <ShoppingCartIcon className="w-48 h-48 text-gray-400" />
        <XMarkIcon className="w-32 h-40 text-gray-400 absolute" />
      </div>
      <p className="flex justify-center text-primary text-lg mt-8">
        Votre panier est actuellement vide.
      </p>
      <div className="flex justify-center mt-8">
        <button
          type="button"
          onClick={redirectToShop}
          className="rounded-md my-6 py-2 px-4 bg-secondary
                        hover:bg-ternary block text-white font-bold"
        >
          Retour à la boutique
        </button>
      </div>
    </div>
  );
}
