import React from "react";
import { MagnifyingGlassIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import SearchBox from "../headersearchbox/search-box";

/**
 * Header search component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function HeaderSearch(): JSX.Element {
  const [open, setOpen] = useState(false);

  const cancelButtonRef = useRef(null);

  /**
   * Handle button click
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @returns {void}
   */
  const buttonclicked = (): void => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <div className="flex -space-x-2 justify-center" onClick={buttonclicked}>
        <MagnifyingGlassIcon
          className="inline-block h-11 w-11 text-gray-500 bg-secondary-rgba
            transition hover:bg-secondary hover:text-white rounded-full p-3
            cursor-pointer duration-500"
          aria-hidden="true"
        />
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75
              transition-opacity"
            />
          </Transition.Child>

          <div className="fixed inset-0 z-40 h-full overflow-y-auto">
            <div
              className="flex h-full min-h-full items-end justify-center
              p-4 text-center sm:items-center sm:p-0"
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="relative h-full pt-6 pr-7 transform
                  overflow-hidden bg-white text-left shadow-xl transition-all
                  sm:my-8 sm:w-full"
                >
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="flex justify-end items-center">
                      <div
                        className="mx-auto flex items-center justify-end
                          cursor-pointer sm:mx-0 sm:h-10 sm:w-10"
                        onClick={() => setOpen(false)}
                      >
                        <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                      </div>
                    </div>
                    <div
                      className="grid grid-cols-8 gap-4 pt-8 text-center
                      sm:mt-0 sm:ml-4 sm:text-left"
                    >
                      <div className="col-start-3 col-span-4">
                        <SearchBox />
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
}
