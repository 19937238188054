import React from "react";
import Address from "./address.component";
import Dashboard from "./dashboard.component";
import Download from "./download.component";
import Order from "./order.component";
import PaymentMethod from "./payment-method.component";
import Profile from "./profile.component";

/**
 * Load customer component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-11
 *
 * @param {LoadCustomerComponentProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function LoadCustomerComponent(
  props: LoadCustomerComponent
): JSX.Element {
  const slug = props.slug;
  const components: any = {
    address: Address,
    dashboard: Dashboard,
    downloads: Download,
    orders: Order,
    "payment-method": PaymentMethod,
    profile: Profile,
  };

  const TheComponent = slug.length ? components[slug] : null;

  return TheComponent && <TheComponent />;
}

type LoadCustomerComponent = {
  slug: string;
};
