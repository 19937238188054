import React from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../../shared/utils/helper.util";
import AddToCartButton from "../../../shared/components/product/add-to-cart-button";
import RemoveFromWishlistButton from "../../../shared/components/product/remove-from-wishlist-button";
import CartType from "../../../shared/api/product-service/cart/cart.type";

/**
 * Display wishlist items
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-27
 *
 * @param {WishlistItemsProps} props the props properties
 * @returns {JSX.Element} the jsx element
 */
export default function WishlistItems(props: WishlistItemsProps): JSX.Element {
  const wishlistItems = props.wishlistItems;

  return (
    <div>
      <div className="overflow-x-auto relative">
        <table className="w-full border border-gray-200 text-sm text-left text-gray-500 dark:text-gray-400">
          <tbody>
            {wishlistItems.map((item, index) => (
              <tr
                key={item._id}
                className={classNames(
                  index / 2 == 0 && `bg-gray-50 border-t border-gray-200 dark:bg-gray-200 dark:border-gray-200`,
                  `hover:bg-gray-100`
                )}>
                <td
                 className="border border-gray-200 py-4 px-2 hidden xl:table-cell lg:table-cell md:table-cell m-auto">
                  <div className="flex justify-center">
                    <RemoveFromWishlistButton product={item as any} />
                  </div>
                </td>
                <td
                  scope="row"
                  className="border border-gray-200 p-2 font-medium text-gray-900 whitespace-nowrap dark:text-white
                    hidden xl:table-cell lg:table-cell md:table-cell" >
                  <img
                    className="xl:w-22 lg:w-22 md:w-22 sm:w-22 mx-auto
                    mb-2 border border-gray-200 xl:mb-0 lg:mb-0 md:mb-0
                    sm:mb-0 xl:border-0 lg:border-0 md:border-0
                    sm:border-0"
                    src={item.image}
                    alt={item.name}
                  />
                </td>
                <td
                  scope="col"
                  className="xl:w-1/2 lg:w-1/2 md:w-1/2 border border-gray-200
                  capitalize p-2 hidden xl:table-cell lg:table-cell
                  md:table-cell">
                  <div className="flex flex-col leading-6">
                    <Link
                      to={`/product/${item._id}`}
                      className="font-medium text-primary">
                      {item.name}
                    </Link>
                    <span className="text-gray-500">{item.price} FCFA</span>
                    <span className="text-gray-500">28 juillet 2023</span>
                  </div>
                </td>
                <td
                  scope="row"
                  className="border border-gray-200 py-4 px-6 font-medium
                  hidden xl:table-cell lg:table-cell md:table-cell text-gray-900
                  whitespace-nowrap dark:text-white">
                  <AddToCartButton
                    product={item as any}
                    className="group flex text-white border rounded justify-center border-transparent
                    py-2 px-4 font-medium focus:ring-0 relative focus:ring-offset-2"/>
                </td>
                <td
                  scope="row"
                  className="border border-gray-200 py-4 px-6 font-medium
                  text-gray-900 whitespace-nowrap dark:text-white flex flex-col
                  xl:hidden lg:hidden md:hidden">
                  <img
                    className="mx-auto
                    mb-2 border border-gray-200 xl:mb-0 lg:mb-0 md:mb-0
                    sm:mb-0 xl:border-0 lg:border-0 md:border-0
                    sm:border-0"
                    src={item.image}
                    alt={item.name}
                  />
                  <div className="flex flex-col leading-6">
                    <div className="flex justify-between items-center">
                      <Link
                        to={`/product/${item._id}`}
                        className="font-medium text-primary"
                      >
                        {item.name}
                      </Link>
                      <RemoveFromWishlistButton
                        product={item as any}
                        className="flex justify-center items-center h-5 w-5
                        text-gray-500 hover:text-secondary"
                      />
                    </div>
                    <span className="text-gray-500">{item.price} FCFA</span>
                    <span className="text-gray-500">28 juillet 2023</span>
                  </div>
                  <div className="mt-4">
                    <AddToCartButton
                      product={item as any}
                      className="w-full group flex text-white border
                    rounded justify-center border-transparent
                    py-2 px-4 font-medium focus:ring-0
                    relative focus:ring-offset-2"
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

type WishlistItemsProps = {
  wishlistItems: Array<CartType>;
};
