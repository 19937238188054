import React from "react";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// import dotenv from "dotenv";

// dotenv.config();

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as any
);

/**
 * Stripe wrapper component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-27
 *
 * @param {StripeWrapperProps} props the shared properties
 * @returns {JSX.Element} the javaScriptXML element
 */
export default function StripeWrapper(props: StripeWrapperProps): JSX.Element {
  const options = {
    clientSecret: props.client_secret,
    locale: "fr",
  } as any;

  return (
    <Elements stripe={stripePromise} options={options}>
      {props.children}
    </Elements>
  );
}

type StripeWrapperProps = {
  client_secret?: any;
  children: any;
};
