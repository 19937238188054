import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingBox from "../../components/others/loading-box";
import MessageBox from "../others/message-box";
import orderService from "../../api/order-service/order/order.service";
import userService from "../../api/user-service/user/user.service";
import { classNames, numberFormatter, _date } from "../../utils/helper.util";
import { OrderItem } from "../../api/order-service/order/order.type";
import StateType from "../../api/user-service/state/state.type";
import CountryType from "../../api/user-service/country/country.type";
import countryService from "../../api/user-service/country/country.service";
import stateService from "../../api/user-service/state/state.service";

/**
 * Order details component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function OrderDetails(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [order, setOrder] = useState<any>();
  const [user, setUser] = useState<any>();
  const [state, setState] = useState<StateType>();
  const [country, setCountry] = useState<CountryType>();
  const params = useParams();
  const { id: orderId } = params;

  // Get order by id
  useEffect(() => {
    if (orderId) {
      orderService.getOrderById(orderId).subscribe({
        next: (data) => {
          // Stop loader
          setIsLoading(false);

          setOrder(data);
        },
        error: (e) => {
          // Stop loader
          setIsLoading(false);

          setError(e);
        },
      });
    }
  }, [orderId]);

  // Get user by id
  useEffect(() => {
    if (order && order.user) {
      userService.profile(order.user).subscribe({
        next: (data) => setUser(data),
        error: (e) => console.error(e),
      });
    }
  }, [order]);

  // Get country by id
  useEffect(() => {
    if (order) {
      countryService.getCountryById(order.shipping_address.country).subscribe({
        next: (data) => setCountry(data),
        error: (e) => console.error(e),
      });
    }
  }, [order]);

  // Get State by id
  useEffect(() => {
    if (order) {
      stateService.getStateById(order.shipping_address.state).subscribe({
        next: (data) => setState(data),
        error: (e) => console.error(e),
      });
    }
  }, [order]);

  return isLoading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div>
      {/* <h1>Order {order._id}</h1> */}
      <div className="">
        <p>
          La commande n°
          <span className="font-bold text-primary">{order.order_number}</span>
          &nbsp;a été passée le &nbsp;
          <span className="font-bold text-primary">
            {_date(order.created_at)}
          </span>
          &nbsp;et est &nbsp;actuellement&nbsp;
          <span className="font-bold text-primary">
            {order.is_delivered ? "Terminé" : "En cours"}
          </span>
          .
        </p>
        <div className="col-start-2 col-span-6">
          <div className="mt-5">
            <h2 className="text-primary text-xl font-bold mb-4">
              Détails de la commande
            </h2>
            <div className="bg-gray-100 grid grid-cols-7 font-bold py-4">
              <div className="col-span-4 pl-4">Produit</div>
              <div className="col-span-3">Total</div>
            </div>
            {order?.order_items.map((item: OrderItem, index: number) => (
              <div
                key={item.product}
                className={classNames(
                  index % 2 === 1 && "bg-gray-100",
                  "dark:bg-gray-200"
                )}
              >
                <div className="grid grid-cols-7 items-center py-4">
                  <div className="col-span-4 pl-4">
                    <Link to={`/product/${item.product}`} className="mr-1">
                      {item.name}
                    </Link>
                    <span className="font-bold">x {item.qty}</span>
                  </div>
                  <div className="col-span-3">
                    {numberFormatter(item.qty * item.price)}
                  </div>
                </div>
              </div>
            ))}
            <div className="font-bold">
              <div
                className={classNames(
                  order?.order_items.length % 2 === 1 && "bg-gray-100",
                  "grid grid-cols-7 items-center py-4"
                )}
              >
                <div className="col-span-4 pl-4">Sous-total</div>
                <div className="col-span-3">
                  {numberFormatter(order?.items_price)}
                </div>
              </div>
              <div
                className={classNames(
                  (order?.order_items.length + 1) % 2 === 1 && "bg-gray-100",
                  "grid grid-cols-7 items-center py-4"
                )}
              >
                <div className="col-span-4 pl-4">Expédition</div>
                <div className="col-span-3">
                  {numberFormatter(order?.shipping_price)}
                  {order.shipping_method && (
                    <span className="font-normal text-xs">
                      &nbsp;via {order?.shipping_method?.name}
                    </span>
                  )}
                </div>
              </div>
              <div
                className={classNames(
                  (order.order_items.length + 2) % 2 === 1 && "bg-gray-100",
                  "grid grid-cols-7 items-center py-4"
                )}
              >
                <div className="col-span-4 pl-4">Tax</div>
                <div className="col-span-3">
                  {numberFormatter(order?.tax_price)}
                </div>
              </div>
              <div
                className={classNames(
                  (order?.order_items.length + 3) % 2 === 1 && "bg-gray-100",
                  "grid grid-cols-7 items-center py-4"
                )}
              >
                <div className="col-span-4 pl-4">Total</div>
                <div className="col-span-3 text-xl text-secondary">
                  {numberFormatter(order?.total_price)}
                </div>
              </div>
            </div>
          </div>
          <div
            className="grid grid-cols-1 gap-6 xl:grid-cols-2 lg:grid-cols-2
            md:grid-cols-2 mt-12"
          >
            {order?.shipping_address && (
              <div className="col-span-1">
                <h2 className="text-primary text-xl font-bold mb-4">
                  Adresse de facturation
                </h2>
                <div className="leading-8 italic">
                  <p>{order?.shipping_address?.full_name}</p>
                  <p>{order?.shipping_address?.company}</p>
                  <p>{order?.shipping_address?.address}</p>
                  <p>{state?.name}</p>
                  <p>{order?.shipping_address?.city}</p>
                  <p>{order?.shipping_address?.postal_code}</p>
                  <p>{country?.name}</p>
                </div>
              </div>
            )}
            {user?.delivery_address && (
              <div className="col-span-1">
                <h2 className="text-primary text-xl font-bold mb-4">
                  Adresse de livraison
                </h2>
                <div className="leading-8 italic">
                  <p>
                    {user?.delivery_address?.first_name && (
                      <span>{user?.delivery_address?.first_name}&nbsp;</span>
                    )}
                    {user?.delivery_address?.last_name && (
                      <span>{user?.delivery_address?.last_name}</span>
                    )}
                  </p>
                  <p>{user?.delivery_address?.company}</p>
                  <p>{user?.delivery_address?.address1}</p>
                  <p>{user?.delivery_address?.state?.name}</p>
                  <p>{user?.delivery_address?.city}</p>
                  <p>{user?.delivery_address?.postal_code}</p>
                  <p>{user?.delivery_address?.country?.name}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
