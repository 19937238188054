import React from "react";
import { Link } from "react-router-dom";
import { HeartIcon } from "@heroicons/react/24/outline";
import useLocalStorage from "../../../hooks/use-localstorage";

/**
 * Header wishlis component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function HeaderWishlist(): JSX.Element {
  const [whishlistItems] = useLocalStorage("wishlistItems", []);

  return (
    <Link to="/wishlist" className="flex -space-x-2 justify-center">
      <div
        className="ml-10 -mt-1 flex justify-center items-center h-3 w-3
        bg-default font-bold rounded-full text-white p-3 absolute"
      >
        <span className="mt-1">{whishlistItems.length}</span>
      </div>
      <HeartIcon
        className="inline-block h-11 w-11 text-gray-500 bg-secondary-rgba
          transition hover:bg-secondary hover:text-white rounded-full p-3
          cursor-pointer duration-500"
        aria-hidden="true"
      />
    </Link>
  );
}
