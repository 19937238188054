import React from "react";
import { Route, Routes } from "react-router-dom";
import AboutPage from "../pages/about.page";

/**
 * About us route
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-12
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function AboutRoute(): JSX.Element {
  return (
    <Routes>
      <Route path="/about-us" element={<AboutPage />}></Route>
    </Routes>
  );
}
