import React, { useEffect } from "react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";

/**
 * Breadcrumb component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @param {BreadcrumbProps} props the report handler
 * @returns {JSX.Element} the javascript xml element
 */
export default function Breadcrumb(props: BreadcrumbProps): JSX.Element {
  const location = useLocation();

  let currentLink = "";

  const pathNames = location.pathname.split("/");
  const crumbs = pathNames.map((name, index) => {
    currentLink += name && `/${name}`;

    return (
      <div key={index} className="inline-block">
        {pathNames.length > index + 1 && name !== "" ? (
          <div className="flex items-center">
            <Link
              key={index}
              to={currentLink}
              className="text-gray-400
                                                capitalize-first"
            >
              {name}
            </Link>
            <ChevronRightIcon className="h-2 w-3" />
          </div>
        ) : pathNames.length > index + 1 ? (
          <div className="flex items-center">
            <Link
              key={index}
              to="/"
              className="text-gray-400
                                                capitalize-first"
            >
              Accueil
            </Link>
            <ChevronRightIcon className="h-2 w-3" />
          </div>
        ) : (
          <p className="capitalize-first">{name}</p>
        )}
      </div>
    );
  });

  useEffect(() => {
    console.log(location);
    console.log(location.pathname.split(/[/]/));
  }, [location]);

  return (
    <div
      className={`inline-block items-center leading-6
                xl:text-left lg:text-left md:text-left sm:text-left
                ${props.className}`}
    >
      {crumbs}
    </div>
  );
}

type BreadcrumbProps = {
  className?: string;
};
