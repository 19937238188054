import React, { createContext, useContext, useMemo } from "react";
import useLocalStorage from "./use-localstorage";

const AuthContext = createContext<any>([]);

/**
 * Create a custom hook that will handle the authenticated user’s state using
 * the Context API and useContext hook
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-15
 *
 * @param {AuthProviderProps} props the component to render
 * @return {JSX.Element} the javaScriptXML element
 */
export const AuthProvider = (props: AuthProviderProps): JSX.Element => {
  const children = props.children;
  const [user, setUser] = useLocalStorage("userInfo", null);
  //   const navigate = useNavigate();

  // call this function when you want to authenticate the user
  //   const login = async (data) => {
  //     setUser(data);
  //     navigate("/profile");
  //   };

  // call this function to sign out logged in user
  //   const logout = () => {
  //     setUser(null);
  //     navigate("/", { replace: true });
  //   };

  const value = useMemo(
    () => ({
      user,
      //   login,
      //   logout
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

/**
 * Use Auth hook
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-15
 *
 * @returns {any} of auth context
 */
export const useAuth = (): any => {
  return useContext(AuthContext);
};

type AuthProviderProps = {
  children?: any;
};
