import React from "react";
import { useState } from "react";
import { Tab } from "@headlessui/react";
import HomeProductMultiCarousel from "./home-product-multicarousel.component";
import { classNames } from "../../../shared/utils/helper.util";

/**
 * Home tab product component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function HomeTabProduct(): JSX.Element {
  const [categories] = useState([
    {
      id: 1,
      title: "Nouveaux",
      value: "newest",
    },
    {
      id: 2,
      title: "En vedette",
      value: "featured",
    },
    {
      id: 3,
      title: "Mieux Notés",
      value: "toprated",
    },
  ]);

  return (
    <div className="w-full">
      <Tab.Group>
        <Tab.List
          className="flex grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3
            sm:grid-cols-3 grid-cols-1 max-w-3xl mx-auto space-x-1
            rounded-xl p-1"
        >
          {categories.map((category) => (
            <Tab
              key={category.id}
              className={({ selected }) =>
                classNames(
                  `col-span-1 flex justify-center w-full rounded-lg py-2.5
                    font-bold xl:text-3xl lg:text-3xl md:text-2xl sm:text-xl
                    text-xl`,
                  "focus:outline-none focus:ring-0",
                  selected ? "text-primary" : "text-gray-200"
                )
              }
            >
              {category.title}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {categories.map((categorie) => (
            <Tab.Panel
              key={categorie.id}
              className={classNames(
                "rounded-xl bg-white p-3",
                `ring-white ring-opacity-60 ring-offset-2 focus:outline-none
                focus:ring-0`
              )}
            >
              <HomeProductMultiCarousel sort={categorie.value} />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
