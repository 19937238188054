import React, { useEffect, useState } from "react";
import LoadingBox from "../../../shared/components/others/loading-box";
import { classNames } from "../../../shared/utils/helper.util";
import ProductType from "../../../shared/api/product-service/product/product.type";
import { toast } from "react-toastify";
import useLocalStorage from "../../../shared/hooks/use-localstorage";
import emailService from "../../../shared/api/email-service/email/email.service";

/**
 * Contact form component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-10
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function ContactForm(): JSX.Element {
  const [userInfo] = useLocalStorage("userInfo", null);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [body, setBody] = useState("");
  const [subject, setSubject] = useState("");

  /**
   * Submit reviews form
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} e the react mouse event
   * @returns {void}
   */
  const submitHandler = (e: any): void => {
    e.preventDefault();

    // Start loader
    setIsLoading(true);

    emailService
      .sendEmail({
        senderName: name,
        senderEmail: email,
        subject,
        body,
      })
      .subscribe({
        next: () => {
          // Stop loader
          setIsLoading(false);

          toast.success("Message envoyé avec succès!", {
            theme: "colored",
          });

          // Reset the form
          resetForm();
        },
        error: (e) => {
          // Stop loader
          setIsLoading(false);

          toast.error(e, {
            theme: "colored",
          });
        },
      });
  };

  /**
   * Reset reviews form
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @returns {void}
   */
  const resetForm = (): void => {
    setSubject("");
    setName("");
    setEmail("");
    setBody("");
  };

  useEffect(() => {
    if (userInfo) {
      const firstName = userInfo?.first_name || "";
      const lastName = userInfo?.last_name || "";
      const concatName =
        firstName.concat(" ", lastName).trim() || userInfo.username;

      setName(concatName);
      setEmail(userInfo.email);
    }
  }, [userInfo]);

  return (
    <div className="flex min-h-full items-center justify-center">
      <div className="w-full">
        <form onSubmit={submitHandler}>
          <div
            className="grid gap-6 grid-cols-1 -space-y-px rounded-md mb-6
            xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2"
          >
            <div className="col-span-1">
              <input
                id="name"
                name="name"
                type="text"
                value={name}
                required
                className="relative block w-full appearance-none rounded-md
                  border border-gray-200 px-3 py-2 text-gray-900
                  placeholder-gray-500 focus:z-10 focus:border-secondary
                  focus:outline-none focus:ring-0 focus:ring-secondary"
                placeholder="Votre nom *"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-span-1">
              <input
                id="email"
                name="email"
                type="email"
                value={email}
                autoComplete="email"
                required
                className="relative block w-full appearance-none rounded-md
                  border border-gray-200 px-3 py-2 text-gray-900
                  placeholder-gray-500 focus:z-10 focus:border-secondary
                  focus:outline-none focus:ring-0 focus:ring-secondary"
                placeholder="Adresse email *"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="mb-6">
            <input
              id="subject"
              name="subject"
              type="text"
              value={subject}
              required
              className="relative block w-full appearance-none rounded-md
                border border-gray-200 px-3 py-2 text-gray-900
                placeholder-gray-500 focus:z-10 focus:border-secondary
                focus:outline-none focus:ring-0 focus:ring-secondary"
              placeholder="Objet *"
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <textarea
              id="comment"
              name="comment"
              rows={6}
              value={body}
              required
              className="relative block w-full appearance-none rounded-md
                border border-gray-200 px-3 py-2 text-gray-900
                placeholder-gray-500 focus:z-10 focus:border-secondary
                focus:outline-none focus:ring-0 focus:ring-secondary"
              placeholder="Message *"
              onChange={(e) => setBody(e.target.value)}
            />
          </div>

          <div className="grid gap-6 grid-cols-2 mb-4">
            <button
              onClick={resetForm}
              type="reset"
              className="col-span-1 group relative flex w-full justify-center
                rounded-md border border-transparent bg-gray-200 py-2 px-4
                font-medium hover:bg-gray-400 hover:text-white
                focus:outline-none focus:ring-0 focus:ring-secondary
                focus:ring-offset-2"
            >
              Annuler
            </button>
            <button
              type="submit"
              className={classNames(
                isLoading
                  ? "bg-gray-400 text-gray-400 hover:bg-gray-400"
                  : `bg-secondary hover:bg-ternary focus:outline-none
                    focus:ring-primary text-white`,
                `col-span-1 w-full group flex justify-center rounded border
                    border-transparent py-2 px-4 font-medium focus:ring-0
                    relative focus:ring-offset-2`
              )}
              disabled={isLoading}
            >
              {isLoading && (
                <div className="absolute text-black">
                  <LoadingBox className="text-white" />
                </div>
              )}
              Envoyer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

type ReviewsProps = {
  product?: ProductType;
};
