import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/home.page";

/**
 * Home route
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function HomeRoute(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<HomePage />}></Route>
    </Routes>
  );
}
