import { BanknotesIcon } from "@heroicons/react/24/outline";
import { Phone, ShieldCheck, TruckOutline } from "heroicons-react";
import React from "react";

/**
 * Home info component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function HomeInfo(): JSX.Element {
  return (
    <div
      className="relative w-full grid xl:grid-cols-4 lg:grid-cols-4
            md:grid-cols-2 sm:grid-cols-2 grid-cols-1 border
            border-x-transparent border-t-transparent border-b-gray-200"
    >
      <div
        className="col-span-1 flex items-center justify-center
                xl:justify-between lg:justify-between md:justify-between
                sm:justify-between"
      >
        <div
          className="xl:flex lg:flex md:flex sm:flex xs:flex-none
                    justify-start items-center px-4 py-5"
        >
          <div className="flex justify-center items-center mr-3">
            <div className="flex -space-x-2 justify-center">
              <TruckOutline
                className="inline-block h-11 w-11
                                bg-gray-200 transition hover:bg-secondary
                                hover:text-white rounded-full p-3
                                cursor-pointer duration-500"
                aria-hidden="true"
              />
            </div>
          </div>
          <div
            className="text-center xl:text-left lg:text-left
                        md:text-left sm:text-left"
          >
            <div className="font-medium leading-6">Livraison Gratuite</div>
            <div className="text-gray-500 leading-5 text-xs">
              Pour les commandes de plus de 50 000 FCFA.
            </div>
          </div>
        </div>
        <span
          className="h-6 w-px mx-4 xl:block lg:block md:hidden
                        sm:hidden hidden border border-y-transparent
                        border-x-gray-400 border-dashed"
          aria-hidden="true"
        />
      </div>
      <div
        className="col-span-1 flex items-center justify-center
                xl:justify-between lg:justify-between md:justify-between
                sm:justify-between"
      >
        <div
          className="xl:flex lg:flex md:flex sm:flex xs:flex-none
                    justify-start items-center px-4 py-5"
        >
          <div className="flex justify-center items-center mr-3">
            <div className="flex -space-x-2 justify-center">
              <Phone
                className="inline-block h-11 w-11
                                bg-gray-200 transition hover:bg-secondary
                                hover:text-white rounded-full p-3
                                cursor-pointer duration-500"
                aria-hidden="true"
              />
            </div>
          </div>
          <div
            className="text-center xl:text-left lg:text-left
                        md:text-left sm:text-left"
          >
            <div className="font-medium leading-6">Assistance en ligne</div>
            <div className="text-gray-500 leading-5 text-xs">
              Assistance en ligne 24 heures sur 24 7/7.
            </div>
          </div>
        </div>
        <span
          className="h-6 w-px mx-4 xl:block lg:block md:hidden
                        sm:hidden hidden border border-y-transparent
                        border-x-gray-400 border-dashed"
          aria-hidden="true"
        />
      </div>
      <div
        className="col-span-1 flex items-center justify-center
                xl:justify-between lg:justify-between md:justify-between
                sm:justify-between"
      >
        <div
          className="xl:flex lg:flex md:flex sm:flex xs:flex-none
                    justify-start items-center px-4 py-5"
        >
          <div className="flex justify-center items-center mr-3">
            <div className="flex -space-x-2 justify-center">
              <BanknotesIcon
                className="inline-block h-11 w-11
                                bg-gray-200 transition hover:bg-secondary
                                hover:text-white rounded-full p-3
                                cursor-pointer duration-500"
                aria-hidden="true"
              />
            </div>
          </div>
          <div
            className="text-center xl:text-left lg:text-left
                        md:text-left sm:text-left"
          >
            <div className="font-medium leading-6">Retour d&#39;argent</div>
            <div className="text-gray-500 leading-5 text-xs">
              Garantie de retour d&#39;argent sous 7 jours
            </div>
          </div>
        </div>
        <span
          className="h-6 w-px mx-4 xl:block lg:block md:hidden
                        sm:hidden hidden border border-y-transparent
                        border-x-gray-400 border-dashed"
          aria-hidden="true"
        />
      </div>
      <div
        className="col-span-1 flex items-center justify-center
                xl:justify-between lg:justify-between md:justify-between
                sm:justify-between"
      >
        <div
          className="xl:flex lg:flex md:flex sm:flex xs:flex-none
                    justify-start items-center px-4 py-5"
        >
          <div className="flex justify-center items-center mr-3">
            <div className="flex -space-x-2 justify-center">
              <ShieldCheck
                className="inline-block h-11 w-11
                                bg-gray-200 transition hover:bg-secondary
                                hover:text-white rounded-full p-3
                                cursor-pointer duration-500"
                aria-hidden="true"
              />
            </div>
          </div>
          <div
            className="text-center xl:text-left lg:text-left
                        md:text-left sm:text-left"
          >
            <div className="font-medium leading-6">Paiement Sécurisé</div>
            <div className="text-gray-500 leading-5 text-xs">
              Nous utilisons des méthodes de paiement sécurisées.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
