import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import LoadingBox from "../../../shared/components/others/loading-box";
import MessageBox from "../../../shared/components/others/message-box";
import AddToWhishlist from "../../../shared/components/product/add-to-wishlist";
import AddToCompare from "../../../shared/components/product/add-to-compare";
import ProductQuickviews from "../../../shared/components/product/product-quick-views";
import AddToCart from "../../../shared/components/product/add-to-cart";
import ProductType from "../../../shared/api/product-service/product/product.type";
import productService from "../../../shared/api/product-service/product/product.service";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

/**
 * Product multi carousel component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @param {HomeProductMultiCarouselProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function HomeProductMultiCarousel(
  props: HomeProductMultiCarouselProps
): JSX.Element {
  const sort = props.sort;
  const [productIsLoading, setProductIsLoading] = useState(true);
  const [products, setProducts] = useState<ProductType[]>([]);
  const [error, setError] = useState();

  useEffect(() => {
    productService
      .showAll({
        featured: sort == "featured" ? 1 : ("" as any),
        order: sort != "featured" ? sort : (0 as any),
      })
      .subscribe({
        next: (data) => {
          setProductIsLoading(false);
          setProducts(data.products);
        },
        error: (e) => {
          setProductIsLoading(false);
          setError(e);
        },
      });
  }, [sort]);

  return (
    <Carousel
      swipeable={true}
      draggable={false}
      showDots={true}
      arrows={false}
      responsive={responsive}
      ssr={true}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={5000}
      keyBoardControl={true}
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      {productIsLoading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        products.map((product: ProductType) => (
          <div className="px-4 home-multi-carousel-image" key={product._id}>
            {product.current_stock <= 0 ? (
              <button
                className="absolute text-white text-xs bg-red-600 uppercase
                      rounded-full pt-px px-2 z-10"
                disabled
              >
                <p className="pt-px">Stock out</p>
              </button>
            ) : product.promotional ? (
              <button
                className="absolute text-white text-xs bg-secondary
                      uppercase rounded-full pt-px px-2 z-10"
                disabled
              >
                <p className="pt-px">Promo</p>
              </button>
            ) : null}
            <Link to={`/product/${product._id}`}>
              <img
                src={product.image}
                alt={product.name}
                className="border border-gray-100"
              />
            </Link>
            <div
              className="-mt-12 flex justify-center
              home-multi-carousel-action invisible duration-1000"
            >
              <div className="m-px">
                <AddToWhishlist product={product} />
              </div>
              <div className="m-px">
                <AddToCompare product={product} />
              </div>
              <div className="m-px">
                <ProductQuickviews product={product} />
              </div>
            </div>
            <Link to={`/product/${product._id}`}>
              <p className="mt-10 text-center truncate">{product.name}</p>
            </Link>
            {product.promotional ? (
              <div
                className="flex items-center mt-2
                  justify-center"
              >
                <p className="text-sm text-primary mr-4">
                  <s>{product.price}&nbsp;FCFA</s>
                </p>
                <p className="text-secondary font-bold">
                  {product.promotional_price}&nbsp;FCFA
                </p>
              </div>
            ) : (
              <p className="mt-2 text-secondary font-bold text-center">
                {product.price} FCFA
              </p>
            )}
            <p
              className="mt-4 h-px w-12 bg-gray-400 mx-auto price-border
                duration-1000"
              aria-hidden="true"
            />
            <AddToCart product={product} />
          </div>
        ))
      )}
    </Carousel>
  );
}

type HomeProductMultiCarouselProps = {
  sort: string;
};
