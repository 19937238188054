import React, { useState } from "react";
import LoadingBox from "./loading-box";
import { classNames } from "../../utils/helper.util";
import { toast } from "react-toastify";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import userService from "../../api/user-service/user/user.service";

/**
 * Subscribe from component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-30
 *
 * @returns {JSX.Element} the javaScriptXML element
 */
export default function SubscribeForm(): JSX.Element {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Submit handler
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-29
   *
   * @param {any} e the react mouse event
   * @returns {void}
   */
  const submitHandler = (e: any): void => {
    e.preventDefault();

    if (email) {
      // Start loading
      setIsLoading(true);

      userService.subscribe(email).subscribe({
        next: () => {
          // Stop loading
          setIsLoading(false);

          // Reset form
          resetForm();

          // Set success message
          toast.success("Inscription effectuée avec succès!", {
            theme: "colored",
            autoClose: 2000,
          });

          resetForm();
        },
        error: (e) => {
          // Stop loading
          setIsLoading(false);

          toast.error(e, {
            theme: "colored",
          });
        },
      });
    }
  };

  /**
   * Reset form
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-18
   *
   * @returns {void}
   */
  const resetForm = (): void => {
    setEmail("");
  };

  return (
    <form onSubmit={submitHandler}>
      <div className="flex justify-between">
        <input
          id="email"
          type="email"
          autoComplete="off"
          className="relative block h-10 w-full appearance-none
                rounded-full rounded-r-none border border-r-0 focus:ring-0
                border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500
                focus:z-10 focus:border-gray-300 focus:outline-none text-xs"
          placeholder="Adresse email..."
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          type="submit"
          className={classNames(
            isLoading
              ? "bg-gray-400 text-gray-400 hover:bg-gray-400"
              : `bg-white hover:bg-ternary focus:outline-non
                        hover:border-secondary hover:bg-secondary`,
            `border border-gray-300 border-l-0 text-black px-5
                    font-bold rounded-full rounded-l-none hover:text-white
                    h-10`
          )}
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="text-black">
              <LoadingBox className="text-white" />
            </div>
          ) : (
            <ArrowLongRightIcon className="h4 w-4" />
          )}
        </button>
      </div>
    </form>
  );
}
