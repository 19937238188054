import React, { Fragment } from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  CheckIcon,
  ChevronUpDownIcon,
  FaceFrownIcon,
  FaceSmileIcon,
} from "@heroicons/react/24/outline";
import { Listbox, Transition } from "@headlessui/react";
import LoadingBox from "../../../shared/components/others/loading-box";
import MessageBox from "../../../shared/components/others/message-box";
import Breadcrumb from "../../../shared/components/banners/breadcrumb";
import ImageMagnifier from "../components/image-magnify.component";
import Rating from "../../../shared/components/others/rating";
import AddToCompareButton from "../../../shared/components/product/add-to-compare-button";
import AddToWhishlistButton from "../../../shared/components/product/add-to-wishlist-button";
import DetailsSection from "../components/details-section.component";
import RelatedProduct from "../components/related-product.component";
import { classNames } from "../../../shared/utils/helper.util";
import AddToCartButton from "../../../shared/components/product/add-to-cart-button";
import productService from "../../../shared/api/product-service/product/product.service";

/**
 * Product page
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
function ProductPage(): JSX.Element {
  const [selected, setSelected] = useState(1);
  const { id } = useParams();
  const [product, setProduct] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [qty, setQty] = useState(1);
  // const navigate = useNavigate();
  let content;

  useEffect(() => {
    productService.getById(id).subscribe({
      next: (data) => {
        setIsLoading(false);
        setProduct(data);
      },
      error: (e) => {
        setIsLoading(false);
        setError(e);
        console.error(e);
      },
    });
  }, [id]);

  if (isLoading) {
    content = (
      <div className="row center">
        <LoadingBox />
      </div>
    );
  } else if (error) {
    content = <MessageBox variant="danger">{error}</MessageBox>;
  } else {
    content = (
      <div className="">
        <div className="bg-oldlace -mt-8 -mx-8 px-8 py-5">
          <Breadcrumb />
        </div>
        <div
          className="grid w-full grid-cols-1 items-start gap-y-8 gap-x-6
            sm:grid-cols-12 lg:gap-x-8 mt-8"
        >
          <div
            className="h-auto aspect-w-2 aspect-h-3 overflow-hidden rounded-lg
                bg-gray-100 sm:col-span-4 lg:col-span-6 border border-gray-200"
          >
            <ImageMagnifier
              height="100%"
              width="100%"
              magnifierHeight={1920}
              magnifieWidth={1920}
              zoomLevel={1.5}
              src={product.image}
              alt={product.name}
              className=""
            />
          </div>
          <div
            className="sm:col-span-8 lg:col-span-6 xl:text-left lg:text-left
                md:text-left sm:text-left text-center"
          >
            <h2 className="text-2xl font-bold text-primary sm:pr-12">
              {product.name}
            </h2>

            <section aria-labelledby="information-heading" className="mt-4">
              <h3 id="information-heading" className="sr-only">
                Product information
              </h3>
              <p>
                <Rating
                  rating={product.rating}
                  numReviews={product.num_reviews}
                />
              </p>
              <p className="text-2xl font-bold text-secondary">
                {product.price} FCFA
              </p>
              <p className="mt-4"> {product.short_description}</p>

              <div
                className="flex mt-4 justify-center xl:justify-start
                lg:justify-start md:justify-start sm:justify-start"
              >
                {product.current_stock > 0 ? (
                  <FaceSmileIcon className="h-5 w-5 mr-2" />
                ) : (
                  <FaceFrownIcon className="h-5 w-5 mr-2" />
                )}

                <div>
                  {product.current_stock > 0 ? (
                    <span className="">{product.current_stock} En stock</span>
                  ) : (
                    <span className="danger">Indisponible</span>
                  )}
                </div>
              </div>
              <div
                className="flex items-center justify-center md:justify-start
                    xl:justify-start lg:justify-start sm:justify-start mt-4"
              >
                {product.current_stock > 0 && (
                  <>
                    <div
                      className="flex items-center grid gap-1 xl:grid-cols-2
                        lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2
                        grid-cols-1"
                    >
                      <div
                        className="col-span-1 flex items-center grid gap-1
                        grid-cols-3 mb-4"
                      >
                        <div className="col-span-1">Quantité</div>
                        <div className="col-span-2 px-4">
                          <Listbox value={selected} onChange={setSelected}>
                            {({ open }) => (
                              <>
                                <div className="relative mt-1 w-full">
                                  <Listbox.Button
                                    className="relative w-full cursor-default
                                        rounded-full border border-gray-300
                                        bg-white py-2 pl-3 pr-10 text-left
                                        shadow-sm focus:border-gray-300
                                        focus:outline-none focus:ring-0
                                        focus:ring-0 sm:text-sm"
                                  >
                                    <span className="flex items-center">
                                      <span className="block truncate">
                                        {selected}
                                      </span>
                                    </span>
                                    <span
                                      className="pointer-events-none
                                        absolute inset-y-0 right-0 ml-3 flex
                                        items-center pr-2"
                                    >
                                      <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options
                                      className="absolute z-40 mt-1 max-h-56
                                        w-full overflow-auto rounded-md
                                        bg-white py-1 text-base shadow-lg
                                        ring-1 ring-black ring-opacity-5
                                        focus:outline-none sm:text-sm"
                                    >
                                      {[
                                        ...Array(product.current_stock).keys(),
                                      ].map((x) => (
                                        <Listbox.Option
                                          key={x + 1}
                                          onClick={() => setQty(x + 1)}
                                          className={({ active }) =>
                                            classNames(
                                              active
                                                ? "text-white bg-secondary"
                                                : "text-gray-900",
                                              `relative cursor-default
                                                select-none py-2 pl-3 pr-9`
                                            )
                                          }
                                          value={x + 1}
                                        >
                                          {({ selected, active }) => (
                                            <>
                                              <div className="flex items-center">
                                                <span
                                                  className={classNames(
                                                    selected
                                                      ? "font-semibold"
                                                      : "font-normal",
                                                    "block truncate"
                                                  )}
                                                >
                                                  {x + 1}
                                                </span>
                                              </div>

                                              {selected ? (
                                                <span
                                                  className={classNames(
                                                    active
                                                      ? "text-white"
                                                      : "text-secondary",
                                                    `absolute inset-y-0
                                                        right-0 flex pr-4
                                                        items-center`
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </>
                            )}
                          </Listbox>
                        </div>
                      </div>
                      <div className="col-span-1 mb-4">
                        {/* <button
                          onClick={() => addToCartHandler(product._id)}
                          className={classNames(
                            addCartIsLoading
                                ? "bg-gray-400 hover:bg-gray-400"
                                : "bg-secondary",
                                `group relative flex w-full justify-center
                                rounded-full border border-transparent  py-2
                                px-4 font-medium text-white hover:bg-ternary
                                focus:outline-none focus:ring-0
                                focus:ring-primary focus:ring-offset-2`
                          )}
                          disabled={addCartIsLoading}>
                          {addCartIsLoading ? (
                            <div className="absolute text-black">
                              <LoadingBox />
                            </div>
                          ) : null}
                          Ajouter au panier
                        </button> */}
                        <AddToCartButton product={product} qty={qty} />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                className="flex mt-4 justify-center xl:justify-start
                    lg:justify-start md:justify-start sm:justify-start"
              >
                <AddToCompareButton product={product} />
                <AddToWhishlistButton product={product} />
              </div>
              <div
                className="h-1 w-full border border-b-gray-200 mt-6
                border-x-transparent border-t-transparent"
              ></div>
              <div className="mt-6">
                <p className="">
                  SKU :
                  <span className="text-sm text-gray-400 ml-2">
                    {product.sku}
                  </span>
                </p>
                <p className="mt-2">
                  Categories :
                  <span className="text-sm text-gray-400 break-words ml-2">
                    {product.categories.map((category: any, index: number) =>
                      product.categories.length == index + 1 ? (
                        <span key={category._id} className="mr-2">
                          {category.name}
                        </span>
                      ) : (
                        <span key={category._id} className="mr-2">
                          {category.name},
                        </span>
                      )
                    )}
                  </span>
                </p>
                <p className="break-words mt-2">
                  Étiquettes :
                  <span
                    className="w-full text-sm text-gray-400 break-words
                    ml-2"
                  >
                    {product.tags.map((tag: any, index: number) =>
                      product.tags.length == index + 1 ? (
                        <span key={tag._id} className="mr-2">
                          {tag.name}
                        </span>
                      ) : (
                        <span key={tag._id} className="mr-2">
                          {tag.name},
                        </span>
                      )
                    )}
                  </span>
                </p>
              </div>
            </section>
          </div>
        </div>

        {/* Product Details */}
        <div className="my-16">
          <DetailsSection product={product} />
        </div>

        {/* Related Products */}
        {product.related_products.length ? (
          <RelatedProduct product={product} />
        ) : null}
      </div>
    );
  }

  return <section>{content}</section>;
}

export default ProductPage;
