import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router";

/**
 * Scroll to top component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-02
 *
 * @param {ScrollToTopProps} props thes shared parametters
 * @returns {JSX.Element} the javaScriptXML element
 */
export default function ScrollToTop(props: ScrollToTopProps): any {
  const location = useLocation();

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [location]);

  return <>{props.children}</>;
}

type ScrollToTopProps = {
  children: any;
};
