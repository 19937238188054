import { Observable, defer, map } from "rxjs";
import { AxiosResponse } from "axios";
import axiosInstance from "../../../utils/http-client.util";

/**
 * Country service
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-08-08
 *
 * class CountryService
 */
class CountryService {
  public lang = "en";

  /**
   * Get all country
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-08
   *
   * @param {number} page the current page index
   * @param {number} perPage the number of items per page
   * @returns {Promise<void>} the eventual completion or failure
   */
  public getCountries(page = 1, perPage = 15): Observable<any> {
    return defer(() =>
      axiosInstance.get(
        `/v1/${this.lang}/countries?page=${page}&perPage=${perPage}`
      )
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Filter countries
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-09-18
   *
   * @param {number} page the current page index
   * @param {number} perPage the number of items per page
   * @param {string} name the country name
   *
   * @returns {Promise<void>} the eventual completion or failure
   */
  public filterCountries({
    name = "",
    page = 1,
    perPage = 15,
  }): Observable<any> {
    return defer(() =>
      axiosInstance.get(
        `/v1/${this.lang}/countries?page=${page}&perPage=${perPage}&name=${name}`
      )
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Create new country
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-08
   *
   * @param {any} data the country data
   * @returns {Observable<any>} the eventual completion or failure
   */
  public store(data: any): Observable<any> {
    return defer(() =>
      axiosInstance.post(`/v1/${this.lang}/countries`, data)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Get country by id
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-08
   *
   * @param {string} countryId the country id
   * @returns {Observable<any>} the eventual completion or failure
   */
  public getCountryById(countryId: string): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/country/${countryId}`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Update country
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-08
   *
   * @param {any} country the country's data
   * @returns {Observable<any>} the eventual completion or failure
   */
  public update(country: any): Observable<any> {
    return defer(() =>
      axiosInstance.put(`/v1/${this.lang}/country/${country._id}`, country)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }
}

const countryService = new CountryService();
export default countryService;
