import React, { useState } from "react";
import { Tab } from "@headlessui/react";
import Reviews from "./reviews.component";
import { classNames } from "../../../shared/utils/helper.util";
import ProductType from "../../../shared/api/product-service/product/product.type";

/**
 * Product details section component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @param {DetailsSectionProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function DetailsSection(
  props: DetailsSectionProps
): JSX.Element {
  const product = props.product;
  const [sections] = useState([
    { id: 1, title: "Description", value: "description" },
    { id: 2, title: "Détails Du Produit", value: "details" },
    { id: 3, title: "Avis", value: "avis" },
  ]);

  return (
    <Tab.Group>
      <Tab.List
        className="flex grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3
            sm:grid-cols-3 grid-cols-1 max-w-xl mx-auto space-x-1 rounded-xl
            product-details-section"
      >
        {sections.map((section) => (
          <div key={section.id} className="overflow-hidden" id={section.value}>
            <Tab
              key={section.id}
              className={({ selected }) =>
                classNames(
                  `col-span-1 flex justify-center h-full w-full py-2 font-bold
                    xl:text-xl lg:text-xl md:text-xl sm:text-xl text-xl`,
                  "focus:outline-none focus:ring-0",
                  selected
                    ? `text-secondary xl:border-b-2 lg:border-b-2 md:border-b-2
                        sm:border-b-2 border-secondary`
                    : "text-primary hover:text-secondary"
                )
              }
            >
              {section.title}
            </Tab>
            <p className="mx-auto bg-secondary mb-0 w-10 product list-btn"></p>
          </div>
        ))}
      </Tab.List>
      <Tab.Panels className="border-t border-gray-200 pt-8">
        {sections.map((section) => (
          <Tab.Panel
            key={section.id}
            className={classNames(
              "rounded-xl bg-white px-10",
              `ring-white ring-opacity-60 ring-offset-2 focus:outline-none
              focus:ring-0`
            )}
          >
            {section.value === "description" ? (
              <div className="text-justify leading-7">
                {product.description}
              </div>
            ) : section.value === "details" ? (
              <div className="overflow-x-auto relative">
                <table
                  className="w-full border border-gray-200 text-sm
                `text-left text-gray-500 dark:text-gray-400"
                >
                  <tbody>
                    <tr
                      className="bg-gray-50 border-t border-gray-200
                        dark:bg-gray-200 dark:border-gray-200"
                    >
                      <th
                        scope="col"
                        className="w-1/2 border border-gray-200 text-primary
                            capitalize py-3 px-6"
                      >
                        Nom du produit
                      </th>
                      <td
                        scope="row"
                        className="border border-gray-200 py-4 px-6 font-medium
                            text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {product.name}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="col"
                        className="border border-gray-200 text-primary
                            capitalize py-3 px-6"
                      >
                        Marque
                      </th>
                      <td className="border border-gray-200 py-4 px-6">
                        {product.brand}
                      </td>
                    </tr>
                    <tr className="bg-gray-50">
                      <th
                        scope="col"
                        className="border border-gray-200 text-primary
                            capitalize py-3 px-6"
                      >
                        Categories
                      </th>
                      <td className="border border-gray-200 py-4 px-6">
                        {product.categories.length > 0
                          ? product.categories.map((category: any) => (
                              <span
                                className="flex items-center mr-1"
                                key={category._id}
                              >
                                {category.name}
                              </span>
                            ))
                          : null}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="col"
                        className="border border-gray-200 text-primary
                            capitalize py-3 px-6"
                      >
                        Prix
                      </th>
                      <td className="border border-gray-200 py-4 px-6">
                        {product.price} FCFA
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <Reviews product={product} />
            )}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
}

type DetailsSectionProps = {
  product: ProductType;
};
