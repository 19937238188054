import React from "react";
import OrderType from "../../../shared/api/order-service/order/order.type";
import { Link, useNavigate } from "react-router-dom";
import {
  _date,
  classNames,
  numberFormatter,
} from "../../../shared/utils/helper.util";
import { EyeIcon } from "@heroicons/react/24/outline";

/**
 * Order items
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-04
 *
 * @param {DetailsSectionProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function OrderItems(props: DetailsSectionProps): JSX.Element {
  const { orders } = props;
  const navigate = useNavigate();

  return (
    <div>
      <div className="overflow-x-auto relative">
        <table
          className="w-full border border-gray-200 text-sm
            `text-left text-gray-500 dark:text-gray-400"
        >
          <thead>
            <tr className="bg-gray-100">
              <th
                scope="col"
                className="border border-gray-200 text-primary
                            capitalize py-4 px-6"
              >
                Commande
              </th>
              <th
                scope="col"
                className="border border-gray-200 text-primary
                            capitalize py-4 px-6"
              >
                Date
              </th>
              <th
                scope="col"
                className="border border-gray-200 text-primary
                            capitalize py-4 px-6"
              >
                État
              </th>
              <th
                scope="col"
                className="border border-gray-200 text-primary
                            capitalize py-4 px-6"
              >
                Total
              </th>
              <th
                scope="col"
                className="border border-gray-200 text-primary
                            capitalize py-4 px-6"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {orders.length &&
              orders.map((order: OrderType, index) => (
                <tr
                  key={order._id}
                  className={classNames(
                    index % 2 === 1 && "bg-gray-100",
                    `border-t border-gray-200 dark:bg-gray-200
                                    dark:border-gray-200`
                  )}
                >
                  <td className="text-center py-4">
                    <Link
                      to=""
                      className="text-secondary
                                            hover:text-ternary"
                    >
                      N°{order.order_number}
                    </Link>
                  </td>
                  <td className="">{_date(order.created_at)}</td>
                  <td className="">
                    {order.is_delivered ? "Terminé" : "En cours"}
                  </td>
                  <td className="">
                    {numberFormatter(order?.total_price)}
                    &nbsp; pour &nbsp;
                    {order.order_items.length}
                    &nbsp; articles
                  </td>
                  <td className="">
                    <div className="flex justify-center">
                      <button
                        className="flex bg-secondary w-22
                                            hover:bg-ternary focus:ring-offset-2
                                            focus:outline-none focus:ring-0
                                            text-white justify-between border
                                            focus:ring-primary font-medium px-4
                                            border-transparent rounded py-2"
                        onClick={() =>
                          navigate(`/my-account/orders/view-order/${order._id}`)
                        }
                      >
                        Voir
                        <span className="">
                          <EyeIcon
                            className="h-5 w-5
                                                        text-white
                                                        group-hover:text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

type DetailsSectionProps = {
  orders: Array<OrderType>;
};
