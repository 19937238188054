import React, { useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";

const ENDPOINT =
  window.location.host.indexOf("localhost") >= 0
    ? "http://127.0.0.1:5000"
    : window.location.host;

/**
 * Chat box component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-29
 *
 * @param {ChatBoxProps} props the shared properties
 * @returns {JSX.Element} the javaScriptXML element
 */
export default function ChatBox(props: ChatBoxProps): JSX.Element {
  const userInfo = props.userInfo;
  const [socket, setSocket] = useState(null) as any;
  const uiMessagesRef = useRef(null) as any;
  const [isOpen, setIsOpen] = useState(false);
  const [messageBody, setMessageBody] = useState("");
  const [messages, setMessages] = useState([
    { name: "Admin", body: "Hello there, Please ask your question." },
  ]);

  useEffect(() => {
    if (uiMessagesRef.current) {
      uiMessagesRef.current.scrollBy({
        top: uiMessagesRef.current.clientHeight,
        left: 0,
        behavior: "smooth",
      });
    }
    if (socket) {
      socket.emit("onLogin", {
        _id: userInfo._id,
        name: userInfo.name,
        isAdmin: userInfo.isAdmin,
      });
      socket.on("message", (data: any) => {
        setMessages([...messages, { body: data.body, name: data.name }]);
      });
    }
  }, [messages, isOpen, socket, userInfo]);

  /**
   * Support handler
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-29
   *
   * @returns {void}
   */
  const supportHandler = (): void => {
    setIsOpen(true);
    console.log(ENDPOINT);
    const sk = socketIOClient(ENDPOINT);
    setSocket(sk);
  };

  /**
   * Submit handler
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-29
   *
   * @param {any} e the react mouse event
   * @returns {void}
   */
  const submitHandler = (e: any): void => {
    e.preventDefault();
    if (!messageBody.trim()) {
      alert("Error. Please type message.");
    } else {
      setMessages([...messages, { body: messageBody, name: userInfo.name }]);
      setMessageBody("");
      setTimeout(() => {
        socket.emit("onMessage", {
          body: messageBody,
          name: userInfo.name,
          isAdmin: userInfo.isAdmin,
          _id: userInfo._id,
        });
      }, 1000);
    }
  };

  /**
   * Close handler
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-29
   *
   * @returns {void}
   */
  const closeHandler = (): void => {
    setIsOpen(false);
  };
  return (
    <div className="chatbox">
      {!isOpen ? (
        <button type="button" onClick={supportHandler}>
          <i className="fa fa-support" />
        </button>
      ) : (
        <div className="card card-body">
          <div className="row">
            <strong>Support </strong>
            <button type="button" onClick={closeHandler}>
              <i className="fa fa-close" />
            </button>
          </div>
          <ul ref={uiMessagesRef}>
            {messages.map((msg, index) => (
              <li key={index}>
                <strong>{`${msg.name}: `}</strong> {msg.body}
              </li>
            ))}
          </ul>
          <div>
            <form onSubmit={submitHandler} className="row">
              <input
                value={messageBody}
                onChange={(e) => setMessageBody(e.target.value)}
                type="text"
                placeholder="type message"
              />
              <button type="submit">Send</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

type ChatBoxProps = {
  userInfo: any;
};
