import React from "react";
import { Outlet } from "react-router-dom";

/**
 * Address component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-11
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function Address(): JSX.Element {
  return <Outlet />;
}
