import React from "react";
import Banner from "../../../shared/components/banners/banner";

/**
 * About page
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-12
 *
 * @returns {JSX.Element} the jsx element
 */
export default function AboutPage(): JSX.Element {
  return (
    <>
      <Banner title="À propos de nous" />
      <div className="mt-12">
        <div
          className="grid grid-cols-1 xl:grid-cols-8 lg:grid-cols-8
            md:grid-cols-8"
        >
          <div
            className="col-start-2 col-span-4 hidden xl:block lg:block
            md:block"
          >
            <img
              src="assets/images/about/about_1.jpg"
              alt="About us"
              className="h-96 w-full rounded"
            />
          </div>
          <div
            className="col-span-2 flex items-center xl:-ml-44 lg:-ml-44
            md:-ml-44"
          >
            <div
              className="bg-white border border-gray-300 shadow-md
              h-auto p-4 rounded leading-7"
            >
              <h2 className="text-primary font-bold text-3xl mb-4">
                Qui sommes-nous ?
              </h2>
              <p>Bienvenue chez KITECOLE - Votre Partenaire de Confiance</p>
              <p className="mt-4 text-justify">
                Chez KITECOLE, nous croyons en la puissance de
                l&apos;innovation, de la qualité et du service exceptionnel.
                Fondée en 2018, nous avons évolué pour devenir une entreprise
                leader dans l&apos;industrie de vente en ligne. Notre engagement
                envers l&apos;excellence et la satisfaction du client a été le
                moteur de notre croissance constante au fil des années.
              </p>
            </div>
          </div>
        </div>
        <div
          className="grid grid-cols-1 gap-8 mt-12 mb-4 bg-oldlace px-8 py-16
          -mx-8 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2"
        >
          <div className="col-span-1 bg-white shadow p-4 leading-7 rounded">
            <div className="">
              <h2 className="text-primary font-bold text-3xl mb-4">
                Notre Mission
              </h2>
              <p className="mt-4 text-justify">
                À KITECOLE, notre mission est de transformer la vie de nos
                clients en fournissant des produits de qualité inégalée et un
                service exceptionnel. Nous sommes déterminés à créer des
                solutions de vente en ligne innovantes qui répondent aux besoins
                uniques de nos clients et dépassent leurs attentes.
              </p>
            </div>
          </div>
          <div className="col-span-1 bg-white shadow p-4 leading-7 rounded">
            <div className="">
              <h2 className="text-primary font-bold text-3xl mb-4">
                Ce que nous faisons
              </h2>
              <p className="mt-4 text-justify">
                Nous sommes fiers des fournitures scolaires et de bureau que
                nous proposons à nos clients (élèves, étudiants, personnel de
                bureau,...). Avec une équipe dévouée d&apos;experts de
                l&apos;industrie, nous concevons, développons et fournissons des
                solutions vente en ligne de pointe qui font la différence dans
                la vie de nos clients.
              </p>
            </div>
          </div>
          <div className="col-span-1 bg-white shadow p-4 leading-7 rounded">
            <h2 className="text-primary font-bold text-3xl mb-4">
              Pourquoi nous ?
            </h2>
            <p className="mt-4 text-justify">
              <ul>
                <li>
                  Qualité exceptionnelle : Nous nous engageons à maintenir les
                  normes les plus élevées de qualité dans tout ce que nous
                  faisons.
                </li>
                <li>
                  Innovation : Nous investissons constamment dans la recherche
                  et le développement pour rester à la pointe de
                  l&apos;industrie.
                </li>
                <li>
                  Service Client Exceptionnel : Notre équipe dédiée est là pour
                  vous accompagner à chaque étape.
                </li>
                <li>
                  Engagement envers la durabilité : Nous nous efforçons de
                  minimiser notre impact environnemental.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
