import React from "react";
import { Popover } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import horizontalMenuItems from "../../../../resources/navigations/horizontal-menu-items.json";

/**
 * Header horizontal menu component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @param {HeaderHorizontalMenuProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function HeaderHorizontalMenu(
  props: HeaderHorizontalMenuProps
): JSX.Element {
  const textColor = props.textColor;

  return (
    <Popover.Group className="hidden lg:block lg:self-stretch">
      <div className="flex h-full space-x-8">
        {horizontalMenuItems.map((item: any) => (
          <NavLink
            key={item.name}
            to={item.url}
            end={item.url == "/" ? true : false}
            className={({ isActive }) =>
              isActive
                ? `text-secondary flex items-center font-bold`
                : `${textColor} flex items-center font-bold`
            }
          >
            {item.name}
            &nbsp;
            {item.icon && <ChevronDownIcon className="h-4 w-4" />}
          </NavLink>
        ))}
      </div>
    </Popover.Group>
  );
}

type HeaderHorizontalMenuProps = {
  textColor: string;
};
