import React from "react";
import { Route, Routes } from "react-router-dom";
import FaqPage from "../pages/faq.page";

/**
 * Faq route
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-15
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function FaqRoute(): JSX.Element {
  return (
    <Routes>
      <Route path="/faqs" element={<FaqPage />}></Route>
    </Routes>
  );
}
