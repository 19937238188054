import React from "react";
import { classNames } from "../../../shared/utils/helper.util";
import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import customerAccountMenuItems from "../../../resources/navigations/customer-account-menu-items.json";
import DynamicHeroIcon from "../../../shared/components/others/dynamic-heroIcon";
import { toast } from "react-toastify";
import authService from "../../../shared/api/auth-service/auth/auth.service";

/**
 * Setting component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-07
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function Setting(): JSX.Element {
  const { pathname } = useLocation();
  const pathNames = pathname.split("/").filter((name) => name !== "");
  const lastPathName = pathNames.slice(-1).toString();
  const navigate = useNavigate();

  /**
   * Signout handler
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-11
   *
   * @param {any} e the react mouse event
   * @returns {void}
   */
  const signoutHandler = (e: any): void => {
    e.preventDefault();

    authService.signout().subscribe({
      next: () => {
        toast.success("Déconnexion réussie!", {
          theme: "colored",
        });

        setTimeout(() => {
          navigate(`/signin?redirect=${pathname}`);
        }, 2000);
      },
      error: (e) => {
        toast.error(e, {
          theme: "colored",
        });
      },
    });
  };

  return (
    <div
      className="grid xl:grid-cols-11 lg:grid-cols-11 md:grid-cols-11
            grid-cols-1 xl:pt-12"
    >
      <div
        className="col-span-3 border border-x-0 border-b-0
            border-t-gray-200 w-min-32"
      >
        {customerAccountMenuItems.map((item: any) =>
          item.slug !== "logout" ? (
            <Link
              key={item.name}
              to={
                item.slug === "dashboard"
                  ? "/my-account"
                  : `/my-account/${item.slug}`
              }
              className={classNames(
                pathNames.includes(item.slug) ||
                  (lastPathName === "my-account" && item.slug === "dashboard")
                  ? "text-secondary"
                  : "text-gray-500",
                `flex items-center block py-3 border border-x-0
                          border-t-0 border-b-gray-200`
              )}
            >
              <div className="w-full flex">
                <div className="w-full">{item.name}</div>
                <div
                  className="float-right flex-1 justify-end
                                items-center right-0"
                >
                  {item.icon && (
                    <DynamicHeroIcon icon={item.icon} className="w-4 h-4" />
                  )}
                </div>
              </div>
            </Link>
          ) : (
            <Link
              to="#"
              key={item.name}
              onClick={(e) => signoutHandler(e)}
              className={classNames(
                pathNames.includes(item.slug) ||
                  (lastPathName === "my-account" && item.slug === "dashboard")
                  ? "text-secondary"
                  : "text-gray-500",
                `flex items-center block py-3 border border-x-0
                            border-t-0 border-b-gray-200 cursor-pointer`
              )}
            >
              <div className="w-full flex">
                <div className="w-full">{item.name}</div>
                <div
                  className="float-right flex-1 justify-end
                                    items-center right-0"
                >
                  {item.icon && (
                    <DynamicHeroIcon icon={item.icon} className="w-4 h-4" />
                  )}
                </div>
              </div>
            </Link>
          )
        )}
      </div>
      <div
        className="col-span-8 xl:pl-12 lg:pl-12 md:pl-12 pl-0 xl:pt-0 lg:pt-0
            md:pt-0 pt-6"
      >
        {/* <LoadCustomerComponent slug={anchor} /> */}
        <Outlet />
      </div>
    </div>
  );
}
