import { Observable, defer, map } from "rxjs";
import { AxiosResponse } from "axios";
import axiosInstance from "../../../utils/http-client.util";

/**
 * State service
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-08-08
 *
 * class StateService
 */
class StateService {
  public lang = "en";

  /**
   * Get all states
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-08
   *
   * @param {number} page the current page index
   * @param {number} perPage the number of items per page
   * @returns {Promise<void>} the eventual completion or failure
   */
  public getStates(page = 1, perPage = 15): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/states/${page}/${perPage}`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Create new state
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-08
   *
   * @param {any} data the state data
   * @returns {Observable<any>} the eventual completion or failure
   */
  public store(data: any): Observable<any> {
    return defer(() =>
      axiosInstance.post(`/v1/${this.lang}/states`, data)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Get state by id
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-08
   *
   * @param {string} stateId the state id
   * @returns {Observable<any>} the eventual completion or failure
   */
  public getStateById(stateId: string): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/state/${stateId}`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Get states by country
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-08
   *
   * @param {string} countryId the country id
   * @returns {Observable<any>} the eventual completion or failure
   */
  public getStatesByCountry(countryId: string): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/states/country/${countryId}`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Update state
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-08
   *
   * @param {any} state the state's data
   * @returns {Observable<any>} the eventual completion or failure
   */
  public update(state: any): Observable<any> {
    return defer(() =>
      axiosInstance.put(`/v1/${this.lang}/state/${state._id}`, state)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }
}

const stateService = new StateService();
export default stateService;
