import React, { useState, useEffect } from "react";
import NoticeBox from "../../../shared/components/others/notice-box";
import orderService from "../../../shared/api/order-service/order/order.service";
import useLocalStorage from "../../../shared/hooks/use-localstorage";
import LoadingBox from "../../../shared/components/others/loading-box";
import MessageBox from "../../../shared/components/others/message-box";
import OrderType from "../../../shared/api/order-service/order/order.type";
import OrderItems from "../../../shared/components/order/order-items";

/**
 * Order list component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-04
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function OrderList(): JSX.Element {
  const [orders, setOrders] = useState<Array<OrderType>>([]);
  const [ordersError, setOrdersError] = useState("");
  const [ordersIsLoading, setOrdersIsLoading] = useState(true);
  const [userInfo] = useLocalStorage("userInfo", null);

  // Get orders
  useEffect(() => {
    orderService.getOrdersByUser(userInfo && userInfo._id).subscribe({
      next: (data) => {
        // Stop loader
        setOrdersIsLoading(false);

        setOrders(data);
      },
      error: (e) => {
        // Stop loader
        setOrdersIsLoading(false);

        setOrdersError(e);
      },
    });
  }, []);

  return (
    <div>
      {ordersIsLoading ? (
        <LoadingBox />
      ) : ordersError ? (
        <MessageBox variant="danger">{ordersError}</MessageBox>
      ) : orders ? (
        <OrderItems orders={orders} />
      ) : (
        <NoticeBox
          text="Aucune commande n’a encore été passée."
          link="/shop"
          linkTitle="Parcourir les produits"
        />
      )}
    </div>
  );
}
