import React from "react";
import { Navigate } from "react-router-dom";
import useLocalStorage from "../../hooks/use-localstorage";

/**
 * Private route component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-27
 *
 * @param {PrivateRouteProps} props the shared properties
 * @returns {JSX.Element} the javaScriptXML element
 */
const PrivateRoute = (props: PrivateRouteProps) => {
  const children = props.children;
  const [userInfo] = useLocalStorage("userInfo", null);
  const redirect = props.path ? props.path : "/";

  return userInfo ? children : <Navigate to={`/signin?redirect=${redirect}`} />;
};

type PrivateRouteProps = {
  children: any;
  path?: string;
};

export default PrivateRoute;
