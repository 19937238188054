import React from "react";
import { Route, Routes } from "react-router-dom";
import SigninPage from "../pages/signin.page";
import { AuthLayout } from "../../../shared/components/others/auth-layout";
import LoggedRoute from "../../../shared/components/route/logged-route";

/**
 * Signin route
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function SigninRoute(): JSX.Element {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route
          path="/signin"
          element={
            <LoggedRoute>
              <SigninPage />
            </LoggedRoute>
          }
        />
      </Route>
    </Routes>
  );
}
