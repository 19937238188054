import { Observable, defer, map, throwError } from "rxjs";
import axiosInstance from "../../../utils/http-client.util";
import { AxiosResponse } from "axios";
import { parseJwt } from "../../../utils/helper.util";

/**
 * Authentication service
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-07
 *
 * class AuthService
 */
class AuthService {
  private lang = "en";

  /**
   * Signin
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-05
   *
   * @param {string} email the user's email
   * @param {string} password the user's password
   * @param {boolean} rememberMe This value determines whether or not to save
   * the user's login information.
   * @returns {Observable<any>} the eventual completion or failure
   */
  public signin(
    email: any,
    password: any,
    rememberMe: boolean
  ): Observable<any> {
    try {
      return defer(() =>
        axiosInstance.post(`/v1/${this.lang}/auth/login`, { email, password })
      ).pipe(
        map((axiosResponse: AxiosResponse) => {
          // console.log(axiosResponse.data);
          const data = axiosResponse.data;

          // Decode access token
          const payload = parseJwt(data.accessToken);

          const userInfo = {
            _id: payload.userId,
            username: payload.username,
            last_name: payload.last_name || "",
            first_name: payload.first_name || "",
            email: payload.email,
            gender: payload.gender,
            roles: payload.roles,
          };

          // Store user information
          localStorage.setItem("userInfo", JSON.stringify(userInfo));

          // Store access token
          localStorage.setItem("accessToken", JSON.stringify(data.accessToken));

          // Store refresh token
          localStorage.setItem(
            "refreshToken",
            JSON.stringify(data.refreshToken)
          );

          // Store user login information
          if (rememberMe) {
            localStorage.setItem(
              "userLogin",
              btoa(JSON.stringify({ email, password, rememberMe }))
            );
          } else {
            localStorage.removeItem("userLogin");
          }

          window.dispatchEvent(new Event("storage"));

          return data;
        })
      );
    } catch (error: any) {
      // console.log(error);
      return throwError(() => new Error(error));
    }
  }

  /**
   * Logout
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-05
   *
   * @returns {Observable<any>} the eventual completion or failure
   */
  public signout(): Observable<any> {
    return defer(() => axiosInstance.get(`/v1/${this.lang}/auth/logout`)).pipe(
      map((axiosResponse: AxiosResponse) => {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        // localStorage.removeItem("cartItems");
        // localStorage.removeItem("shippingAddress");

        window.dispatchEvent(new Event("storage"));

        return axiosResponse.data;
      })
    );
  }

  /**
   * Refresh token
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-07
   *
   * @param {string} refreshToken the refreshtoken data
   * @returns {Observable<any>} the eventual completion or failure
   */
  public refreshToken(refreshToken: string): Observable<any> {
    return defer(() =>
      axiosInstance.post(`/v1/${this.lang}/auth/refresh`, { refreshToken })
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        const data = axiosResponse.data;

        // Store access token
        localStorage.setItem("accessToken", JSON.stringify(data.accessToken));

        // Store refresh token
        localStorage.setItem("refreshToken", JSON.stringify(data.refreshToken));

        window.dispatchEvent(new Event("storage"));

        return data;
      })
    );
  }

  /**
   * Get local access token
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-08
   *
   * @returns {any} of the local access token
   */
  public getLocalAccessToken(): any {
    const accessToken = localStorage.getItem("accessToken")
      ? JSON.parse(localStorage.getItem("accessToken") as string)
      : null;

    return accessToken;
  }

  /**
   * Get local refresh token
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-08
   *
   * @returns {any} of the local refresh token
   */
  public getLocalRefreshToken() {
    const refreshToken = localStorage.getItem("refreshToken")
      ? JSON.parse(localStorage.getItem("refreshToken") as string)
      : null;

    return refreshToken;
  }

  /**
   * Cleat local auth data
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-08
   *
   * @returns {void}
   */
  public clearLocalAuthData(): void {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userInfo");

    window.dispatchEvent(new Event("storage"));
  }
}

const authService = new AuthService();
export default authService;
