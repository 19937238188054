import { Observable, defer, map } from "rxjs";
import { AxiosResponse } from "axios";
import axiosInstance from "../../../utils/http-client.util";

/**
 * Compare service
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-01
 *
 * class CompareService
 */
class CompareService {
  public lang = "en";

  /**
   * Add a product to compare
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-30
   *
   * @param {any} productId the product's id
   * @returns {Observable<any>} the eventual completion or failure
   */
  public addToCompare(productId: any): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/product/${productId}`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        const data = axiosResponse.data;
        const payload = {
          name: data.name,
          image: data.image,
          price: data.price,
          current_stock: data.current_stock,
          _id: data._id,
        };

        //Get products from local storage
        const compare: Array<any> = localStorage.getItem("compareItems")
          ? JSON.parse(localStorage.getItem("compareItems") as any)
          : [];

        const compareItems = [...compare, payload];

        //Set products to local storage
        localStorage.setItem("compareItems", JSON.stringify(compareItems));

        return compareItems;
      })
    );
  }

  /**
   * Remove a product from compare
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-30
   *
   * @param {any} productId the product's id
   * @returns {Observable<any>} the eventual completion or failure
   */
  public removeFromCompare(productId: any): Observable<any> {
    //Get products from local storage
    const compare: any = localStorage.getItem("compareItems")
      ? JSON.parse(localStorage.getItem("compareItems") as any)
      : [];

    // Remove product from storage
    const compareItems = compare.filter((item: any) => item._id != productId);

    // Update products from storage
    if (!compareItems.length) localStorage.removeItem("compareItems");
    else localStorage.setItem("compareItems", JSON.stringify(compareItems));

    return new Observable((subscriber) => {
      subscriber.next(compareItems);
    });
  }
}

const compareService = new CompareService();
export default compareService;
