import React, { useState } from "react";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import LoadingBox from "../others/loading-box";
import { toast } from "react-toastify";
import { classNames } from "../../utils/helper.util";
import cartService from "../../api/product-service/cart/cart.service";
import ProductType from "../../api/product-service/product/product.type";

/**
 * Add to cart component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-28
 *
 * @param {AddToCartProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function AddToCart(props: AddToCartProps): JSX.Element {
  const product = props.product;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProuct, setSelectedProuct] = useState(0) as any;
  const navigate = useNavigate();
  /**
   * Add product to cart
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-28
   *
   * @param {string} id the product's id
   * @returns {void}
   */
  const addToCartHandler = (id: string): void => {
    const qty = 1;
    // Start loader
    setIsLoading(true);

    setSelectedProuct(id as any);

    cartService.addToCart(id, qty).subscribe({
      next: () => {
        // toast.success('Produit ajouté avec succès au panier!', {
        //   theme: "colored",
        // });

        setIsLoading(false);
      },
      error: () => {
        setIsLoading(false);
        toast.error("Error !", {
          theme: "colored",
        });
      },
    });
  };
  return (
    <button
      onClick={() => {
        if (!product.current_stock) navigate(`/product/${product._id}`);
        else addToCartHandler(product._id);
      }}
      className={classNames(
        isLoading && selectedProuct == product._id
          ? "text-gray-400"
          : "hover:text-secondary",
        props.className ||
          `flex justify-center items-center mt-4 mb-8 mx-auto
          bg-transparent font-bold text-xs text-center`
      )}
      disabled={isLoading && selectedProuct == product._id ? true : false}
    >
      {isLoading && selectedProuct == product._id ? (
        <div className="absolute text-black">
          <LoadingBox />
        </div>
      ) : null}
      <span className="uppercase">
        {product.current_stock <= 0 ? "En savoir plus" : "Ajouter au panier"}
      </span>
      <ChevronDoubleRightIcon
        className="h-3 w-3 chevron -mt-px pb-px
        duration-500"
      />
    </button>
  );
}

type AddToCartProps = {
  product: ProductType;
  className?: string;
};
