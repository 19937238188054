import React from "react";
import { Routes } from "react-router-dom";
// import AdminRoute from "../components/route/AdminRoute";

/**
 * Admin route
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-27
 *
 * @returns {JSX.Element} the javaScriptXML element
 */
export default function AdminRoutes(): JSX.Element {
  return (
    <Routes>
      {/* <Route
              path="/productlist"
              element={
                <AdminRoute>
                  <ProductListPage />
                </AdminRoute>
              }
            />

            <Route
              path="/productlist/pageNumber/:pageNumber"
              element={
                <AdminRoute>
                  <ProductListPage />
                </AdminRoute>
              }
            />
            <Route
              path="/orderlist"
              element={
                <AdminRoute>
                  <OrderListPage />
                </AdminRoute>
              }
            />
            <Route
              path="/userlist"
              element={
                <AdminRoute>
                  <UserListPage />
                </AdminRoute>
              }
            />
            <Route
              path="/user/:id/edit"
              element={
                <AdminRoute>
                  <UserEditPage />
                </AdminRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <AdminRoute>
                  <DashboardPage />
                </AdminRoute>
              }
            />
            <Route
              path="/support"
              element={
                <AdminRoute>
                  <SupportPage />
                </AdminRoute>
              }
            /> */}
    </Routes>
  );
}
