import { Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useState } from "react";
import SearchBox from "../../header/headersearchbox/search-box";
import { classNames } from "../../../utils/helper.util";

/**
 * FooterSearch component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @param {FooterSearchProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function FooterSearch(props: FooterSearchProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const { navClicked } = props;
  console.log(navClicked);

  /**
   * Button event
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @returns {void}
   */
  const buttonclicked = (): void => {
    setOpen(!open);
    console.log(open);
  };

  useEffect(() => {
    if (open && navClicked) setOpen(false);
  }, [navClicked]);

  return (
    <Fragment>
      <div
        className="cursor-pointer text-primary-100 hover:text-secondary"
        onClick={buttonclicked}>
        <div className="flex justify-center">
          <MagnifyingGlassIcon
            className={classNames(open ? "text-secondary" : "text-primary-100", "h-6 w-6")}
          />
        </div>
        <div
          className={classNames(
            open ? "text-secondary" : "text-primary-100",
            "flex justify-center font-bold text-xs"
          )}>
          Recherche
        </div>
      </div>
      <div className="w-full footer-search absolute left-0 bottom-0">
        <Transition
          appear={true}
          show={open}
          enter="transition ease-out duration-1000 transform"
          enterFrom="translate-y-full"
          enterTo="-translate-y-0"
          leave= "transition ease-in-out duration-1000 dilay-1000 transform"
          leaveFrom="-translate-y-0"
          leaveTo="translate-y-full">
          <div className="bg-white h-80 grid grid-cols-6 gap-4 text-center sm:mt-0 sm:text-left">
            <div className="col-start-2 col-span-4">
              <SearchBox />
            </div>
          </div>
        </Transition>
      </div>
    </Fragment>
  );
}

type FooterSearchProps = {
  navClicked: boolean;
};
