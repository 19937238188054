import React, { useState, useEffect } from "react";
import { classNames } from "../../../shared/utils/helper.util";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import Faqs from "../../../resources/data/faqs.json";

/**
 * Faq items component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-07
 *
 * @param {FaqItemsProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function FaqItems(props: FaqItemsProps): JSX.Element {
  const { slug } = props;
  const [faqs, setFaqs] = useState<Array<any>>();

  useEffect(() => {
    setFaqs(Faqs);
  }, []);

  return (
    <div className="w-full">
      <div className="w-full bg-white">
        {faqs
          ?.filter((faq) => faq.slug === slug)
          .map((faq) => (
            <div className="mb-6" key={faq.question}>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className="flex w-full justify-between border-b
                        border-gray-200 pb-3 pr-2 font-medium
                        focus:outline-none"
                    >
                      <p className="text-left">{faq.question}</p>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-4 w-4`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel
                      className="px-4 pt-4 pb-2 text-sm text-gray-500
                        text-justify leading-7"
                    >
                      {faq.response}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          ))}
      </div>
    </div>
  );
}

type FaqItemsProps = {
  slug: string;
};
