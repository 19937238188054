import React, { useEffect, useState } from "react";
import { PlayIcon } from "@heroicons/react/24/outline";
import { Link, useParams } from "react-router-dom";
import MultiRangeSlider from "multi-range-slider-react";
import LoadingBox from "../../../shared/components/others/loading-box";
import MessageBox from "../../../shared/components/others/message-box";
import { classNames } from "../../../shared/utils/helper.util";
import categoryService from "../../../shared/api/product-service/category/category.service";
import tagService from "../../../shared/api/product-service/tag/tag.service";

/**
 * Add to compare button component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @param {ProductFilterProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function ProductFilter(props: ProductFilterProps): JSX.Element {
  const [stateTag, setStateTag] = useState("");
  const [minValue, set_minValue] = useState(25);
  const [maxValue, set_maxValue] = useState(5000);

  const [categoryIsLoading, setCategoryIsLoading] = useState(true);
  const [categoryError, setCategoryError] = useState("");
  const [categories, setCategories] = useState([]);

  const [tagIsLoading, setTagIsLoading] = useState(true);
  const [tagError, setTagError] = useState("");
  const [tags, setTags] = useState([]);

  const { tag: tagParam } = useParams();

  /**
   * Handle input
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} e the react mouse event
   * @returns {void}
   */
  const handleInput = (e: any): void => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };

  // Get all categories
  useEffect(() => {
    categoryService.getAll().subscribe({
      next: (data) => {
        // Stop loading
        setCategoryIsLoading(false);

        setCategories(data);
      },
      error: (e) => {
        // Stop loading
        setCategoryIsLoading(false);

        setCategoryError(e);
      },
    });
  }, []);

  // Get all tags
  useEffect(() => {
    tagService.getAll().subscribe({
      next: (data) => {
        // Stop loading
        setTagIsLoading(false);

        setTags(data);
      },
      error: (e) => {
        // Stop loading
        setTagIsLoading(false);

        setTagError(e);
      },
    });
  }, []);

  /**
   * Handle category change
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} category the product's category
   * @returns {void}
   */
  const handleChangeCategory = (category: any): void => {
    props.onCategoryChange({ category });
  };

  /**
   * Handle MinMax change
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @returns {void}
   */
  const handleChangeMinMax = (): void => {
    props.onMinMaxChange({ min: minValue, max: maxValue });
  };

  /**
   * Handle MinMax change
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} tag the product's tag
   * @returns {void}
   */
  const handleChangeTag = (tag: any): void => {
    props.onTagChange({ tag });
    setStateTag(tag);
  };

  return (
    <>
      <div className="mt-3">
        <h3
          className="text-lg text-primary font-bold pb-4 border-b
            border-dotted border-b-gray-200"
        >
          Catégories
        </h3>
        <div className="mt-8">
          {categoryIsLoading ? (
            <LoadingBox></LoadingBox>
          ) : categoryError ? (
            <MessageBox variant="danger">{categoryError}</MessageBox>
          ) : categories.length > 0 ? (
            categories.map((category: any) =>
              category.products.length || !category.products.length ? (
                <div className="flex items-center mb-3" key={category._id}>
                  <PlayIcon className="h-3 w-3 mr-3 text-secondary" />
                  <Link
                    to="#"
                    onClick={() => handleChangeCategory(category.slug)}
                  >
                    {category.name}
                    <span className="ml-1 text-gray-400">
                      ({category.products.length})
                    </span>
                  </Link>
                </div>
              ) : null
            )
          ) : null}
        </div>
      </div>
      <div className="mt-10">
        <h3
          className="text-lg text-primary font-bold pb-2 border-b
            border-dotted border-b-gray-200"
        >
          Prix
        </h3>
        <div className="mt-8">
          <MultiRangeSlider
            min={25}
            max={20000}
            step={5}
            minValue={minValue}
            maxValue={maxValue}
            ruler={false}
            label={false}
            barInnerColor="#F7941D"
            thumbLeftColor="#F7941D"
            thumbRightColor="#F7941D"
            onInput={(e) => {
              handleInput(e);
            }}
          />
        </div>
        <div className="flex justify-between mt-6">
          <p>
            Prix: {minValue} FCFA — {maxValue} FCFA
          </p>
          <Link to="#" className="underline" onClick={handleChangeMinMax}>
            Filtrer
          </Link>
        </div>
      </div>
      <div className="mt-10">
        <h3
          className="text-lg text-primary font-bold pb-2 border-b
            border-dotted border-b-gray-200"
        >
          Étiquettes de produit
        </h3>
        <div className="mt-8 h-full">
          {tagIsLoading ? (
            <LoadingBox></LoadingBox>
          ) : tagError ? (
            <MessageBox variant="danger">{tagError}</MessageBox>
          ) : tags.length > 0 ? (
            tags.map((tag: any) =>
              tag.products.length ? (
                <button
                  key={tag._id}
                  onClick={() => {
                    if (tag.slug === tagParam) handleChangeTag("any");
                    else handleChangeTag(tag.slug);
                  }}
                  className={classNames(
                    tag.slug === stateTag || tag.slug === tagParam
                      ? "bg-secondary bg-secondary text-white"
                      : `bg-white border-gray-200 hover:bg-secondary
                            hover:border-secondary hover:text-white`,
                    `relative text-xs lowercase px-4 py-1 mb-3 border
                        rounded-full cursor-pointer mr-1`
                  )}
                >
                  {tag.name}
                </button>
              ) : null
            )
          ) : null}
        </div>
      </div>
    </>
  );
}

type ProductFilterProps = {
  onCategoryChange: any;
  onMinMaxChange: any;
  onTagChange: any;
};
