import React from "react";
import { Link } from "react-router-dom";

/**
 * Header logo component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function HeaderLogo() {
  return (
    <Link className="brand" to="/">
      <div className="w-32 flex-shrink-0">
        <img
          src="/assets/images/logo/logo.png"
          alt="kitecone"
          className="h-full w-full object-cover object-center"
        />
      </div>
    </Link>
  );
}
