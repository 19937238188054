import { Observable, defer, map } from "rxjs";
import { AxiosResponse } from "axios";
import axiosInstance from "../../../utils/http-client.util";

/**
 * Shipping price service
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-08-12
 *
 * class ShippingPriceService
 */
class ShippingPriceService {
  public lang = "en";

  /**
   * Get all shipping price
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-12
   *
   * @returns {Promise<void>} the eventual completion or failure
   */
  public getShippingPrices(): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/shippingPrices`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Get shipping price by id
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-12
   *
   * @param {any} shippingPriceId the shipping price's id
   * @returns {Observable<any>} the eventual completion or failure
   */
  public getShippingPriceById(shippingPriceId: any): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/shippingPrice/${shippingPriceId}`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Get shipping price by departure and arrival
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-22
   *
   * @param {string} departure the shipping price departure
   * @param {string} arrival the shipping price arrival
   * @returns {Observable<any>} the eventual completion or failure
   */
  public getShippingPriceByDepartureArrival(
    departure: string,
    arrival: string
  ): Observable<any> {
    return defer(() =>
      axiosInstance.get(
        `/v1/${this.lang}/shippingPrice/departure/${departure}/arrival/${arrival}`
      )
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }
}

const shippingPriceService = new ShippingPriceService();
export default shippingPriceService;
