import { Transition } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import PrincipalHeader from "./headerposition/principalheader/principal-header";
import StickyHeader from "./headerposition/stickyheader/sticky-header";

/**
 * Header component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-27
 *
 * @returns {JSX.Element} the javaScriptXML element
 */
export default function Header(): JSX.Element {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    /**
     * Header component
     *
     * @author Valentin magde <valentinmagde@gmail.com>
     * @since 2023-06-29
     *
     * @returns {void}
     */
    const listenToScroll = (): void => {
      if (window.pageYOffset >= 217) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [scrolled]);

  return (
    <>
      <Transition
        appear={true}
        show={true}
        enter="transition ease-out duration-1000 transform"
        enterFrom="-translate-y-full"
        enterTo="translate-y-0"
        leave="transition ease-in-out duration-1000 transform"
        leaveFrom="translate-y-0"
        leaveTo="-translate-y-full"
        className="relative z-20"
      >
        <PrincipalHeader />
      </Transition>

      <div className="header-sticky">
        <Transition
          appear={true}
          show={scrolled}
          enter="transition ease-out duration-1000 transform"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          leave="transition ease-in-out duration-1000 dilay-1000 transform"
          leaveFrom="translate-y-0"
          leaveTo="-translate-y-full"
        >
          <StickyHeader />
        </Transition>
      </div>
    </>
  );
}
