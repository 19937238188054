import React, { useEffect, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import LoadingBox from "../../others/loading-box";
import SearchList from "./search-list";
import productService from "../../../api/product-service/product/product.service";

/**
 * Search box component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
function SearchBox(): JSX.Element {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [isFiltering, setIsFiltering] = useState(false);
  const [close, setClose] = useState(false);
  const [displayResult, setDisplayResult] = useState(false);
  const [filterProducts, setFilterProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  /**
   * Submit handler
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} e the react mouse event
   * @returns {void}
   */
  const submitHandler = (e: any): void => {
    e.preventDefault();
    navigate(`/search/name/${name}`);
  };

  /**
   * Async search
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} value the filter criteria
   * @returns {Promise<unknown>} the eventual completion or failure
   */
  const asyncSearch = (value: any): Promise<unknown> => {
    if (value.length >= 3) {
      // Start loader
      setIsFiltering(true);

      return new Promise((resolve, reject) => {
        productService.showAll({ name: value }).subscribe({
          next: (data) => {
            // Stop loader
            setIsLoading(false);

            resolve(data.products);
          },
          error: () => {
            // Stop loader
            setIsLoading(false);

            reject(null);
          },
        });
      });
    } else {
      return Promise.reject(null);
    }
  };

  /**
   * Handle outside click
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {boolean} open this value can be true|false
   * @returns {void}
   */
  const handleClickOutside = (open: boolean): void => {
    setClose(open);
  };

  useEffect(() => {
    let i = 0,
      speed = 60,
      placeholder = "";
    const txt = "Rechercher un produit...";
    let j = txt.length;

    /**
     * Animate placeholder text
     *
     * @author Valentin magde <valentinmagde@gmail.com>
     * @since 2023-06-28
     *
     * @returns {void}
     */
    const typing = (): void => {
      if (i == txt.length) {
        speed = 20;
        placeholder = txt.substring(0, j);
        j--;
        i = j == 0 ? 0 : i;
        placeholder = j == 0 ? "" : placeholder;
      } else {
        speed = 60;
        placeholder += txt.charAt(i);
        i++;
        j = i == txt.length ? i : 0;
        speed = i == txt.length ? 2000 : speed;
      }

      document?.getElementById("q")?.setAttribute("placeholder", placeholder);
      setTimeout(typing, speed);
    };

    typing();
  }, []);

  return (
    <div className="grid grid-cols-4 gap-4 relative">
      <div className="col-span-4">
        <form className="search" onSubmit={submitHandler}>
          <div className="flex justify-start items-center">
            <div className="absolute z-10 pl-6">
              <span className="pointer-events-none flex items-center">
                {isFiltering ? (
                  <LoadingBox />
                ) : (
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-500"
                    aria-hidden="true"
                  />
                )}
              </span>
            </div>
            <div className="w-full flex justify-between">
              <input
                type="text"
                name="q"
                id="q"
                autoComplete="off"
                className="bg-gray-100 border-0 h-11 w-full rounded-full pl-12
                  pb-1 text-gray-900 placeholder-gray-400 focus:ring-secondary
                  focus:ring-1 mask-path"
                onFocus={() => {
                  setClose(false);
                }}
                onChange={(e) => {
                  setName(e.target.value);
                  asyncSearch(e.target.value).then(
                    (res) => {
                      setFilterProducts(res as any);
                      setIsFiltering(false);
                      setDisplayResult(true);
                    },
                    () => {
                      setFilterProducts([]);
                      setIsFiltering(false);
                      setDisplayResult(false);
                    }
                  );
                }}
              />
              <button
                className="absolute right-0 bg-gray-100 border border-secondary
                  border-0 hover:border-l-0 hover:text-secondary text-black
                  font-bold h-11 rounded-full rounded-l-none pt-1"
                type="submit"
              >
                <span className="flex items-center px-4">
                  <span className="flex items-center">Recherche</span>
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="col-span-4 w-full z-20 absolute mt-12">
        {displayResult && name.length >= 3 && !close ? (
          <SearchList
            products={filterProducts}
            onHandleClickOutside={handleClickOutside}
          />
        ) : null}
      </div>
    </div>
  );
}

export default SearchBox;
