import React, { useEffect, useState } from "react";
import LoadingBox from "./loading-box";
import MessageBox from "./message-box";
import { useValidation } from "react-simple-form-validator";
import {
  classNames,
  getFieldsCustomErrorMessages,
} from "../../utils/helper.util";
import { toast } from "react-toastify";
import { Link, useSearchParams } from "react-router-dom";
import userService from "../../api/user-service/user/user.service";

/**
 * Forgot password component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-22
 *
 * @param {ForfotPasswordProps} props the shared properties
 * @returns {JSX.Element} the javaScriptXML element
 */
export default function ForgotPassword(
  props: ForfotPasswordProps
): JSX.Element {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrorMessages, setFieldErrorMessages] = useState<Array<string>>(
    []
  );
  const [touchedFields, setTouchedFields] = useState({
    email: false,
  });

  const { isFieldInError, getErrorsInField, isFormValid } = useValidation({
    fieldsRules: {
      email: { required: true, email: true },
    },
    state: { email },
    locale: "en",
  });

  const [search] = useSearchParams();
  const redirect =
    search.get("redirect") && search.get("redirect") != "/"
      ? `${search.get("redirect")}`
      : "/";

  /**
   * Submit handler
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-29
   *
   * @param {any} e the react mouse event
   * @returns {void}
   */
  const submitHandler = (e: any): void => {
    e.preventDefault();

    if (isFormValid) {
      // Start loading
      setIsLoading(true);

      userService.sendResetPasswordLink(email).subscribe({
        next: () => {
          // Stop loading
          setIsLoading(false);

          toast.success("Lien envoyé avec succès", {
            theme: "colored",
          });
        },
        error: (e) => {
          // Stop loading
          setIsLoading(false);

          toast.error(e, {
            theme: "colored",
          });
        },
      });
    } else {
      setFieldErrorMessages(
        getFieldsCustomErrorMessages(
          touchedFields,
          isFieldInError,
          getErrorsInField,
          false
        )
      );

      setTouchedFields({
        email: true,
      });
    }
  };

  /**
   *  handleClose
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-29
   *
   * @returns {void}
   */
  const handleClose = (): void => {
    if (props.onClose) {
      props.onClose(false);
    }
  };

  /**
   * OnBlurHandler
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-15
   *
   * @param {React.FormEvent<HTMLElement>} event the react mouse event
   * @param {string} field the field on blur
   * @returns {void}
   */
  const onBlurHandler = (
    event: React.FormEvent<HTMLElement>,
    field: string
  ): void => {
    setTouchedFields((prevFields) => ({ ...prevFields, [field]: true }));
  };

  useEffect(() => {
    setFieldErrorMessages(
      getFieldsCustomErrorMessages(
        touchedFields,
        isFieldInError,
        getErrorsInField
      )
    );
  }, [touchedFields]);

  return (
    <div className="flex items-center justify-center">
      <div className="w-full">
        {fieldErrorMessages.length ? (
          <div className="mb-4">
            <MessageBox variant="danger">
              {fieldErrorMessages.map((s) => (
                <span key={s}>
                  {s}
                  <br />
                </span>
              ))}
            </MessageBox>
          </div>
        ) : null}
        <form onSubmit={submitHandler}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm mb-4">
            <div className="mb-4">
              <label htmlFor="email" className="text-gray-900">
                Email
                <span className="text-red-500">*</span>
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="off"
                required
                className={classNames(
                  touchedFields.email && isFieldInError("email")
                    ? `border-red-500 focus:border-red-500 focus:outline-none
                    focus:ring-0 focus:ring-secondary`
                    : `border-gray-300 focus:border-secondary
                      focus:outline-none focus:ring-0 focus:ring-secondary`,
                  `relative block w-full appearance-none rounded-md border
                    px-3 py-2 text-gray-900 placeholder-gray-500`
                )}
                placeholder="Adresse email"
                value={email}
                onBlur={(e) => onBlurHandler(e, "email")}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="mb-4">
            <button
              type="submit"
              className={classNames(
                isLoading
                  ? "bg-gray-400 text-gray-400 hover:bg-gray-400"
                  : `bg-secondary hover:bg-ternary focus:outline-none
                  focus:ring-primary text-white`,
                `w-full group flex justify-center rounded border
                border-transparent py-2 px-4 font-medium focus:ring-0 relative
                focus:ring-offset-2`
              )}
              disabled={isLoading}
            >
              {isLoading && (
                <div className="absolute text-black">
                  <LoadingBox className="text-white" />
                </div>
              )}
              Envoyer le lien
            </button>
          </div>
          <div>
            <p className="text-center text-gray-900">
              Nouveau client ?{" "}
              <Link
                to={`/register?redirect=${redirect}`}
                className="font-medium text-primary hover:text-secondry"
                onClick={handleClose}
              >
                Créer un compte
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

type ForfotPasswordProps = {
  onClose?: any;
};
