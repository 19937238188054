import React from "react";
import { Route, Routes } from "react-router-dom";
import BlogPage from "../pages/blog.page";
import Post from "../components/post.component";
import PostDetails from "../components/post-details";

/**
 * Blog route
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-06
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function BlogRoute(): JSX.Element {
  return (
    <Routes>
      <Route path="/blog" element={<BlogPage />}>
        <Route index element={<Post />} />
        {[
          "post",
          "post/page/:page",
          "post/category/:category",
          "post/category/:category/page/:page",
          "post/tag/:tag",
          "post/tag/:tag/page/:page",
        ].map((path) => (
          <Route path={path} key={path} element={<Post />} />
        ))}
        <Route path="post/:id" element={<PostDetails />} />
      </Route>
    </Routes>
  );
}
