import React, { useState, useRef, useEffect } from "react";
import style from "./add-payment-method.module.scss";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import useOutsideClick from "../../../hooks/use-outside-click";
import { classNames } from "../../../utils/helper.util";
import { toast } from "react-toastify";

/**
 * Checkout form
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-27
 *
 * @param {AddPayMethodProps} props the shared properties
 * @returns {JSX.Element} the javaScriptXML element
 */
const AddPayMethod = React.forwardRef((props: AddPayMethodProps, ref: any) => {
  const { triggerFormValidation, checkoutFormData } = props;
  const [numberCompleted, setNumberCompleted] = useState("") as any;
  const [expiryCompleted, setExpiryCompleted] = useState("") as any;
  const [cvcCompleted, setCvcCompleted] = useState("") as any;
  const [numberOnFocus, setNumberOnFocus] = useState("") as any;
  const [expiryOnFocus, setExpiryOnFocus] = useState("") as any;
  const [cvcOnFocus, setCvcOnFocus] = useState("") as any;
  const stripe = useStripe();

  const elements = useElements();
  const wrapperRef = useRef() as any;

  const options = {
    // placeholder: "Numéro de carte",
    showIcon: true,
  };

  // Create a copy of the props
  const tempProps = JSON.parse(JSON.stringify(props));

  /**
   * Handle outside click
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @returns {void}
   */
  const handleClickOutside = (): void => {
    setCvcOnFocus(false);
    setExpiryOnFocus(false);
    setNumberOnFocus(false);
  };

  tempProps.onHandleClickOutside = handleClickOutside;
  useOutsideClick(wrapperRef, tempProps);

  useEffect(() => {
    if (
      triggerFormValidation &&
      checkoutFormData &&
      elements?.getElement(CardNumberElement)
    ) {
      numberCompleted === "" ? setNumberCompleted(false) : null;
      expiryCompleted === "" ? setExpiryCompleted(false) : null;
      cvcCompleted === "" ? setCvcCompleted(false) : null;

      if (numberCompleted && expiryCompleted && cvcCompleted) {
        try {
          props.paymentMethodIsLoading(true);

          stripe
            ?.createPaymentMethod({
              type: "card",
              billing_details: checkoutFormData.billingDetails,
              card: elements.getElement(CardNumberElement),
            } as any)
            .then((resp: any) => {
              console.log(resp.paymentMethod);
              props.onChangeStripeCardInfo(resp.paymentMethod);
            });
        } catch (err: any) {
          /* Handle Error*/
          toast.error(err?.message || err, {
            theme: "colored",
          });

          props.paymentMethodIsLoading(false);
          props.onChangeStripeCardInfo(null);
        }
      } else {
        props.invalidPaymentForm(true);
      }
    } else {
      props.onChangeStripeCardInfo(null);
      props.invalidPaymentForm(false);
    }
  }, [triggerFormValidation, checkoutFormData]);

  return (
    <div className={classNames(style.wrapper, "p-2")}>
      <div className={style.innerWrapper}>
        <label>
          Numéro de carte
          <span className="text-red-500">*</span>
        </label>
        <div
          className={classNames(
            numberCompleted === false
              ? "border border-red-500"
              : numberOnFocus === true
              ? "border border-secondary outline-none ring-0 ring-secondary"
              : "border border-gray-300",
            style.rowPaymentInput,
            "mb-4"
          )}
          ref={wrapperRef}
        >
          <CardNumberElement
            onChange={(e) => {
              setNumberCompleted(e.complete);
            }}
            options={options}
            onFocus={() => {
              setNumberOnFocus(true);
              setCvcOnFocus(false);
              setExpiryOnFocus(false);
            }}
          />
        </div>
        <div className="flex items-center space-x-2">
          <div className="w-full">
            <label>
              Date d&apos;expiration
              <span className="text-red-500">*</span>
            </label>
            <div
              className={classNames(
                expiryCompleted === false
                  ? "border border-red-500"
                  : expiryOnFocus === true
                  ? "border border-secondary outline-none ring-0 ring-secondary"
                  : "border border-gray-300",
                style.rowPaymentInput,
                "w-full"
              )}
              ref={wrapperRef}
            >
              <CardExpiryElement
                onChange={(e) => setExpiryCompleted(e.complete)}
                onFocus={() => {
                  setExpiryOnFocus(true);
                  setCvcOnFocus(false);
                  setNumberOnFocus(false);
                }}
              />
            </div>
          </div>
          <div className="w-full">
            <label>
              Cryptogramme visuel
              <span className="text-red-500">*</span>
            </label>
            <div
              className={classNames(
                cvcCompleted === false
                  ? "border border-red-500"
                  : cvcOnFocus === true
                  ? "border border-secondary outline-none ring-0 ring-secondary"
                  : "border border-gray-300",
                style.rowPaymentInput,
                "w-full"
              )}
              ref={wrapperRef}
            >
              <CardCvcElement
                onChange={(e) => setCvcCompleted(e.complete)}
                onFocus={() => {
                  setCvcOnFocus(true);
                  setExpiryOnFocus(false);
                  setNumberOnFocus(false);
                }}
              />
            </div>
          </div>
        </div>
        {/* <div className={style.addressWrapper}>
          <div className={style.btnContainer}>
            <button className="hidden" onClick={handleSubmit} ref={ref}>
              Soumettre
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
});

// 👇️ set display name
AddPayMethod.displayName = "AddPayMethod";

type AddPayMethodProps = {
  onChangeStripeCardInfo: any;
  triggerFormValidation: any;
  invalidPaymentForm: any;
  paymentMethodIsLoading: any;
  checkoutFormData: any;
};

export default AddPayMethod;
