import axios from "axios";
import authService from "../api/auth-service/auth/auth.service";

// Create axios intance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

let retry = false;

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Modify the request config here (e.g., add headers, authentication tokens)
    const accessToken = authService.getLocalAccessToken();

    // ** If token is present add it to request's Authorization Header
    if (accessToken) {
      if (config.headers)
        config.headers.Authorization = `Bearer ${accessToken}` as any;
    }

    return config;
  },
  (error) => {
    console.log(error);
    // Handle request errors here

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      let err = error.response.data.data.errMsg;

      if (!err) err = JSON.stringify(error.response);
      else if (typeof err == "object")
        err = Object.values(err)
          .map((val) => val)
          .toString();

      return Promise.reject(err);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an
      // instance of
      // http.ClientRequest in node.js
      if (error.code === "ERR_NETWORK") {
        // Handle network error
        return Promise.reject(`
          ${error.message},
          ${error.config.baseURL} server is down
        `);
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      return Promise.reject(error?.message || JSON.stringify(error));
    }
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // console.log(error);
    // Handle response errors here
    // Catch "401 Unauthorized" responses
    console.log(error);
    if (error.response && error.response.status === 401 && !retry) {
      const originalRequest = error.config;
      const refreshToken = authService.getLocalRefreshToken();

      retry = true;

      return new Promise((resolve, reject) => {
        authService.refreshToken(refreshToken).subscribe({
          next: (data) => {
            const { accessToken } = data;

            axiosInstance.defaults.headers.common["Authorization"] =
              "Bearer " + accessToken;

            resolve(axiosInstance(originalRequest));
          },
          error: (e) => {
            authService.clearLocalAuthData();
            reject(e);
          },
        });
      });
    } else if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      let err = error.response.data.data.errMsg;

      if (!err) err = JSON.stringify(error.response);
      else if (typeof err == "object")
        err = Object.values(err)
          .map((val) => val)
          .toString();

      return Promise.reject(err);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an
      // instance of
      // http.ClientRequest in node.js
      if (error.code === "ERR_NETWORK") {
        // Handle network error
        return Promise.reject(`
          ${error.message},
          ${error.config.baseURL} server is down
        `);
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      // console.log('Error', error.message);
      return Promise.reject(error?.message || JSON.stringify(error));
    }
  }
);

// 👇️ export axios instance
export default axiosInstance;
