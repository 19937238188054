import React, { Fragment, useEffect, useState } from "react";
import LoadingBox from "../../../shared/components/others/loading-box";
import MessageBox from "../../../shared/components/others/message-box";
import {
  classNames,
  getFieldsCustomErrorMessages,
} from "../../../shared/utils/helper.util";
import { useValidation } from "react-simple-form-validator";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import userService from "../../api/user-service/user/user.service";

/**
 * Reset password component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-07
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function ResetPassword(): JSX.Element {
  const { id } = useParams();
  const [loadingReset, setLoadingReset] = useState(false);
  const [fieldErrorMessages, setFieldErrorMessages] = useState<Array<string>>(
    []
  );

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [touchedFields, setTouchedFields] = useState({
    newPassword: false,
    confirmNewPassword: false,
  });

  const { isFieldInError, getErrorsInField, isFormValid } = useValidation({
    fieldsRules: {
      newPassword: {
        required: true,
        minlength: 6,
      },
      confirmNewPassword: {
        required: true,
        equalPassword: newPassword,
        minlength: 6,
      },
    },
    state: {
      newPassword,
      confirmNewPassword,
    },
    locale: "en",
  });

  /**
   * Submit update form
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-27
   *
   * @param {any} e the react mouse event
   * @returns {void}
   */
  const submitHandler = (e: any): void => {
    e.preventDefault();

    if (isFormValid) {
      // Start loader
      setLoadingReset(true);

      userService
        .resetPassword({
          _id: id,
          new_password: newPassword,
          confirm_password: confirmNewPassword,
        })
        .subscribe({
          next: () => {
            // Stop loader
            setLoadingReset(false);

            toast.success("Password reset Successfully", {
              theme: "colored",
            });

            // Reset form
            resetForm();
          },
          error: (e) => {
            // Stop loader
            setLoadingReset(false);

            toast.error(e, {
              theme: "colored",
            });
          },
        });
    }
  };

  /**
   * OnBlurHandler
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-27
   *
   * @param {React.FormEvent<HTMLElement>} event the react mouse event
   * @param {string} field the field on blur
   * @returns {void}
   */
  const onBlurHandler = (
    event: React.FormEvent<HTMLElement>,
    field: string
  ): void => {
    setTouchedFields((prevFields) => ({ ...prevFields, [field]: true }));
  };

  useEffect(() => {
    setFieldErrorMessages(
      getFieldsCustomErrorMessages(
        touchedFields,
        isFieldInError,
        getErrorsInField
      )
    );
  }, [touchedFields]);

  /**
   * Reset form
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-27
   *
   * @returns {void}
   */
  const resetForm = (): void => {
    setNewPassword("");
    setConfirmNewPassword("");
    setTouchedFields({
      newPassword: false,
      confirmNewPassword: false,
    });
  };

  return (
    <div>
      <form onSubmit={submitHandler}>
        {fieldErrorMessages.length ? (
          <div className="mb-4">
            <MessageBox variant="danger">
              {fieldErrorMessages.map((s) => (
                <span key={s}>
                  {s}
                  <br />
                </span>
              ))}
            </MessageBox>
          </div>
        ) : null}
        <>
          <div className="rounded-md shadow-sm mb-4">
            <div className="mb-4">
              <label htmlFor="newPassword" className="text-gray-900">
                New password
              </label>
              <input
                id="newPassword"
                type="password"
                className={classNames(
                  touchedFields.newPassword && isFieldInError("newPassword")
                    ? `border-red-500 focus:border-red-500 focus:ring-0
                      focus:outline-none focus:ring-secondary`
                    : `border-gray-300 focus:border-secondary
                    focus:outline-none focus:ring-0 focus:ring-secondary`,
                  `relative block w-full appearance-none rounded-md border
                  px-3 py-2 text-gray-900 placeholder-gray-500`
                )}
                placeholder="Enter new password"
                onBlur={(e) => onBlurHandler(e, "newPassword")}
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
              ></input>
            </div>
            <div className="mb-4">
              <label htmlFor="confirmNewPassword" className="text-gray-900">
                Confirm new password
              </label>
              <input
                id="confirmNewPassword"
                type="password"
                className={classNames(
                  touchedFields.confirmNewPassword &&
                    isFieldInError("confirmNewPassword")
                    ? `border-red-500 focus:border-red-500 focus:ring-0
                      focus:outline-none focus:ring-secondary`
                    : `border-gray-300 focus:border-secondary
                      focus:outline-none focus:ring-0 focus:ring-secondary`,
                  `relative block w-full appearance-none rounded-md border
                    px-3 py-2 text-gray-900 placeholder-gray-500`
                )}
                placeholder="Enter confirm password"
                onBlur={(e) => onBlurHandler(e, "confirmNewPassword")}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                value={confirmNewPassword}
              ></input>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className={classNames(
                loadingReset
                  ? "bg-gray-400 text-gray-400 hover:bg-gray-400"
                  : `bg-secondary hover:bg-ternary focus:outline-none
                  focus:ring-primary text-white`,
                `group flex justify-center rounded border
                border-transparent py-2 px-4 font-medium focus:ring-0 relative
                focus:ring-offset-2 w-full`
              )}
              disabled={loadingReset}
            >
              {loadingReset ? (
                <div className="absolute text-black">
                  <LoadingBox />
                </div>
              ) : null}
              Réinitialiser
            </button>
          </div>
        </>
      </form>
    </div>
  );
}
