import React from "react";
import { Link } from "react-router-dom";
import PostType from "../../../shared/api/blog-service/post/post.type";
import {
  CalendarIcon,
  ChatBubbleLeftRightIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { _date } from "../../../shared/utils/helper.util";

/**
 * Post items component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-08
 *
 * @param {PostItemsProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function PostItems(props: PostItemsProps): JSX.Element {
  const { posts } = props;

  return (
    <>
      {posts.map((post: PostType) => (
        <div className="mb-12" key={post._id}>
          <Link to={`/blog/post/${post._id}`} className="">
            <img src={post.image} alt={post.name} className="w-full" />
          </Link>
          <div className="mt-6">
            {post.categories.map((category: any) => (
              <Link
                to={`/blog/post/category/${category.slug}`}
                key={category._id}
              >
                <span
                  className="inline-flex items-center rounded-sm mr-2
                      bg-secondary px-2 py-1 text-xs font-medium text-white
                      ring-1 ring-inset ring-gray-500/10 hover:bg-ternary"
                >
                  {category.name}
                </span>
              </Link>
            ))}
            <div className="flex text-gray-500 text-xs mt-6">
              <CalendarIcon className="w-4" />
              <span className="ml-1 pt-1 underline">
                {_date(post.created_at)}
              </span>
              &nbsp;<span className="pt-1"> /</span>&nbsp;
              <UserIcon className="h-4 w-4" />
              <span className="ml-1 pt-1 underline">Posté par {post.user}</span>
              &nbsp; <span className="pt-1"> /</span> &nbsp;
              <ChatBubbleLeftRightIcon className="w-4" />
              <span className="ml-1 pt-1">0</span>
            </div>
            <Link to={`/blog/post/${post._id}`}>
              <p className="mt-6 text-primary text-3xl font-bold">
                {post.name}
              </p>
            </Link>
            <p className="mt-6 text-justify leading-8 line-clamp-3">
              {post.description}
            </p>
            <Link to={`/blog/post/${post._id}`}>
              <p
                className="mt-6 text-secondary font-bold
                    underline hover:text-ternary"
              >
                Voir plus
              </p>
            </Link>
          </div>
        </div>
      ))}
    </>
  );
}

type PostItemsProps = {
  posts: Array<PostType>;
};
