import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import infoMenuItems from "../../../resources/navigations/footer/info-menu-items.json";
import myAccountMenuItems from "../../../resources/navigations/footer/my-account-menu-items.json";
import categoryMenuItems from "../../../resources/navigations/footer/category-menu-items.json";
import SubscribeForm from "../others/subscribe-form";
/**
 * Footer menu component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function FooterMenu(): JSX.Element {
  const [email, setEmail] = useState("");

  /**
   * Submit form
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} e the react event
   * @returns {void}
   */
  const submitHandler = async (e: any) => {
    e.preventDefault();
    console.log(email);
  };

  return (
    <div
      className="mx-auto grid gap-2 grid-cols-1 xl:grid-cols-5 lg:grid-cols-5
        md:grid-cols-5 sm:grid-cols-2"
    >
      <div
        className="col-span-1 text-center xl:text-left lg:text-left
        md:text-left"
      >
        <p className="font-bold">EMPLACEMENT DU MAGASIN</p>
        <p className="mt-6 text-gray-300">
          9066 Green Lake Drive Chevy Chase, MD 20815
        </p>
        <p className="mt-6 underline">contact@kitecole.net</p>
      </div>
      <div
        className="col-span-1 text-center xl:text-left lg:text-left
        md:text-left"
      >
        <p className="font-bold">INFORMATION</p>
        <div className="flex flex-col mt-6 text-gray-300">
          {infoMenuItems.map((menu) => (
            <NavLink
              to={menu.url}
              key={menu.slug}
              className={({ isActive }) =>
                isActive ? `text-secondary leading-8` : `text-white leading-8`
              }
            >
              {menu.name}
            </NavLink>
          ))}
        </div>
      </div>
      <div
        className="col-span-1 text-center xl:text-left lg:text-left
        md:text-left"
      >
        <p className="font-bold">MON COMPTE</p>
        <div className="flex flex-col mt-6 text-gray-300">
          {myAccountMenuItems.map((menu) => (
            <NavLink
              to={menu.url}
              key={menu.slug}
              className={({ isActive }) =>
                isActive ? `text-secondary leading-8` : `text-white leading-8`
              }
            >
              {menu.name}
            </NavLink>
          ))}
        </div>
      </div>
      <div
        className="col-span-1 text-center xl:text-left lg:text-left
        md:text-left"
      >
        <p className="font-bold">CATEGORIES</p>
        <div className="flex flex-col mt-6 text-gray-300">
          {categoryMenuItems.map((menu) => (
            <NavLink
              to={menu.url}
              key={menu.slug}
              className={({ isActive }) =>
                isActive
                  ? `text-secondary leading-8`
                  : `text-gray-300 leading-8`
              }
            >
              {menu.name}
            </NavLink>
          ))}
        </div>
      </div>
      <div
        className="col-span-1 text-center xl:text-left lg:text-left
        md:text-left"
      >
        <p className="font-bold">ABONNEZ-VOUS</p>
        <p className="mt-6 text-gray-300">
          Inscrivez-vous et recevez les dernières mises à jour de notre blog
        </p>
        <div className="mt-4">
          <SubscribeForm />
        </div>
      </div>
    </div>
  );
}
