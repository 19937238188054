import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import DynamicHeroIcon from "../../others/dynamic-heroIcon";
import { classNames } from "../../../utils/helper.util";
import categoryMenuItems from "../../../../resources/navigations/category-menu-items.json";

/**
 * Header Mega menu component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @param {HeaderMegamenuProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function HeaderMegamenu(props: HeaderMegamenuProps) {
  const itemRef = useRef(null) as any;
  const [width, setWidth] = useState(0);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    /**
     * Handle window resize
     *
     * @author Valentin magde <valentinmagde@gmail.com>
     * @since 2023-06-28
     *
     * @returns {void}
     */
    const handleWindowResize = (): void => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useLayoutEffect(() => {
    setWidth(itemRef.current.offsetWidth as any);
  }, []);

  return categoryMenuItems.map((category: any, index: number) => (
    <Popover key={category.name} className="flex">
      {({ open }) => (
        <>
          <div
            className={classNames(
              index != categoryMenuItems.length - 1
                ? "border-b border-gray-100"
                : "",
              `w-56 relative flex items-center px-2 py-3
                    text-gray-500 hover:text-secondary hover:bg-gray-100`
            )}
            ref={itemRef}
          >
            <Popover.Button
              className={classNames(
                open
                  ? "border-secondary text-secondary"
                  : `border-transparent hover:text-secondary`,
                `relative w-full z-10 -mb-px flex items-center
                    transition-colors duration-200 ease-out`
              )}
            >
              <span className="w-full h-5 flex justify-start items-center">
                <span className="flex justify-start items-center pr-2">
                  <DynamicHeroIcon icon={category.icon} />
                </span>
                <span>{category.name}</span>
                {category.featured.length || category.sections.length ? (
                  <span
                    className="float-right flex-1 justify-end items-center
                    right-0"
                  >
                    <ChevronRightIcon
                      className="h-3 w-3 float-right"
                      aria-hidden="true"
                    />
                  </span>
                ) : null}
              </span>
            </Popover.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Panel
              className="w-full absolute inset-x-0 text-gray-500 border-t
                border-gray-200"
              style={{
                marginLeft: `${width}px`,
                top: 0,
                width: `${windowSize[0] - width - 61}px`,
              }}
            >
              {({ close }) => (
                <>
                  <div
                    className="absolute inset-0 top-1/2 bg-white shadow"
                    aria-hidden="true"
                  />

                  <div className="relative bg-white">
                    <div className="mx-auto max-w-7xl px-8">
                      <div className="grid grid-cols-2 gap-y-10 gap-x-8 py-4">
                        <div className="col-start-2 grid grid-cols-2 gap-x-8">
                          {category.featured.map((item: any) => (
                            <div key={item.name} className="group relative">
                              <div
                                className="aspect-w-1 aspect-h-1 rounded-lg
                                  overflow-hidden group-hover:opacity-75
                                  bg-gray-100"
                              >
                                <img
                                  src={item.imageSrc}
                                  alt={item.imageAlt}
                                  className="object-cover object-center"
                                />
                              </div>
                              <Link
                                to={item.href}
                                onClick={() => {
                                  close();
                                  props.onClose();
                                }}
                                className="mt-6 block font-medium
                                    text-gray-900"
                              >
                                <span
                                  className="absolute inset-0 z-10"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </Link>
                              <p aria-hidden="true" className="mt-1">
                                Shop now
                              </p>
                            </div>
                          ))}
                        </div>
                        <div
                          className="row-start-1 grid grid-cols-3 gap-y-10
                              gap-x-8"
                        >
                          {category.sections.map((section: any) => (
                            <div key={section.name}>
                              <p
                                id={`${section.name}-heading`}
                                className="font-medium text-primary
                                    font-bold"
                              >
                                {section.name}
                              </p>
                              <ul
                                role="list"
                                aria-labelledby={`${section.name}-heading`}
                                className="mt-6 space-y-6 sm:mt-4
                                    sm:space-y-4"
                              >
                                {section.items.map((item: any) => (
                                  <Link
                                    to={item.href}
                                    onClick={() => {
                                      close();
                                      props.onClose();
                                    }}
                                    key={item.name}
                                    className="flex"
                                  >
                                    {item.name}
                                  </Link>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  ));
}

type HeaderMegamenuProps = {
  onClose: any;
};
