import React from "react";
import * as HIcons from "@heroicons/react/24/outline";

/**
 * Dynamic hero icon component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-29
 *
 * @param {DynamicHeroIconProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function DynamicHeroIcon(
  props: DynamicHeroIconProps
): JSX.Element {
  const icon = props.icon;
  const { ...icons } = HIcons as any;
  const TheIcon = icon.length ? icons[icon] : null;

  return (
    TheIcon && (
      <TheIcon className={props.className || "h-6 w-6"} aria-hidden="true" />
    )
  );
}

type DynamicHeroIconProps = {
  icon: string;
  className?: string;
};
