import React, { Fragment, useState } from "react";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { NavLink, Link } from "react-router-dom";
import {
  Bars3Icon,
  ChevronUpIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import verticalMenuItems from "../../../../resources/navigations/vertical-menu-items.json";

/**
 * Header vertical menu component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-31
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function HeaderVerticalMenu(): JSX.Element {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        className="rounded-md bg-white p-2 text-gray-400"
        onClick={() => setOpen(true)}
      >
        <span className="sr-only">Open menu</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel
                className="relative flex w-full max-w-xs flex-col
                    overflow-y-auto bg-white pb-12 shadow-xl"
              >
                <div className="flex px-4 pt-5 pb-2">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center
                        rounded-md p-2 text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div
                  className="space-y-6 border-t border-gray-200 py-6
                        px-4"
                >
                  {verticalMenuItems.map((menu) => (
                    <div key={menu.slug} className="flow-root">
                      <NavLink
                        to={menu.url}
                        onClick={() => setOpen(false)}
                        className={({ isActive }) =>
                          isActive
                            ? `text-secondary -m-2 block p-2 font-medium`
                            : `text-gray-900 -m-2 block p-2 font-medium`
                        }
                      >
                        {menu.name}
                      </NavLink>
                    </div>
                  ))}
                </div>

                {/* <div className="w-full px-4">
                    <Disclosure>
                        {({ open }) => (
                        <>
                            <Disclosure.Button
                            className="flex w-full justify-between text-left
                                hover:text-secondary">
                            <span>Kits scolaire</span>
                            <ChevronUpIcon
                                className={`${
                                open ? "rotate-180 transform" : ""
                                } h-3 w-3`}
                            />
                            </Disclosure.Button>
                            <Disclosure.Panel
                            className="px-4 pt-4 text-sm flex flex-col">
                            <Link to="#" onClick={() => setOpen(false)}>
                                Maternelle
                            </Link>
                            <Link
                                to="#"
                                className="pt-4"
                                onClick={() => setOpen(false)}>
                                Primaire
                            </Link>
                            <Link
                                to="#"
                                className="pt-4"
                                onClick={() => setOpen(false)}>
                                Secondaire
                            </Link>
                            </Disclosure.Panel>
                        </>
                        )}
                    </Disclosure>
                    <Disclosure as="div" className="my-6">
                        {({ open }) => (
                        <>
                            <Disclosure.Button
                            className="flex w-full justify-between text-left
                                text-sm hover:text-secondary">
                            <span>Programmes scolaire</span>
                            <ChevronUpIcon
                                className={`${
                                open ? "rotate-180 transform" : ""
                                } h-3 w-3`}
                            />
                            </Disclosure.Button>
                            <Disclosure.Panel
                            className="px-4 pt-4 text-sm flex flex-col">
                            <Link to="#" onClick={() => setOpen(false)}>
                                Section francophone
                            </Link>
                            <Link
                                to="#"
                                className="pt-4"
                                onClick={() => setOpen(false)}>
                                Section anglophone
                            </Link>
                            </Disclosure.Panel>
                        </>
                        )}
                    </Disclosure>
                    </div> */}

                <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                  <div className="flow-root">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full justify-between text-left hover:text-secondary">
                            <span>Langues</span>
                            <ChevronUpIcon className={`${open ? "rotate-180 transform" : ""} h-3 w-3`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4 pt-4 text-sm flex flex-col">
                            <Link to="#" onClick={() => setOpen(false)}>
                              Français
                            </Link>
                            <Link
                              to="#"
                              className="pt-4"
                              onClick={() => setOpen(false)}
                            >
                              Anglais
                            </Link>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
