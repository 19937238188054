import { Observable, defer, map } from "rxjs";
import { AxiosResponse } from "axios";
import axiosInstance from "../../../utils/http-client.util";

/**
 * Order service
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-08-25
 *
 * class OrderService
 */
class OrderService {
  public lang = "en";

  /**
   * Create an order
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-25
   *
   * @param {any} data the order details
   * @returns {Promise<void>} the eventual completion or failure
   */
  public createOrder(data: any): Observable<any> {
    return defer(() =>
      axiosInstance.post(`/v1/${this.lang}/orders`, data)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Get order by id
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-25
   *
   * @param {string} orderId the order id
   * @returns {Observable<any>} the eventual completion or failure
   */
  public getOrderById(orderId: string): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/order/${orderId}`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Get orders by user
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-10-04
   *
   * @param {string} userId the user id
   * @returns {Observable<any>} the eventual completion or failure
   */
  public getOrdersByUser(userId: string): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/orders/user/${userId}`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }
}

const orderService = new OrderService();
export default orderService;
