import { Observable, defer, map } from "rxjs";
import { AxiosResponse } from "axios";
import axiosInstance from "../../../utils/http-client.util";

/**
 * Shipping method service
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-08-19
 *
 * class ShippingMethodService
 */
class ShippingMethodService {
  public lang = "en";

  /**
   * Get shipping methods by country
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-19
   *
   * @param {string} countryId the country id
   * @returns {Promise<void>} the eventual completion or failure
   */
  public getShippingMethods(countryId: string): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/shippingMethods/${countryId}`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Get shipping method by id
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-12
   *
   * @param {any} shippingMethodId the shipping method's id
   * @returns {Observable<any>} the eventual completion or failure
   */
  public getShippingPriceById(shippingMethodId: any): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/shippingMethod/${shippingMethodId}`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }
}

const shippingMethodService = new ShippingMethodService();
export default shippingMethodService;
