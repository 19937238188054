import React, { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import LoadingBox from "../../../shared/components/others/loading-box";
import MessageBox from "../../../shared/components/others/message-box";
import { classNames } from "../../../shared/utils/helper.util";
import PostType from "../../../shared/api/blog-service/post/post.type";
import postService from "../../../shared/api/blog-service/post/post.service";
import PostItems from "../components/post-items.component";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/outline";

/**
 * Post component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-08
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function Post(): JSX.Element {
  const [posts, setPosts] = useState<Array<PostType>>([]);
  const [postsError, setPostsError] = useState("");
  const [postsIsLoading, setPostsIsLoading] = useState(true);
  const [pages, sePages] = useState(0);
  const [previousPage, setPreviousPage] = useState(null);
  const [perPage, setPerPager] = useState(0);
  const [allPosts, setAllPosts] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [nextPage, setNextPage] = useState();

  const navigate = useNavigate();
  const { category, tag, page } = useParams();
  const [search] = useSearchParams();
  const name = search.get("name") || "";
  const rating = search.get("rating") as any;
  const order = search.get("order") || "";

  /**
   * Get filter url
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} filter the filter parameters
   * @returns {string} the shop page url
   */
  const getFilterUrl = (filter: any): string => {
    const filterName = filter.name || name;
    const filterRating = filter.rating || rating;
    const sortOrder = filter.order || order;

    const filterPage =
      filter.category || filter.tag ? undefined : filter.page || page;

    const filterCategory = filter.tag ? "" : filter.category || category;

    const filterTag = filter.category ? "" : filter.tag || tag;

    let url = "/blog/post";
    let params = "";

    params +=
      (filterName &&
        (params ? `&name=${filterName}` : `?name=${filterName}`)) ||
      "";

    params +=
      (sortOrder && (params ? `&order=${sortOrder}` : `?order=${sortOrder}`)) ||
      "";

    params +=
      (filterRating &&
        (params ? `&rating=${filterRating}` : `?rating=${filterRating}`)) ||
      "";

    url += (filterCategory && `/category/${filterCategory}`) || "";
    url += (filterTag && `/tag/${filterTag}`) || "";
    url += (filterPage && `/page/${filterPage}`) || "";

    url += params;

    return url;
  };

  // Get posts
  useEffect(() => {
    postService
      .showAll({
        perPage: 3,
        page,
        name,
        category,
        tag,
        rating,
        order,
      })
      .subscribe({
        next: (data) => {
          // Stop loading
          setPostsIsLoading(false);

          setPosts(data.posts);
          sePages(data.pages);
          setPreviousPage(data.previousPage);
          setPerPager(data.perPage);
          setAllPosts(data.allPosts);
          setCurrentPage(data.currentPage);
          setNextPage(data.nextPage);
        },
        error: (e) => {
          // Stop loading
          setPostsIsLoading(false);

          setPostsError(e);
        },
      });
  }, [perPage, page, name, category, tag, rating, order]);

  return (
    <div>
      {postsIsLoading ? (
        <LoadingBox></LoadingBox>
      ) : postsError ? (
        <MessageBox variant="danger">{postsError}</MessageBox>
      ) : posts.length ? (
        <>
          <PostItems posts={posts} />
          <div className="flex justify-center mt-8">
            {previousPage ? (
              <button
                onClick={() => {
                  navigate(getFilterUrl({ page: previousPage }));
                }}
                className={classNames(
                  postsIsLoading ? "text-gray-400" : "hover:text-secondary",
                  `flex justify-center items-center bg-transparent
                        font-bold mr-4`
                )}
                disabled={postsIsLoading ? true : false}
              >
                <ChevronDoubleLeftIcon
                  className="h-3 w-3 chevron -mt-px pb-px
                        duration-500"
                />
                Précedent
              </button>
            ) : null}

            {[...Array(pages).keys()].map((x) => (
              <Link
                className={classNames(
                  x + 1 === currentPage
                    ? "bg-secondary text-white border-secondary"
                    : "bg-white border-gray-200 hover:bg-secondary",
                  `flex justify-center items-center rounded-full
                        border hover:text-white h-8 w-8 text-center mr-2
                        pt-1`
                )}
                key={x + 1}
                to={getFilterUrl({ page: x + 1 })}
              >
                {x + 1}
              </Link>
            ))}

            {nextPage ? (
              <button
                onClick={() => {
                  navigate(getFilterUrl({ page: nextPage }));
                }}
                className={classNames(
                  postsIsLoading ? "text-gray-400" : "hover:text-secondary",
                  `flex justify-center items-center bg-transparent
                    font-bold ml-2`
                )}
                disabled={postsIsLoading ? true : false}
              >
                Suivant
                <ChevronDoubleRightIcon
                  className="h-3 w-3 chevron -mt-px
                        pb-px duration-500"
                />
              </button>
            ) : null}
          </div>
        </>
      ) : (
        <MessageBox variant="info">
          <p>Aucun article trouvé!</p>
        </MessageBox>
      )}
    </div>
  );
}
