import * as React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

/**
 * Simple react PDF Viewer component with controls like
 *
 * @returns {JSX.Element} The react element.
 */
export default function PdfViewer(): JSX.Element {
    // Create new plugin instance
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        // <Viewer
        //     fileUrl='/assets/files/pdf/manuels-scolaires.pdf'
        //     plugins={[
        //         // Register plugins
        //         defaultLayoutPluginInstance
        //     ]}
        // />
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.js">
        <div style={{ height: '750px' }}>
            <Viewer
                fileUrl='/assets/files/pdf/manuels-scolaires.pdf'
                plugins={[
                    defaultLayoutPluginInstance,
                ]}
            />
        </div>
    </Worker>
    )
}
