import React from "react";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  FaceFrownIcon,
  FaceSmileIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import LoadingBox from "../others/loading-box";
import Rating from "../others/rating";
import AddToCartButton from "./add-to-cart-button";
import ProductType from "../../api/product-service/product/product.type";

/**
 * Product quick views component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @param {ProductQuickviewsProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function ProductQuickviews(
  props: ProductQuickviewsProps
): JSX.Element {
  const product = props.product;
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [qty, setQty] = useState(1);

  /**
   * Quick views
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @returns {void}
   */
  const quickViewsHandler = (): void => {
    // Start loader
    setIsLoading(true);

    setTimeout(() => {
      // Open Dialog Panel
      setOpen(true);

      // Stop loader
      setIsLoading(false);
    }, 100);
  };

  return (
    <Fragment>
      <div
        className="flex -space-x-2 justify-center"
        onClick={quickViewsHandler}
      >
        {isLoading ? (
          <div
            className="flex items-center justify-center inline-block
                bg-white h-10 w-10 border border-gray-200 shadow transition
                hover:border-secondary hover:bg-secondary hover:text-white
                rounded-full pt-1 cursor-pointer duration-500"
          >
            <LoadingBox />
          </div>
        ) : (
          <i
            className="fa fa-eye inline-block bg-white h-10 w-10 border
            border-gray-200 shadow transition hover:border-secondary
            hover:bg-secondary hover:text-white rounded-full p-3 cursor-pointer
            duration-500"
            aria-hidden="true"
          ></i>
        )}
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 hidden bg-black bg-opacity-75
            transition-opacity md:block"
            />
          </Transition.Child>

          <div className="fixed inset-0 z-30 overflow-y-auto">
            <div
              className="flex min-h-full items-stretch justify-center
              text-center md:items-center md:px-2 lg:px-4"
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                enterTo="opacity-100 translate-y-0 md:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 md:scale-100"
                leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              >
                <Dialog.Panel
                  className="flex w-full transform text-left
                  text-base transition md:my-8 md:max-w-2xl md:px-4
                  lg:max-w-4xl"
                >
                  <div
                    className="relative flex w-full items-center
                    overflow-hidden bg-white px-4 pt-14 pb-8 shadow-2xl sm:px-6
                    sm:pt-8 md:p-6 lg:p-8"
                  >
                    <button
                      type="button"
                      className="absolute top-4 right-4 text-gray-400
                        hover:text-gray-500 sm:top-8 sm:right-6 md:top-6
                        md:right-6 lg:top-8 lg:right-8"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    <div
                      className="grid w-full grid-cols-1 items-start gap-y-8
                      gap-x-6 sm:grid-cols-12 lg:gap-x-8"
                    >
                      <div
                        className="aspect-w-2 aspect-h-3 overflow-hidden
                        rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5"
                      >
                        <img
                          src={product.image}
                          alt={product.name}
                          className="object-cover object-center"
                        />
                      </div>
                      <div className="sm:col-span-8 lg:col-span-7">
                        <h2
                          className="text-2xl font-bold text-primary
                          sm:pr-12"
                        >
                          {product.name}
                        </h2>

                        <section
                          aria-labelledby="information-heading"
                          className="mt-2"
                        >
                          <h3 id="information-heading" className="sr-only">
                            Product information
                          </h3>
                          <p>
                            <Rating
                              rating={product.rating}
                              numReviews={product.num_reviews}
                            />
                          </p>
                          <p className="text-2xl font-bold text-secondary">
                            {product.price} FCFA
                          </p>
                          <p className="mt-4"> {product.short_description}</p>

                          <div className="flex mt-4">
                            {product.current_stock > 0 ? (
                              <FaceSmileIcon className="h-5 w-5 mr-2" />
                            ) : (
                              <FaceFrownIcon className="h-5 w-5 mr-2" />
                            )}

                            <div>
                              {product.current_stock > 0 ? (
                                <span className="">
                                  {product.current_stock} En stock
                                </span>
                              ) : (
                                <span className="danger">Indisponible</span>
                              )}
                            </div>
                          </div>
                          <div className="flex items-center mt-4">
                            {product.current_stock > 0 && (
                              <>
                                <div
                                  className="flex items-center grid gap-1
                                  xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2
                                  sm:grid-cols-2 grid-cols-1 mr-8"
                                >
                                  <div
                                    className="col-span-1 flex items-center
                                    grid gap-1 grid-cols-3 mb-4"
                                  >
                                    <div className="col-span-1">Quantité</div>
                                    <div className="col-span-2 px-4">
                                      <select
                                        className="w-full rounded-full
                                            text-center border border-gray-200
                                            focus:outline-none focus:ring-0"
                                        value={qty}
                                        onChange={(e: any) =>
                                          setQty(e.target.value)
                                        }
                                      >
                                        {[
                                          ...Array(
                                            product.current_stock
                                          ).keys(),
                                        ].map((x) => (
                                          <option key={x + 1} value={x + 1}>
                                            {" "}
                                            {x + 1}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-span-1 mb-4">
                                    <AddToCartButton
                                      product={product}
                                      qty={qty}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div
                            className="h-1 w-full border border-b-gray-200
                            border-x-transparent border-t-transparent"
                          ></div>
                          <p className="mt-4">
                            SKU : &nbsp;
                            <span className="text-sm text-gray-400">
                              {product.sku}
                            </span>
                          </p>
                          <p className="flex items-center">
                            Categories : &nbsp;
                            <span className="text-sm text-gray-400">
                              {/* {product.category} */}
                              {product.categories.length > 0
                                ? product.categories.map((category: any) => (
                                    <span
                                      className="flex items-center mr-1"
                                      key={category._id}
                                    >
                                      {category.name}
                                    </span>
                                  ))
                                : "Sans catégorie"}
                            </span>
                          </p>
                        </section>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
}

type ProductQuickviewsProps = {
  product: ProductType;
};
