import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { classNames } from "../../../utils/helper.util";

/**
 * Header language component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function HeaderLanguage(): JSX.Element {
  return (
    <Menu as="div" className="relative flex text-left">
      <Menu.Button className="outline-none">
        {({ open }) => (
          <GlobeAltIcon
            className={classNames(
              open
                ? `h-11 w-11 text-white bg-secondary rounded-full p-3
                    cursor-pointer duration-500`
                : `h-11 w-11 text-gray-500 bg-primary-rgba hover:bg-secondary
                    hover:text-white rounded-full p-3 cursor-pointer
                    duration-500`
            )}
            aria-hidden="true"
          />
        )}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute mt-5 border-2 border-x-0 border-b-0
            border-t-secondary right-0 z-10 mt-2 w-80 origin-top-right bg-white
            shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none
            top-9"
        >
          <Menu.Item>
            {({ active }) => (
              <Link
                to="#"
                className={classNames(
                  active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                  `block px-4 py-3 border border-x-0 border-t-0
                    border-b-gray-100`
                )}
              >
                English (UK)
              </Link>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <Link
                to="#"
                className={classNames(
                  active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                  `block px-4 py-3 border border-x-0 border-t-0
                    border-b-gray-100`
                )}
              >
                Français
              </Link>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
