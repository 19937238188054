import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toPrice } from "../../../../shared/utils/helper.util";
import useLocalStorage from "../../../../shared/hooks/use-localstorage";
import CartType from "../../../../shared/api/product-service/cart/cart.type";
import shippingMethodService from "../../../../shared/api/order-service/shipping-method/shipping-method.service";
import LoadingBox from "../../../../shared/components/others/loading-box";
import ShippingMethodType from "../../../../shared/api/order-service/shipping-method/shipping-method.type";
import ShippingPriceType from "../../../../shared/api/order-service/shipping-price/shipping-price.type";
import userService from "../../../../shared/api/user-service/user/user.service";
import shippingPriceService from "../../../../shared/api/order-service/shipping-price/shipping-price.service";

/**
 *  Checkout details
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-08-09
 *
 * @param {CheckoutDetailsProps} props the shared properties
 * @returns {JSX.Element} the javaScriptXML element
 */
export default function CheckoutDetails(
  props: CheckoutDetailsProps
): JSX.Element {
  const country = props.country;
  const state = props.state;
  const { triggerFormValidation } = props;
  const [tax, setTax] = useState(0);
  const [shippingPrice, setShippingPrice] = useState<ShippingPriceType>();
  const [shippingPricing, setShippingPricing] = useState(0);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [selectedShippingMethod, setSelectedShippingMethod] =
    useState<ShippingMethodType>();
  const [shippingMethodsIsLoading, setShippingMethodsIsLoading] =
    useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const navigate = useNavigate();
  const [cartItems] = useLocalStorage("cartItems", []);
  const cart: any = {
    cartItems,
    taxPrice: toPrice(0, 2),
    itemsPrice: toPrice(
      cartItems.reduce((a: any, c: any) => a + c.qty * c.price, 0),
      2
    ),
  };

  /**
   * Handle shipping method onchange
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-22
   *
   * @param {any} price the shipping price
   * @return {void}
   */
  const handleShippingMethodOnchange = (price: any): void => {
    cart.shippingPrice = toPrice(price, 2);
    cart.totalPrice = cart.itemsPrice + cart.shippingPrice + cart.taxPrice;

    setTax(cart.taxPrice);
    setShippingPricing(price);
    setTotalPrice(cart.totalPrice);
  };

  /**
   * Submit the form
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-25
   *
   * @returns {void}
   */
  const handleSubmit = (): void => {
    const checkoutDetailsData = {
      orderItems: cart.cartItems.map((p: any) => {
        return {
          name: p.name,
          image: p.image,
          price: p.price,
          product: p._id,
          qty: p.qty,
          current_stock: p.current_stock,
          vendor: p.vendor,
        };
      }),
      shippingMethod: selectedShippingMethod?._id,
      itemsPrice: cart.itemsPrice,
      shippingPrice: shippingPricing,
      taxPrice: tax,
      totalPrice: totalPrice,
    };

    props.checkoutDetailsData(checkoutDetailsData);
  };

  useEffect(() => {
    if (!cart.cartItems.length) navigate("/cart");
  }, [navigate, cart]);

  useEffect(() => {
    cart.shippingPrice = toPrice(0, 2);
    cart.taxPrice = toPrice(0, 2);
    cart.totalPrice = cart.itemsPrice + cart.shippingPrice + cart.taxPrice;

    setTax(cart.taxPrice);
    setTotalPrice(cart.totalPrice);
  }, [cartItems]);

  // Get shipping price
  useEffect(() => {
    if (state) {
      // Start loader
      setShippingMethodsIsLoading(true);

      userService.profile(cart.cartItems[0].vendor).subscribe({
        next: (data) => {
          if (data) {
            // Get shipping price
            shippingPriceService
              .getShippingPriceByDepartureArrival(data.state, state._id)
              .subscribe({
                next: (shippingPriceData) => {
                  if (shippingPriceData) setShippingPrice(shippingPriceData);
                  // Get shipping methods
                  shippingMethodService
                    .getShippingMethods(country._id)
                    .subscribe({
                      next: (shippingMethodData) => {
                        if (shippingMethodData) {
                          const selectFirstShippingMethod =
                            shippingMethodData.find(
                              (e: any) =>
                                e !== undefined &&
                                (e.slug !== "free-shipping" ||
                                  (e.slug === "free-shipping" &&
                                    e.minimum_amount <= cart.itemsPrice))
                            );
                          const price =
                            selectFirstShippingMethod?.slug === "fixed"
                              ? shippingPriceData?.price || 0
                              : 0;

                          setShippingMethods(shippingMethodData);
                          setSelectedShippingMethod(selectFirstShippingMethod);
                          setShippingPricing(price);
                          setTotalPrice(
                            cart.itemsPrice + cart.taxPrice + price
                          );

                          // Stop loader
                          setShippingMethodsIsLoading(false);
                        }
                      },
                      error: () => {
                        setShippingMethods([]);
                        setSelectedShippingMethod(undefined);
                        setShippingPricing(0);
                        setTotalPrice(cart.itemsPrice + cart.taxPrice);

                        // Stop loader
                        setShippingMethodsIsLoading(false);
                      },
                    });
                },
                error: (error) => {
                  console.log(error);
                  setShippingMethods([]);
                  setSelectedShippingMethod(undefined);
                  setShippingPricing(0);
                  setTotalPrice(cart.itemsPrice + cart.taxPrice);

                  // Stop loader
                  setShippingMethodsIsLoading(false);
                },
              });
          }
        },
        error: () => {
          // Stop loader
          setShippingMethodsIsLoading(false);
        },
      });
    } else {
      setShippingMethods([]);
      setSelectedShippingMethod(undefined);
      setShippingPricing(0);
      setTotalPrice(cart.itemsPrice + cart.taxPrice);
    }
  }, [state]);

  useEffect(() => {
    if (triggerFormValidation) handleSubmit();
    else props.checkoutDetailsData(null);
  }, [triggerFormValidation]);

  return (
    <div className="">
      <div
        className="flex justify-between border-b border-gray-200
          text-primary font-bold pb-3"
      >
        <p>Produit</p>
        <p>Sous-total</p>
      </div>
      {cart.cartItems.map((item: CartType) => (
        <div
          key={item._id}
          className="flex justify-between border-b border-gray-200
              py-3"
        >
          <Link to={`/product/${item._id}`}>
            {item.name} x {item.qty}
          </Link>
          <p className="text-primary font-bold">{item.qty * item.price} FCFA</p>
        </div>
      ))}
      <div
        className="flex justify-between border-b border-gray-200
          text-primary font-bold py-3"
      >
        <p>Sous-total</p>
        <p className="text-primary font-bold">{cart.itemsPrice} FCFA</p>
      </div>
      <div
        className="flex justify-between gap-20 border-b border-gray-200 py-3
          xl:gap-32 lg:gap-24 md:gap-14 sm:gap-32"
      >
        <div className="text-primary font-bold">Expédition</div>
        <div className="text-right">
          {shippingMethodsIsLoading ? (
            <LoadingBox />
          ) : shippingMethods.length ? (
            <div className="space-y-3">
              {shippingMethods.map(
                (element: any) =>
                  (element.slug !== "free-shipping" ||
                    (element.slug === "free-shipping" &&
                      element.minimum_amount <= cart.itemsPrice)) && (
                    <div key={element._id} className="flex items-center">
                      <label
                        htmlFor={element._id}
                        className="w-full capitalize mr-2 block
                          text-gray-900"
                      >
                        {element.name}
                        {element.slug === "fixed" &&
                          `: ${shippingPrice?.price} FCFA`}
                      </label>
                      <input
                        id={element._id}
                        name="shippingMethod"
                        type="radio"
                        value={element._id}
                        checked={selectedShippingMethod?.name === element.name}
                        className="h-4 w-4 rounded-50 border-gray-300 mt-0 pt-0
                        text-secondary focus:ring-0 focus:ring-transparent mb-1"
                        onChange={() => {
                          setSelectedShippingMethod(element);
                          handleShippingMethodOnchange(
                            element.slug !== "fixed"
                              ? 0
                              : shippingPrice?.price || 0
                          );
                        }}
                      />
                    </div>
                  )
              )}
            </div>
          ) : (
            <div>
              Aucun mode d&apos;expédition disponible. Assurez-vous que
              l&apos;adresse que vous avez saisie est correcte ou contactez-nous
              si vous avez besoin d&apos;aide.
            </div>
          )}
        </div>
      </div>
      <div
        className="flex justify-between border-b border-gray-200
          text-primary font-bold py-3"
      >
        <p>Tax</p>
        <p className="text-primary font-bold">{tax.toFixed(2)} FCFA</p>
      </div>
      <div className="flex justify-between text-primary font-bold pt-6">
        <p>Total</p>
        <p className="text-secondary text-xl font-bold">
          {totalPrice.toFixed(2)} FCFA
        </p>
      </div>
    </div>
  );
}

type CheckoutDetailsProps = {
  triggerFormValidation: boolean;
  checkoutDetailsData: any;
  country?: any;
  state?: any;
};
