import React from "react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import wishlistService from "../../api/product-service/wishlist/wishlist.service";
import ProductType from "../../api/product-service/product/product.type";

/**
 * Remove from wishlist button component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-29
 *
 * @param {RemoveFromWishlistButtonProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function RemoveFromWishlistButton(
  props: RemoveFromWishlistButtonProps
): JSX.Element {
  const product = props.product;

  /**
   * Remove product from wishlist
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-29
   *
   * @param {string} id the product's id
   * @return {void}
   */
  const removeFromWishlistHandler = (id: string): void => {
    // delete action
    wishlistService.removeFromWishlist(id).subscribe({
      next: () => {
        toast.success("Produit retiré avec succès au panier!", {
          theme: "colored",
        });
      },
      error: () => {
        toast.error("Error !", {
          theme: "colored",
        });
      },
    });
  };

  return (
    <button
      type="button"
      onClick={() => removeFromWishlistHandler(product._id)}
    >
      <XCircleIcon
        className={
          props.className ||
          `flex justify-center items-center h-4 w-4
          text-gray-500 hover:text-secondary`
        }
        aria-hidden="true"
      />
    </button>
  );
}

type RemoveFromWishlistButtonProps = {
  product: ProductType;
  className?: string;
};
