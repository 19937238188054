import React, { Fragment, useRef, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { UserIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import useOutsideClick from "../../../hooks/use-outside-click";
import Signin from "../../others/signin";
import { classNames } from "../../../utils/helper.util";
import { toast } from "react-toastify";
import useLocalStorage from "../../../hooks/use-localstorage";
import { useNavigate } from "react-router-dom";
import customerAccountMenuItems from "../../../../resources/navigations/customer-account-menu-items.json";
import authService from "../../../api/auth-service/auth/auth.service";

/**
 * Header account component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @param {any} props the report handler
 * @returns {JSX.Element} the javascript xml element
 */
export default function HeaderAccount(props: any): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [userInfo] = useLocalStorage("userInfo", null);
  const wrapperRef = useRef(null);
  const navigate = useNavigate();

  // Create a copy of the props
  const tempProps = JSON.parse(JSON.stringify(props));

  /**
   * Handle click outside
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} closed the react mouse event
   * @returns {void}
   */
  const handleClickOutside = (closed: any): void => {
    setIsOpen(!closed);
  };

  tempProps.onHandleClickOutside = handleClickOutside;
  useOutsideClick(wrapperRef, tempProps);

  /**
   * Handle button click
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @returns {void}
   */
  const buttonClicked = (): void => {
    setIsOpen(!isOpen);
  };

  /**
   * Signout handler
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @returns {void}
   */
  const signoutHandler = (): void => {
    buttonClicked();

    authService.signout().subscribe({
      next: () => {
        toast.success("Déconnexion réussie!", {
          theme: "colored",
        });

        // setTimeout(() => {
        //   navigate("/signin");
        // }, 2000)
      },
      error: (e) => {
        toast.error(e, {
          theme: "colored",
        });
      },
    });
  };

  return (
    <Menu as="div" ref={wrapperRef} className="relative flex text-left">
      <Menu.Button className="outline-none" onClick={buttonClicked}>
        <UserIcon
          className={classNames(
            isOpen
              ? `h-11 w-11 text-white bg-secondary rounded-full p-3
                cursor-pointer duration-500`
              : `h-11 w-11 text-gray-500 bg-primary-rgba hover:bg-secondary
                hover:text-white rounded-full p-3 cursor-pointer duration-500`
          )}
          aria-hidden="true"
        />
      </Menu.Button>

      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            userInfo ? "w-56" : "w-80",
            `absolute mt-5 border-2 border-x-0 border-b-0
              border-t-secondary right-0 z-10 mt-2 origin-top-right
              bg-white shadow-lg ring-1 ring-black ring-opacity-5
              focus:outline-none top-9 text-gray-500`
          )}
        >
          {isOpen && userInfo ? (
            <div className="">
              {customerAccountMenuItems.map((item: any) => (
                <Menu.Item key={item.name}>
                  {({ active }) =>
                    item.slug !== "logout" ? (
                      <Link
                        to={
                          item.slug === "dashboard"
                            ? "/my-account"
                            : `/my-account/${item.slug}`
                        }
                        onClick={() => buttonClicked()}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          `block px-4 py-3 border border-x-0 border-t-0
                            border-b-gray-100`
                        )}
                      >
                        <span className="h-5 flex justify-start items-center">
                          {/* <span className="flex justify-start
                          items-center pr-2">
                            {
                              item.icon &&
                              <DynamicHeroIcon
                                  icon={item.icon}
                                  className="w-6 h-6"/>
                            }
                          </span> */}
                          <span className="pt-1">{item.name}</span>
                        </span>
                      </Link>
                    ) : (
                      <a
                        key={item.name}
                        onClick={signoutHandler}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          `block px-4 py-3 border border-x-0 border-t-0
                              border-b-gray-100 cursor-pointer`
                        )}
                      >
                        <span className="h-5 flex justify-start items-center">
                          {/* <span className="flex justify-start
                            items-center pr-2">
                              {
                                item.icon &&
                                <DynamicHeroIcon
                                    icon={item.icon}
                                    className="w-6 h-6"/>
                              }
                            </span> */}
                          <span className="pt-1">{item.name}</span>
                        </span>
                      </a>
                    )
                  }
                </Menu.Item>
              ))}
            </div>
          ) : isOpen && !userInfo ? (
            <div className="p-5">
              <Signin onClose={(close: any) => setIsOpen(close)} />
            </div>
          ) : null}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
