import React from "react";
import useLocalStorage from "../../../shared/hooks/use-localstorage";
import authService from "../../../shared/api/auth-service/auth/auth.service";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";

/**
 * Dashboard component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-11
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function Dashboard(): JSX.Element {
  const [userInfo] = useLocalStorage("userInfo", null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const userNameSpan = (
    <span className="text-primary font-bold">
      {userInfo && userInfo.username}
    </span>
  );

  /**
   * Signout handler
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-11
   *
   * @param {any} e the react mouse event
   * @returns {void}
   */
  const signoutHandler = (e: any): void => {
    e.preventDefault();

    authService.signout().subscribe({
      next: () => {
        toast.success("Déconnexion réussie!", {
          theme: "colored",
        });

        setTimeout(() => {
          navigate(`/signin?redirect=${pathname}`);
        }, 2000);
      },
      error: (e) => {
        toast.error(e, {
          theme: "colored",
        });
      },
    });
  };

  return (
    <div className="text-gray-500">
      <p>
        Bonjour {userNameSpan} (vous n&apos;êtes pas {userNameSpan} ?{" "}
        <Link
          to=""
          className="text-secondary hover:text-ternary"
          onClick={(e) => signoutHandler(e)}
        >
          Déconnexion
        </Link>
        )
      </p>
      <p className="mt-6 leading-6">
        À partir du tableau de bord de votre compte, vous pouvez visualiser vos
        &nbsp;
        <Link
          to="/my-account/orders"
          className="text-secondary hover:text-ternary"
        >
          commandes récentes
        </Link>
        , &nbsp;gérer vos&nbsp;
        <Link
          to="/my-account/address"
          className="text-secondary hover:text-ternary"
        >
          adresses de livraison et de facturation
        </Link>
        &nbsp;ainsi que&nbsp;
        <Link
          to="/my-account/profile"
          className="text-secondary hover:text-ternary"
        >
          changer votre mot de passe et les détails de votre compte.
        </Link>
      </p>
    </div>
  );
}
