import React from "react";
import { Route, Routes } from "react-router-dom";
import { AuthLayout } from "../../../shared/components/others/auth-layout";
import LoggedRoute from "../../../shared/components/route/logged-route";
import ForgotPasswordPage from "../pages/forgot-password.page";

/**
 * Forgot password route
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-22
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function ForgotpasswordRoute(): JSX.Element {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route
          path="/forgot-password"
          element={
            <LoggedRoute>
              <ForgotPasswordPage />
            </LoggedRoute>
          }
        />
      </Route>
    </Routes>
  );
}
