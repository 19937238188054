import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Banner from "../../../shared/components/banners/banner";
import PostFilter from "../components/post-filter.component";
import SearchBox from "../components/search-box";

/**
 * Blog page
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-06
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function BlogPage(): JSX.Element {
  const [stateCategory, setStateCategory] = useState("all");
  const [stateTag, setStateTag] = useState("all");

  /**
   * Handle category change
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} category the product's category
   * @returns {void}
   */
  const handleCategoryChange = (category: any): void => {
    setStateCategory(category.category);
  };

  /**
   * Handle tag change
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} tag the product's tag
   * @returns {void}
   */
  const handleTagChange = (tag: any): void => {
    setStateTag(tag.tag);
  };

  return (
    <div>
      <Banner title="Blog" />
      <div
        className="grid grid-cols-1 gap-4 mt-12
        xl:grid-cols-7 lg:grid-cols-7"
      >
        <div
          className="col-span-1 border-dashed border-gray-200
          xl:col-span-5 lg:col-span-5 xl:pr-12 lg:pr-12 xl:border-r
          lg:border-r"
        >
          <Outlet />
        </div>
        <div className="col-span-2 pl-8 hidden xl:block lg:block">
          <div className="mb-10">
            <SearchBox />
          </div>
          <div>
            <PostFilter
              onCategoryChange={handleCategoryChange}
              onTagChange={handleTagChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
