import React, { useEffect, useState } from "react";
import { PlayIcon } from "@heroicons/react/24/outline";
import { Link, useParams, useNavigate } from "react-router-dom";
import LoadingBox from "../../../shared/components/others/loading-box";
import MessageBox from "../../../shared/components/others/message-box";
import { _date, classNames } from "../../../shared/utils/helper.util";
import tagService from "../../../shared/api/blog-service/tag/tag.service";
import categoryService from "../../../shared/api/blog-service/category/category.service";
import CategoryType from "../../../shared/api/blog-service/category/category.type";
import TagType from "../../../shared/api/blog-service/tag/tag.type";
import postService from "../../../shared/api/blog-service/post/post.service";
import PostType from "../../../shared/api/blog-service/post/post.type";

/**
 * Post filter component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-07
 *
 * @param {PostFilterProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function PostFilter(props: PostFilterProps): JSX.Element {
  const [stateTag, setStateTag] = useState("");
  const [categoryIsLoading, setCategoryIsLoading] = useState(true);
  const [categoryError, setCategoryError] = useState("");
  const [categories, setCategories] = useState<Array<CategoryType>>([]);
  const [tagIsLoading, setTagIsLoading] = useState(true);
  const [tagError, setTagError] = useState("");
  const [tags, setTags] = useState<Array<TagType>>([]);
  const [lastPosts, setLastPosts] = useState<Array<PostType>>([]);
  const [lastPostsError, setLastPostsError] = useState("");
  const [lastPostsIsLoading, setLastPostsIsLoading] = useState(true);

  const { tag: tagParam } = useParams();
  const navigate = useNavigate();

  /**
   * Handle category change
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} category the post's category
   * @returns {void}
   */
  const handleChangeCategory = (category: any): void => {
    props.onCategoryChange({ category });
  };

  /**
   * Handle MinMax change
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} tag the post's tag
   * @returns {void}
   */
  // const handleChangeTag = (tag: any): void => {
  //   props.onTagChange({ tag });
  //   setStateTag(tag);
  // };

  // Get all categories
  useEffect(() => {
    categoryService.getAll().subscribe({
      next: (data) => {
        // Stop loading
        setCategoryIsLoading(false);

        setCategories(data);
      },
      error: (e) => {
        // Stop loading
        setCategoryIsLoading(false);

        setCategoryError(e);
      },
    });
  }, []);

  // Get all tags
  useEffect(() => {
    tagService.getAll().subscribe({
      next: (data) => {
        // Stop loading
        setTagIsLoading(false);

        setTags(data);
      },
      error: (e) => {
        // Stop loading
        setTagIsLoading(false);

        setTagError(e);
      },
    });
  }, []);

  // Get three last posts
  useEffect(() => {
    postService.showAll({ order: "newest", perPage: 3 }).subscribe({
      next: (data) => {
        // Stop loading
        setLastPostsIsLoading(false);

        setLastPosts(data.posts);
      },
      error: (e) => {
        // Stop loading
        setLastPostsIsLoading(false);

        setLastPostsError(e);
      },
    });
  }, []);

  return (
    <>
      <div className="mt-3">
        <h3
          className="text-lg text-primary font-bold pb-4 border-b
            border-dotted border-b-gray-200"
        >
          Catégories
        </h3>
        <div className="mt-8">
          {categoryIsLoading ? (
            <LoadingBox></LoadingBox>
          ) : categoryError ? (
            <MessageBox variant="danger">{categoryError}</MessageBox>
          ) : (
            categories.map((category: any) => (
              <div className="flex items-center mb-3" key={category._id}>
                <PlayIcon className="h-3 w-3 mr-3 text-secondary" />
                <Link
                  to={`/blog/post/category/${category.slug}`}
                  onClick={() => handleChangeCategory(category.slug)}
                >
                  {category.name}
                  <span className="ml-1 text-gray-400">
                    ({category.posts.length})
                  </span>
                </Link>
              </div>
            ))
          )}
        </div>
      </div>
      <div className="mt-10">
        <h3
          className="text-lg text-primary font-bold pb-2 border-b
          border-dotted border-b-gray-200"
        >
          Articles récents
        </h3>
        <div className="mt-8 h-full">
          {lastPostsIsLoading ? (
            <LoadingBox></LoadingBox>
          ) : lastPostsError ? (
            <MessageBox variant="danger">{lastPostsError}</MessageBox>
          ) : (
            lastPosts.map((post: PostType) => (
              <div key={post._id} className="flex items-center mb-4">
                <div className="flex w-28">
                  <Link to={`/blog/post/${post._id}`}>
                    <img
                      src={post.image}
                      alt={post.name}
                      className="h-28 w-28 object-cover object-center"
                    />
                  </Link>
                </div>
                <div className="flex-1 ml-4">
                  <Link to={`/blog/post/${post._id}`}>
                    <p className="font-bold line-clamp-2">{post.name}</p>
                  </Link>
                  <p className="mt-2 text-gray-500">{_date(post.created_at)}</p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <div className="mt-10">
        <h3
          className="text-lg text-primary font-bold pb-2 border-b
            border-dotted border-b-gray-200"
        >
          Étiquettes de post
        </h3>
        <div className="mt-8 h-full">
          {tagIsLoading ? (
            <LoadingBox></LoadingBox>
          ) : tagError ? (
            <MessageBox variant="danger">{tagError}</MessageBox>
          ) : (
            tags.map((tag: any) => (
              <button
                key={tag._id}
                onClick={() => {
                  // if (tag.slug === tagParam) handleChangeTag("any");
                  // else handleChangeTag(tag.slug);
                  navigate(`/blog/post/tag/${tag.slug}`);
                }}
                className={classNames(
                  tag.slug === stateTag || tag.slug === tagParam
                    ? "bg-secondary bg-secondary text-white"
                    : `bg-white border-gray-200 hover:bg-secondary
                              hover:border-secondary hover:text-white`,
                  `relative text-xs lowercase px-4 py-1 mb-3 border
                          rounded-full cursor-pointer mr-1`
                )}
              >
                {tag.name}
              </button>
            ))
          )}
        </div>
      </div>
    </>
  );
}

type PostFilterProps = {
  onCategoryChange: any;
  onTagChange: any;
};
