import React from "react";
import { useOutlet } from "react-router-dom";
import { AuthProvider } from "../../hooks/use-auth";

/**
 * Chat box component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-29
 *
 * @returns {JSX.Element} the javaScriptXML element
 */
export const AuthLayout = (): JSX.Element => {
  const outlet = useOutlet();

  return <AuthProvider>{outlet}</AuthProvider>;
};
