import React from "react";
import Banner from "../../../shared/components/banners/banner";
import ResetPassword from "../../../shared/components/others/reset-password";
/**
 * Reset password page
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-27
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function ResetPasswordPage(): JSX.Element {
  return (
    <div className="h-full">
      <div className="">
        <Banner title="Connexion" />
      </div>
      <div className="flex h-fit items-center justify-center pt-10">
        <div className="w-full max-w-lg space-y-4">
          <h2
            className="text-xl font-bold tracking-tight
                        text-primary"
          >
            Réinitialisation du mot de passe
          </h2>
          {/* <p className="tracking-tight text-primary">
                        Mot de passe perdu ? Veuillez saisir votre adresse
                        e-mail. Vous recevrez un lien par e-mail pour
                        créer un nouveau mot de passe.
                    </p> */}
          <ResetPassword />
        </div>
      </div>
    </div>
  );
}
