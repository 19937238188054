import React from "react";

/**
 * Message box component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @param {MessageBoxProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
function MessageBox(props: MessageBoxProps): JSX.Element {
  return (
    <div className={`alert alert-${props.variant || "info"} break-words`}>
      {props.children}
    </div>
  );
}

type MessageBoxProps = {
  variant?: string;
  children: any;
};

export default MessageBox;
