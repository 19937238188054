import React, { useRef } from "react";
import { Link } from "react-router-dom";
import useOutsideClick from "../../../hooks/use-outside-click";
import ProductType from "../../../api/product-service/product/product.type";

/**
 * Search list component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @param {SearchListProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function SearchList(props: SearchListProps): JSX.Element {
  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, props);

  /**
   * Handle close
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @returns {void}
   */
  const handleClose = (): void => {
    props.onHandleClickOutside(true);
  };

  if (!props.products.length) {
    return (
      <div
        ref={wrapperRef}
        className="flex max-h-80 bg-white shadow-2xl overflow-auto
            duration-500"
      >
        <div className="flex-1 px-4 py-4 sm:px-6">
          <p className="flex py-4 px-4 items-center">Aucun résultat !</p>
        </div>
      </div>
    );
  }

  return (
    <div
      ref={wrapperRef}
      className="flex max-h-80 bg-white shadow-2xl overflow-auto duration-500"
    >
      <div className="flex-1">
        <div>
          <div className="flow-root">
            <ul role="list" className="divide-y divide-gray-200">
              {props.products.map((product: ProductType) => (
                <li key={product._id} className="flex py-4 px-8 items-center">
                  <div className="w-23 flex-shrink-0">
                    <Link to={`/product/${product._id}`} onClick={handleClose}>
                      <img
                        src={product.image}
                        alt={product.name}
                        className="h-full w-full object-cover object-center"
                      />
                    </Link>
                  </div>

                  <div className="flex flex-1 flex-col">
                    <div>
                      <div className="ml-4 text-gray-900">
                        <Link
                          className="p-0 hover:text-gray-900"
                          to={`/product/${product._id}`}
                          onClick={handleClose}
                        >
                          <h3 className="line-clamp-2">{product.name}</h3>
                        </Link>
                        <Link
                          className="p-0"
                          to={`/product/${product._id}`}
                          onClick={handleClose}
                        >
                          <p
                            className="font-bold text-secondary
                            hover:text-ternary mt-1"
                          >
                            {product.price} FCFA
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

type SearchListProps = {
  products: Array<any>;
  onHandleClickOutside: any;
};
