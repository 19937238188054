import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import LoadingBox from "./loading-box";
import MessageBox from "./message-box";
import { useValidation } from "react-simple-form-validator";
import {
  classNames,
  getFieldsCustomErrorMessages,
} from "../../utils/helper.util";
import { toast } from "react-toastify";
import useLocalStorage from "../../hooks/use-localstorage";
import authService from "../../api/auth-service/auth/auth.service";

/**
 * Signin component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-29
 *
 * @param {SigninProps} props the shared properties
 * @returns {JSX.Element} the javaScriptXML element
 */
export default function Signin(props: SigninProps): JSX.Element {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [fieldErrorMessages, setFieldErrorMessages] = useState<Array<string>>(
    []
  );
  const [touchedFields, setTouchedFields] = useState({
    email: false,
    password: false,
  });

  const { isFieldInError, getErrorsInField, isFormValid } = useValidation({
    fieldsRules: {
      email: { required: true, email: true },
      password: {
        required: true,
        minlength: 6,
      },
    },
    state: { email, password },
    locale: "en",
  });

  const [userLogin] = useLocalStorage("userLogin", null);
  const [search] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const redirect =
    search.get("redirect") && search.get("redirect") != "/"
      ? `${search.get("redirect")}`
      : "/";

  /**
   * Submit handler
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-29
   *
   * @param {any} e the react mouse event
   * @returns {void}
   */
  const submitHandler = (e: any): void => {
    e.preventDefault();

    if (isFormValid) {
      // Start loading
      setIsLoading(true);

      authService.signin(email, password, rememberMe).subscribe({
        next: () => {
          // Stop loading
          setIsLoading(false);

          // Close form popup
          handleClose();
        },
        error: (e) => {
          // Stop loading
          setIsLoading(false);

          toast.error(e, {
            theme: "colored",
          });
        },
      });
    } else {
      setFieldErrorMessages(
        getFieldsCustomErrorMessages(
          touchedFields,
          isFieldInError,
          getErrorsInField,
          false
        )
      );

      setTouchedFields({
        email: true,
        password: true,
      });
    }
  };

  /**
   *  handleClose
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-29
   *
   * @returns {void}
   */
  const handleClose = (): void => {
    if (props.onClose) {
      props.onClose(false);
    }
  };

  /**
   * OnBlurHandler
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-15
   *
   * @param {React.FormEvent<HTMLElement>} event the react mouse event
   * @param {string} field the field on blur
   * @returns {void}
   */
  const onBlurHandler = (
    event: React.FormEvent<HTMLElement>,
    field: string
  ): void => {
    setTouchedFields((prevFields) => ({ ...prevFields, [field]: true }));
  };

  useEffect(() => {
    setFieldErrorMessages(
      getFieldsCustomErrorMessages(
        touchedFields,
        isFieldInError,
        getErrorsInField
      )
    );
  }, [touchedFields]);

  // Fill in the login form with the user's login data in localestorage,
  // if available.
  useEffect(() => {
    if (userLogin) {
      // Decode user login data with base64
      const data: any = JSON.parse(atob(userLogin));

      setEmail(data.email);
      setPassword(data.password);
      setRememberMe(data.rememberMe);
    }
  }, [userLogin]);

  return (
    <div className="flex min-h-full items-center justify-center">
      <div className="w-full">
        {fieldErrorMessages.length ? (
          <div className="mb-4">
            <MessageBox variant="danger">
              {fieldErrorMessages.map((s) => (
                <span key={s}>
                  {s}
                  <br />
                </span>
              ))}
            </MessageBox>
          </div>
        ) : null}
        <form onSubmit={submitHandler}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm mb-4">
            <div className="mb-4">
              <label htmlFor="email" className="text-gray-900">
                Email
                <span className="text-red-500">*</span>
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="off"
                required
                className={classNames(
                  touchedFields.email && isFieldInError("email")
                    ? `border-red-500 focus:border-red-500 focus:outline-none
                    focus:ring-0 focus:ring-secondary`
                    : `border-gray-300 focus:border-secondary
                      focus:outline-none focus:ring-0 focus:ring-secondary`,
                  `relative block w-full appearance-none rounded-md border
                    px-3 py-2 text-gray-900 placeholder-gray-500`
                )}
                placeholder="Adresse email"
                value={email}
                onBlur={(e) => onBlurHandler(e, "email")}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="text-gray-900">
                Mot de passe
                <span className="text-red-500">*</span>
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="off"
                required
                className={classNames(
                  touchedFields.password && isFieldInError("password")
                    ? `border-red-500 focus:border-red-500 focus:outline-none
                    focus:ring-0 focus:ring-secondary`
                    : `border-gray-300 focus:border-secondary
                      focus:outline-none focus:ring-0 focus:ring-secondary`,
                  `relative block w-full appearance-none rounded-md border
                    px-3 py-2 text-gray-900 placeholder-gray-500`
                )}
                placeholder="Mot de passe"
                value={password}
                onBlur={(e) => onBlurHandler(e, "password")}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              <input
                id="rememberMe"
                name="rememberMe"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-secondary
                    focus:outline-none focus:ring-0"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <label htmlFor="rememberMe" className="ml-2 block text-gray-900">
                Se souvenir
              </label>
            </div>

            <div>
              <Link
                to="/forgot-password"
                className="font-medium text-primary hover:text-secondary"
                onClick={handleClose}
              >
                Mot de passe oublié ?
              </Link>
            </div>
          </div>

          <div className="mb-4">
            <button
              type="submit"
              className={classNames(
                isLoading
                  ? "bg-gray-400 text-gray-400 hover:bg-gray-400"
                  : `bg-secondary hover:bg-ternary focus:outline-none
                  focus:ring-primary text-white`,
                `w-full group flex justify-center rounded border
                border-transparent py-2 px-4 font-medium focus:ring-0 relative
                focus:ring-offset-2`
              )}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="absolute text-black">
                  <LoadingBox className="text-white" />
                </div>
              ) : (
                <span
                  className="absolute inset-y-0 left-0 flex
                        items-center pl-3"
                >
                  <LockClosedIcon
                    className="h-5 w-5 text-white group-hover:text-white"
                    aria-hidden="true"
                  />
                </span>
              )}
              Se connecter
            </button>
          </div>
          <div>
            <p className="text-center text-gray-900">
              Nouveau client ?{" "}
              <Link
                to={`/register?redirect=${redirect}`}
                className="font-medium text-primary hover:text-secondry"
                onClick={handleClose}
              >
                Créer un compte
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

type SigninProps = {
  onClose?: any;
};
