import React from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../hooks/use-auth";

/**
 * Logged route component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-27
 *
 * @param {LoggedRouteProps} props the shared properties
 * @returns {JSX.Element} the javaScriptXML element
 */
const LoggedRoute = (props: LoggedRouteProps) => {
  const [search] = useSearchParams();
  const children = props.children;
  const { user } = useAuth();
  const redirect =
    search.get("redirect") && search.get("redirect") != "/"
      ? `${search.get("redirect")}`
      : "/";

  return user ? <Navigate to={redirect} /> : children;
};

type LoggedRouteProps = {
  children: any;
};

export default LoggedRoute;
