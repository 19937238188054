import React from "react";
import Banner from "../../../shared/components/banners/banner";
import Setting from "../components/setting.component";

/**
 * User page
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-07
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function CustomerPage(): JSX.Element {
  return (
    <div>
      <div className="">
        <Banner title="Profil" />
      </div>
      <div className="mt-6">
        <Setting />
      </div>
    </div>
  );
}
