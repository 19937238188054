import React, { useEffect, useState } from "react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import useLocalStorage from "../../../shared/hooks/use-localstorage";
import userService from "../../../shared/api/user-service/user/user.service";
import LoadingBox from "../../../shared/components/others/loading-box";
import MessageBox from "../../../shared/components/others/message-box";
import { Link } from "react-router-dom";

/**
 * Address details component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-05
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function AddressDetails(): JSX.Element {
  const [userInfo] = useLocalStorage("userInfo", null);
  const [user, setUser] = useState<any>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (userInfo) {
      // Start loader
      setIsLoading(true);

      userService.profile(userInfo._id).subscribe({
        next: (data) => {
          // Stop loading
          setIsLoading(false);

          setUser(data);
        },
        error: (e) => {
          console.log(e);
          // Stop loading
          setIsLoading(false);

          setError(e);
        },
      });
    }
  }, [userInfo]);

  return (
    <div>
      <p className="text-gray-500">
        Les adresses suivantes seront utilisées par défaut sur la page de
        commande.
      </p>
      <div
        className="grid grid-cols-1 gap-6 xl:grid-cols-2 lg:grid-cols-2
          md:grid-cols-2 mt-8 text-gray-500"
      >
        <div className="col-span-1">
          <div className="flex justify-between">
            <h2 className="text-primary text-xl font-bold mb-4">
              Adresse de facturation
            </h2>
            <Link
              to="/my-account/address/edit-billing"
              className="text-secondary hover:text-ternary"
            >
              <PencilSquareIcon className="h-5 w-5" />
            </Link>
          </div>
          {isLoading ? (
            <LoadingBox />
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : user?.billing_address ? (
            <div className="leading-8 italic">
              <p>
                {user?.billing_address?.first_name && (
                  <span>{user?.billing_address?.first_name}&nbsp;</span>
                )}
                {user?.billing_address?.last_name && (
                  <span>{user?.billing_address?.last_name}</span>
                )}
              </p>
              <p>{user?.billing_address?.company}</p>
              <p>{user?.billing_address?.address1}</p>
              <p>{user?.billing_address?.state?.name}</p>
              <p>{user?.billing_address?.city}</p>
              <p>{user?.billing_address?.postal_code}</p>
              <p>{user?.billing_address?.email}</p>
              <p>{user?.billing_address?.phone}</p>
              <p>{user?.billing_address?.country?.name}</p>
            </div>
          ) : (
            <p className="">
              Vous n&apos;avez pas encore défini ce type d&apos;adresse.
            </p>
          )}
        </div>
        <div className="col-span-1">
          <div className="flex justify-between">
            <h2 className="text-primary text-xl font-bold mb-4">
              Adresse de livraison
            </h2>
            <Link
              to="/my-account/address/edit-delivery"
              className="text-secondary hover:text-ternary"
            >
              <PencilSquareIcon className="h-5 w-5" />
            </Link>
          </div>
          {isLoading ? (
            <LoadingBox />
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : user?.delivery_address ? (
            <div className="leading-8 italic">
              <p>
                {user?.delivery_address?.first_name && (
                  <span>{user?.delivery_address?.first_name}&nbsp;</span>
                )}
                {user?.delivery_address?.last_name && (
                  <span>{user?.delivery_address?.last_name}</span>
                )}
              </p>
              <p>{user?.delivery_address?.company}</p>
              <p>{user?.delivery_address?.address1}</p>
              <p>{user?.delivery_address?.state?.name}</p>
              <p>{user?.delivery_address?.city}</p>
              <p>{user?.delivery_address?.postal_code}</p>
              <p>{user?.delivery_address?.phone}</p>
              <p>{user?.delivery_address?.country?.name}</p>
            </div>
          ) : (
            <p className="">
              Vous n&apos;avez pas encore défini ce type d&apos;adresse.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
