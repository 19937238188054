import { Observable, defer, map } from "rxjs";
import { AxiosResponse } from "axios";
import axiosInstance from "../../../utils/http-client.util";

/**
 * Post service
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-07
 *
 * class PostService
 */
class PostService {
  public lang = "en";

  /**
   * Get all posts
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-10-07
   *
   * @returns {Promise<void>} the eventual completion or failure
   */
  public showAll({
    page = "",
    name = "",
    category = "",
    tag = "",
    order = "",
    rating = 0,
    perPage = 0,
  }): Observable<any> {
    const url = `/v1/${this.lang}/blog/posts?page=${page}&name=${name}
      &category=${category}&tag=${tag}&rating=${rating}&order=${order}
      &perPage=${perPage}`;

    return defer(() => axiosInstance.get(url.replace(/\s/g, ""))).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Get post by id
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-10-07
   *
   * @param {any} postId the post's id
   * @returns {Observable<any>} the eventual completion or failure
   */
  public getById(postId: any): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/blog/post/${postId}`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Create review
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-10-09
   *
   * @param {any} postId the product's id
   * @param {any} review the review data
   * @returns {Observable<any>} the eventual completion or failure
   */
  public createReview(postId: any, review: any): Observable<any> {
    return defer(() =>
      axiosInstance.post(`/v1/${this.lang}/blog/post/${postId}/reviews`, review)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }
}

const postService = new PostService();
export default postService;
