// import { FaceFrownIcon, FaceSmileIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { Link, useNavigate } from "react-router-dom";
import LoadingBox from "../../../shared/components/others/loading-box";
import MessageBox from "../../../shared/components/others/message-box";
import Rating from "../../../shared/components/others/rating";
import AddToCart from "../../../shared/components/product/add-to-cart";
import productService from "../../../shared/api/product-service/product/product.service";
import ProductType from "../../../shared/api/product-service/product/product.type";

/**
 * Home special product component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function HomeSpecialProduct(): JSX.Element {
  const [productIsLoading, setProductIsLoading] = useState(true);
  const [products, setProducts] = useState<any>([]);
  const [error, setError] = useState();
  const navigate = useNavigate();

  /**
   * Redirect to product detaila
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {string} id the product's id
   * @return {void}
   */
  const productDetailsHandler = (id: string): void => {
    navigate(`/product/${id}`);
  };

  useEffect(() => {
    productService
      .showAll({
        promotional: 1 as any,
      })
      .subscribe({
        next: (data) => {
          setProductIsLoading(false);
          setProducts(data.products);
        },
        error: (e) => {
          setProductIsLoading(false);
          setError(e);
        },
      });
  }, []);

  return (
    <div
      className="special-product xl:py-24 lg:py-24 md:py-24 sm:py-16
        py-14"
    >
      <div className="text-center">
        <p
          className="greatest-richmond-font text-secondary xl:text-2xl
            lg:text-2xl md:text-2xl sm:text-xl text-xl our-offers-title"
        >
          Nos Offres
        </p>
        <p
          className="font-bold text-primary mt-2 xl:text-4xl
            lg:text-4xl md:text-3xl sm:text-2xl text-2xl"
        >
          Produits Spéciaux
        </p>
      </div>
      <div className="mt-6 mx-auto max-w-4xl px-8">
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          showArrows={true}
          showIndicators={false}
          showThumbs={false}
          showStatus={false}
          interval={10000}
          preventMovementUntilSwipeScrollTolerance={true}
          className="xl:h-98 lg:h-98 md:h-98 sm:h-98 min-h-sm bg-white
            rounded-md border-2 border-ternary"
        >
          {productIsLoading ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : (
            products.map((product: ProductType) => (
              <div
                key={product._id}
                className="relative flex h-full w-full items-center
                overflow-hidden bg-white px-4 sm:px-6 md:px-6 lg:px-8
                rounded-md"
              >
                <div
                  className="grid w-full grid-cols-1 items-start gap-y-8
                    gap-x-6 sm:grid-cols-12 lg:gap-x-8"
                >
                  <div
                    className="aspect-w-2 aspect-h-3 overflow-hidden rounded-lg
                        bg-gray-100 sm:col-span-4 lg:col-span-5 cursor-pointer"
                    onClick={() => productDetailsHandler(product._id)}
                  >
                    <img
                      src={product.image}
                      alt={product.name}
                      className="object-cover object-center"
                    />
                  </div>
                  <div
                    className="xl:text-left lg:text-left md:text-left
                        sm:text-left text-center my-auto xl:mr-16 lg:mr-16
                        md:mr-16 sm:mr-16 sm:col-span-8 lg:col-span-7"
                  >
                    <p className="text-xs text-gray-400">
                      {/* {product.category} */}
                    </p>
                    <Link to={`/product/${product._id}`}>
                      <h2 className="text-xl mt-2">{product.name}</h2>
                    </Link>
                    <section
                      aria-labelledby="information-heading"
                      className="mt-2"
                    >
                      <h3 id="information-heading" className="sr-only">
                        Product information
                      </h3>
                      <p className="mt-4">{product.short_description}</p>
                      <div className="mt-4">
                        <Rating
                          rating={product.rating}
                          numReviews={product.num_reviews}
                        />
                      </div>
                      <div
                        className="flex items-center xl:justify-start mt-4
                        justify-center lg:justify-start md:justify-start
                        sm:justify-start"
                      >
                        <p className="text-sm text-primary mr-4">
                          <s>{product.price} FCFA</s>
                        </p>
                        <p className="text-lg font-bold text-secondary">
                          {product.promotional_price}
                          FCFA
                        </p>
                      </div>
                      <div
                        className="w-full bg-gray-200 rounded-full h-2 mt-9
                            dark:bg-gray-700"
                      >
                        <div
                          style={{
                            width:
                              100 -
                              (100 * product.current_stock) /
                                product.initial_stock +
                              "%",
                          }}
                          className="bg-secondary h-2 rounded-full"
                        ></div>
                      </div>
                      <div className="flex justify-between text-sm mt-4">
                        <p className="">Disponible: {product.current_stock}</p>
                        <p className="">
                          Vendu:{" "}
                          <span className="text-primary">
                            {product.initial_stock - product.current_stock}
                          </span>
                        </p>
                      </div>
                      <div
                        className="flex xl:justify-start xl:justify-start
                        lg:justify-start md:justify-start sm:justify-start
                        justify-center special-product-add-cart"
                      >
                        <div>
                          <p
                            className="mt-4 h-px w-12 bg-gray-400 price-border
                                duration-1000"
                            aria-hidden="true"
                          />
                          <AddToCart product={product} />
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            ))
          )}
        </Carousel>
      </div>
    </div>
  );
}
