import React from "react";
import { Link } from "react-router-dom";
import LanguageSwitcher from "../headerlanguageswitcher/language-switcher";

/**
 * Header info component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function HeaderInfo(): JSX.Element {
  return (
    <div
      className="flex h-10 items-center justify-end bg-oldlace
        font-normal px-4 sm:px-6 lg:px-8"
    >
      <div className="ml-4 mr-auto truncate">
        Livraison gratuite pour les commandes de plus de 50 000 FCFA.
      </div>
      <div className="flex items-center">
        <div className="ml-auto flex items-center">
          <div
            className="hidden xl:flex lg:flex flex-1 items-center
              justify-end space-x-6 text-xs"
          >
            <Link to="/about-us">À PROPOS</Link>
            <Link to="/faqs">FAQ</Link>
            <Link to="/contact-us">NOUS CONTACTER</Link>
          </div>
          <span className="h-4 w-px bg-gray-400 mx-4" aria-hidden="true"></span>
          <div className="hidden lg:flex lg:items-center w-22 text-xs">
            <LanguageSwitcher />
          </div>
        </div>
      </div>
    </div>
  );
}
