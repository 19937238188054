import React from "react";
import { Fragment } from "react";
import Banner from "../../../shared/components/banners/banner";
import Register from "../../../shared/components/others/register";

/**
 * Register page
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
function RegisterPage(): JSX.Element {
  return (
    <Fragment>
      <div className="">
        <Banner title="Créer un compte" />
      </div>
      <div
        className="flex min-h-full items-center justify-center pt-10 px-4
            sm:px-6 lg:px-8"
      >
        <div className="w-full max-w-lg space-y-4">
          <h2 className="text-xl font-bold tracking-tight text-primary">
            Créer un compte
          </h2>
          <Register />
        </div>
      </div>
    </Fragment>
  );
}

export default RegisterPage;
