import React, { useState } from "react";
import Banner from "../../../shared/components/banners/banner";
import FaqMenu from "../components/faq-menu.component";
import FaqItems from "../components/faq-items.component";

/**
 * Faq page
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-15
 *
 * @returns {JSX.Element} the jsx element
 */
export default function FaqPage(): JSX.Element {
  const [slug, setSlug] = useState("order");

  return (
    <>
      <Banner title="Faqs" />
      <div className="mt-6">
        <div
          className="grid xl:grid-cols-11 lg:grid-cols-11 md:grid-cols-11
            grid-cols-1 xl:pt-12"
        >
          <div
            className="col-span-3 border border-x-0 border-b-0
              border-t-gray-200 w-min-32"
          >
            <FaqMenu slug={(e: any) => setSlug(e)} />
          </div>
          <div
            className="col-span-8 xl:pl-12 lg:pl-12 md:pl-12 pl-0 xl:pt-0
              lg:pt-0 md:pt-0 pt-6"
          >
            <h2 className="font-bold text-primary text-3xl">
              Bienvenue, comment pouvons-nous vous aider ?
            </h2>
            <p className="text-justify text-gray-500 mt-4 mb-6 leading-7">
              Nous garantissons que vos informations, y compris les informations
              sensibles que vous soumettez, sont protégées à la fois en ligne et
              hors ligne. Lorsque vous effectuez un paiement, la page est
              sécurisée, cryptée et protégée par le meilleur logiciel de
              cryptage de l&apos;industrie - SSL.
            </p>
            <FaqItems slug={slug} />
          </div>
        </div>
      </div>
    </>
  );
}
