import React from "react";
import { useNavigate } from "react-router-dom";

/**
 * Shop by category component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function HomeShopByCategory(): JSX.Element {
  const navigate = useNavigate();

  /**
   * Redirect to shop page
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @return {void}
   */
  const shopPage = (): void => {
    navigate(`/shop`);
  };

  return (
    <div
      className="grid gap-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2
            sm:grid-cols-2 grid-cols-1 mx-5"
    >
      <div className="col-span-1 hidden xl:block lg:block">
        <div className="mb-6 flex">
          <img
            src="/assets/images/categories/category-5.jpg"
            alt="category 3"
            className="w-full"
          />
          <div
            className="absolute text-white ml-12 mt-5 xl:mt-10
                        lg:mt-10 md:mt-10 sm:mt-5"
          >
            <p className="">Tous les types de pages</p>
            <p className="text-2xl font-bold mt-2">Carnets de notes</p>
            <button
              className="border-2 border-white rounded-full
                                text-white bg-transparent mt-5 xl:mt-10 lg:mt-5
                                md:mt-10 sm:mt-5 py-2 px-2 flex justify-start
                                items-end hover:text-primary
                                hover:border-primary"
              onClick={shopPage}
            >
              Acheter Maintenant
              <span className="ml-2" aria-hidden="true">
                &rarr;
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="col-span-1">
        <div className="mb-6 flex">
          <img
            src="/assets/images/categories/category-2.jpg"
            alt="category 3"
            className="w-full"
          />
          <div
            className="absolute text-white ml-12 mt-5 xl:mt-10
                        lg:mt-10 md:mt-10 sm:mt-5"
          >
            <p className="">Bureau / École</p>
            <p className="text-2xl font-bold mt-2">Stylos en métal</p>
            <button
              className="border-2 border-white rounded-full
                                text-white bg-transparent mt-5 xl:mt-10 lg:mt-5
                                md:mt-10 sm:mt-5 py-2 px-2 flex justify-start
                                items-end hover:text-primary
                                hover:border-primary"
              onClick={shopPage}
            >
              Acheter Maintenant
              <span className="ml-2" aria-hidden="true">
                &rarr;
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="col-span-1">
        <div className="flex">
          <img
            src="/assets/images/categories/category-3.jpg"
            alt="category 4"
            className="w-full"
          />
          <div
            className="absolute text-white ml-12 mt-5 xl:mt-10
                        lg:mt-10 md:mt-10 sm:mt-5"
          >
            <p className="">Adhésif de bureau</p>
            <p className="text-2xl font-bold mt-2">Ruban adhésif</p>
            <button
              className="border-2 border-white rounded-full
                                text-white bg-transparent mt-5 xl:mt-10
                                lg:mt-5 md:mt-10 sm:mt-5 py-2 px-2 flex
                                justify-start items-end hover:text-primary
                                hover:border-primary"
              onClick={shopPage}
            >
              Acheter Maintenant
              <span className="ml-2" aria-hidden="true">
                &rarr;
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
