import React, { useEffect, useState } from "react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import MessageBox from "../../../../shared/components/others/message-box";
import StripeWrapper from "../../../../shared/components/paymentmethods/stripe/stripe-wrapper";
import AddPayMethod from "../../../../shared/components/paymentmethods/stripe/add-payment-method";
import systemService from "../../../../shared/api/pament-service/system/system.service";
import paymentMethodService from "../../../../shared/api/pament-service/payment-method/payment-method.service";
import PaymentMethodType from "../../../../shared/api/pament-service/payment-method/payment-method.type";
import LoadingBox from "../../../../shared/components/others/loading-box";

/**
 *  Payment methods
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-08-09
 * @param {PaymentMethodProps} props the shared properties
 * @returns {JSX.Element} the javaScriptXML element
 */
export default function PaymentMethod(props: PaymentMethodProps): JSX.Element {
  const { triggerFormValidation, checkoutFormData, country } = props;
  const [paymentMethods, setPaymentMethods] = useState<
    Array<PaymentMethodType>
  >([]);
  const [paymentMethodsIsLoading, setPaymentMethodsIsLoading] = useState(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethodType>();
  const [stripeCartInfo, setStripeCartInfo] = useState<any>(null);
  const [stripeCartInfoVisibility, setStripeCartInfoVisibility] = useState("");
  const [paymentMethodIsLoading, setPaymentMethodIsLoading] = useState(false);
  const [invalidPaymentForm, setInvalidPaymentForm] = useState(false);

  /**
   * Set stripe cart information
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} data the stripe cart information
   * @returns {any} of handle stripr card info
   */
  const handleStriprCardInfo = (data: any): any => {
    setStripeCartInfo(data);
    // console.log(stripeCartInfo);
  };

  /**
   * Submit the form
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-25
   *
   * @returns {void}
   */
  const handleSubmit = (): void => {
    const paymentMethodData = {
      paymentMethod: selectedPaymentMethod?._id,
      paymentDescription: "Buy product",
      paymentData: stripeCartInfo,
    };

    props.checkoutPaymentMethodData(paymentMethodData);
  };

  // Get all payment methods
  useEffect(() => {
    if (country && !paymentMethods.length) {
      systemService.getSystemByName("Kitecole website").subscribe({
        next: (data) => {
          if (data) {
            // Start loading
            setPaymentMethodsIsLoading(true);

            paymentMethodService.getPaymentMethodsBySystem(data._id).subscribe({
              next: (data) => {
                // Stop loader
                setPaymentMethodsIsLoading(false);

                if (data.length)
                  setSelectedPaymentMethod(data.find(() => true));

                setPaymentMethods(data);

                // Stop loader
                setPaymentMethodsIsLoading(false);
              },
              error: () => {
                // Stop loader
                setPaymentMethodsIsLoading(false);
              },
            });
          }
        },
        error: () => {
          // setCountryError(e);
        },
      });
    }
  }, [country]);

  useEffect(() => {
    console.log(
      selectedPaymentMethod?.name,
      stripeCartInfo,
      triggerFormValidation
    );
    if (selectedPaymentMethod?.name === "Stripe") {
      props.paymentMethodIsLoading(paymentMethodIsLoading);
    }
    if (
      (selectedPaymentMethod?.name === "Stripe" &&
        stripeCartInfo &&
        triggerFormValidation) ||
      (selectedPaymentMethod?.name !== "Stripe" && triggerFormValidation)
    )
      handleSubmit();
    else props.checkoutPaymentMethodData(null);
  }, [triggerFormValidation, selectedPaymentMethod, stripeCartInfo]);

  useEffect(() => {
    props.paymentMethodIsLoading(paymentMethodIsLoading);
  }, [paymentMethodIsLoading]);

  useEffect(() => {
    props.invalidPaymentForm(invalidPaymentForm);
  }, [invalidPaymentForm]);

  return (
    <div className="mt-6 mb-4">
      {paymentMethodsIsLoading ? (
        <LoadingBox />
      ) : paymentMethods.length ? (
        paymentMethods.map((element: any) => (
          <div key={element._id} className="border-b border-gray-200">
            <div className="w-full h-full flex items-center my-3">
              <input
                id={element._id}
                name="paymentMethod"
                type="radio"
                value={element._id}
                checked={selectedPaymentMethod?.name === element.name}
                className="h-4 w-4 rounded-50 border-gray-300 mb-1
                  text-secondary focus:ring-0 focus:ring-transparent"
                onChange={(e) => {
                  setSelectedPaymentMethod(element);
                }}
              />
              <label
                htmlFor={element._id}
                className="w-full capitalize ml-2 block text-gray-900"
              >
                {element.name}
              </label>
            </div>
            {element.name === selectedPaymentMethod?.name && (
              <div className="text-xs text-muted leading-6">
                <div className="text-justify p-2 payment-method-description">
                  <div
                    dangerouslySetInnerHTML={{ __html: element.description }}
                  ></div>
                </div>
                {selectedPaymentMethod?.name === "Stripe" &&
                  (stripeCartInfo && stripeCartInfoVisibility === "visible" ? (
                    <div
                      className="flex w-full items-center px-4 pb-2
                          rounded-md shadow border border-gray-200"
                    >
                      <img
                        className="w-6 mr-2"
                        src={`/assets/svg/payment_card/struck/${stripeCartInfo.card.brand}.svg`}
                      />
                      <div
                        className="flex w-full justify-between
                            items-center"
                      >
                        <p className="capitalize mt-1">
                          {stripeCartInfo.card.brand} -{" "}
                          {stripeCartInfo.card.last4}
                        </p>
                        <span data-title="Supprimer">
                          <XCircleIcon
                            className="h-4 w-6 text-red-500
                                cursor-pointer"
                            onClick={() =>
                              setStripeCartInfoVisibility("hidden")
                            }
                          />
                        </span>
                      </div>
                    </div>
                  ) : (
                    <StripeWrapper>
                      <AddPayMethod
                        onChangeStripeCardInfo={handleStriprCardInfo}
                        paymentMethodIsLoading={(e: any) =>
                          setPaymentMethodIsLoading(e)
                        }
                        invalidPaymentForm={(e: any) =>
                          setInvalidPaymentForm(e)
                        }
                        triggerFormValidation={triggerFormValidation}
                        checkoutFormData={checkoutFormData}
                      />
                    </StripeWrapper>
                  ))}
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="mt-6">
          <MessageBox variant="info">
            Désolé, il semble qu&apos;il n&apos;y ait pas de méthode de paiement
            disponible pour votre état. Veuillez nous contacter si vous avez
            besoin d&apos;aide ou si vous souhaitez prendre d&apos;autres
            dispositions.
          </MessageBox>
        </div>
      )}
    </div>
  );
}

type PaymentMethodProps = {
  triggerFormValidation: boolean;
  checkoutPaymentMethodData: any;
  paymentMethodIsLoading: any;
  invalidPaymentForm: any;
  checkoutFormData: any;
  country: any;
};
