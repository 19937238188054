import React, { Fragment, useEffect, useState } from "react";
import useLocalStorage from "../../../shared/hooks/use-localstorage";
import UserType, {
  GenderType,
} from "../../../shared/api/user-service/user/user.type";
import LoadingBox from "../../../shared/components/others/loading-box";
import MessageBox from "../../../shared/components/others/message-box";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { Listbox, Transition } from "@headlessui/react";
import { classNames } from "../../../shared/utils/helper.util";
import { useValidation } from "react-simple-form-validator";
import { toast } from "react-toastify";
import userService from "../../../shared/api/user-service/user/user.service";

/**
 * Profile component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-07
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function Profile(): JSX.Element {
  const [userInfo] = useLocalStorage("userInfo", null);
  const [user, setUser] = useState<UserType>();
  const [genders, setGenders] = useState<GenderType[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState(userInfo && userInfo.email);
  const [gender, setGender] = useState(userInfo && userInfo.gender);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const { isFieldInError, getErrorMessages, isFormValid } = useValidation({
    fieldsRules: {
      username: { required: true },
      email: { required: true, email: true },
      currentPassword: {
        required: newPassword || confirmNewPassword ? true : false,
      },
      newPassword: {
        required: currentPassword || confirmNewPassword ? true : false,
        minlength: 6,
      },
      confirmNewPassword: {
        required: currentPassword || newPassword ? true : false,
        equalPassword: newPassword,
        minlength: 6,
      },
    },
    state: {
      lastName,
      firstName,
      username: userInfo && userInfo.username,
      email,
      gender,
      currentPassword,
      newPassword,
      confirmNewPassword,
    },
    locale: "en",
  });

  const navigate = useNavigate();
  const [search] = useSearchParams();
  const redirect =
    search.get("redirect") && search.get("redirect") != "/"
      ? `/${search.get("redirect")}`
      : "/";

  /**
   * Submit update form
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-07
   *
   * @param {any} e the react mouse event
   * @returns {void}
   */
  const submitHandler = (e: any): void => {
    e.preventDefault();

    if (isFormValid) {
      // Start loader
      setLoadingUpdate(true);

      userService
        .updateUser({
          _id: userInfo._id,
          first_name: firstName,
          last_name: lastName,
          username,
          email,
          current_password: currentPassword,
          new_password: newPassword,
          gender: gender?._id,
        })
        .subscribe({
          next: () => {
            // Stop loader
            setLoadingUpdate(false);

            toast.success("Profile Updated Successfully", {
              theme: "colored",
            });
          },
          error: (e) => {
            // Stop loader
            setLoadingUpdate(false);

            toast.error(e, {
              theme: "colored",
            });
          },
        });
    }
  };

  useEffect(() => {
    if (!user && userInfo) {
      // Start loader
      setIsLoading(true);

      userService.profile(userInfo._id).subscribe({
        next: (data) => {
          // Stop loading
          setIsLoading(false);

          setUser(data);
        },
        error: (e) => {
          console.log(e);
          // Stop loading
          setIsLoading(false);

          setError(e);
        },
      });
    } else if (user) {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setUsername(user.username);
      setEmail(user.email);
    } else {
      navigate(`/signin?redirect=${redirect}`);
    }
  }, [user]);

  useEffect(() => {
    userService.getAllGenders().subscribe({
      next: (data) => {
        setGenders(data);
      },
      error: (e) => {
        setError(e);
      },
    });
  }, []);

  return (
    <div>
      <form onSubmit={submitHandler}>
        {!isFormValid ? (
          <div className="mb-4">
            <MessageBox variant="danger">
              {getErrorMessages("\n")
                .split("\n")
                .map((s) => (
                  <span key={s}>
                    {s}
                    <br />
                  </span>
                ))}
            </MessageBox>
          </div>
        ) : null}
        {isLoading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <div className="mb-4">
            <MessageBox variant="danger">{error}</MessageBox>
          </div>
        ) : (
          <>
            <div className="rounded-md shadow-sm mb-4">
              <div
                className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2
                grid-cols-1 gap-4 mb-4"
              >
                <div className="col-span-1">
                  <label htmlFor="firstName" className="text-gray-900">
                    First name
                  </label>
                  <input
                    id="firstName"
                    type="text"
                    className="relative block w-full appearance-none rounded-md
                      border border-gray-300 px-3 py-2 text-gray-900
                      placeholder-gray-500 focus:z-10 focus:border-secondary
                      focus:outline-none focus:ring-0 focus:ring-secondary"
                    placeholder="Enter first name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  ></input>
                </div>
                <div className="col-span-1">
                  <label htmlFor="lastName" className="text-gray-900">
                    Last name
                  </label>
                  <input
                    id="lastName"
                    type="text"
                    className="relative block w-full appearance-none rounded-md
                      border border-gray-300 px-3 py-2 text-gray-900
                      placeholder-gray-500 focus:z-10 focus:border-secondary
                      focus:outline-none focus:ring-0 focus:ring-secondary"
                    placeholder="Enter last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  ></input>
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="username" className="text-gray-900">
                  User name
                  <span className="text-red-500">*</span>
                </label>
                <input
                  id="username"
                  type="text"
                  className={classNames(
                    isFieldInError("username")
                      ? `border-red-500 focus:border-red-500 focus:outline-none
                      focus:ring-0 focus:ring-secondary`
                      : `border-gray-300 focus:border-secondary
                        focus:outline-none focus:ring-0 focus:ring-secondary`,
                    `relative block w-full appearance-none rounded-md border
                      px-3 py-2 text-gray-900 placeholder-gray-500`
                  )}
                  placeholder="Enter username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                ></input>
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="text-gray-900">
                  Email
                  <span className="text-red-500">*</span>
                </label>
                <input
                  id="email"
                  type="email"
                  className={classNames(
                    isFieldInError("email")
                      ? `border-red-500 focus:border-red-500 focus:outline-none
                      focus:ring-0 focus:ring-secondary`
                      : `border-gray-300 focus:border-secondary
                        focus:outline-none focus:ring-0 focus:ring-secondary`,
                    `relative block w-full appearance-none rounded-md border
                      px-3 py-2 text-gray-900 placeholder-gray-500`
                  )}
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
              </div>
              <div className="mb-4">
                <label htmlFor="gender" className="text-gray-900">
                  Gender
                </label>
                <Listbox value={gender} onChange={setGender}>
                  {({ open }) => (
                    <>
                      <div className="relative mt-1 w-full h-12">
                        <Listbox.Button
                          className="relative w-full pr-10 py-2
                          cursor-default rounded-md border border-gray-300 pl-3
                          bg-white text-left shadow-sm focus:ring-0 focus:ring-0
                          focus:outline-none sm:text-sm focus:border-gray-300
                          h-11"
                        >
                          <span className="flex items-center">
                            <span className="block truncate">
                              {gender?.name}
                            </span>
                          </span>
                          <span
                            className="pointer-events-none absolute right-0
                            inset-y-0 ml-3 flex items-center pr-2"
                          >
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options
                            className="absolute z-20 mt-1 ring-1
                            max-h-56 w-full overflow-auto rounded-md bg-white
                            py-1 text-base shadow-lg ring-black ring-opacity-5
                            focus:outline-none sm:text-sm"
                          >
                            {genders?.map((gender: GenderType) => (
                              <Listbox.Option
                                key={gender._id}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "text-white bg-secondary"
                                      : "text-gray-900",
                                    `relative cursor-default select-none py-2
                                      pl-3 pr-9`
                                  )
                                }
                                value={gender}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <div className="flex items-center">
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                        onClick={() => {
                                          // navigate(
                                          //   getFilterUrl({
                                          //     order: filter.value,
                                          //     page: 1,
                                          //   })
                                          // );
                                        }}
                                      >
                                        {gender.name}
                                      </span>
                                    </div>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-secondary",
                                          `absolute inset-y-0 right-0 flex
                                              items-center pr-4`
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>
              <fieldset className="border border-gray-200 rounded p-6">
                <legend className="text-primary">
                  Changement de mot de passe
                </legend>
                <div className="mb-4">
                  <label htmlFor="currentPassword" className="text-gray-900">
                    Current password (leave empty for safekeeping)
                  </label>
                  <input
                    id="currentPassword"
                    type="password"
                    className={classNames(
                      isFieldInError("currentPassword")
                        ? `border-red-500 focus:border-red-500 focus:ring-0
                          focus:outline-none focus:ring-secondary`
                        : `border-gray-300 focus:border-secondary
                          focus:outline-none focus:ring-0 focus:ring-secondary`,
                      `relative block w-full appearance-none rounded-md border
                        px-3 py-2 text-gray-900 placeholder-gray-500`
                    )}
                    placeholder="Enter current password"
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    value={currentPassword}
                  ></input>
                </div>
                <div className="mb-4">
                  <label htmlFor="newPassword" className="text-gray-900">
                    New password
                  </label>
                  <input
                    id="newPassword"
                    type="password"
                    className={classNames(
                      isFieldInError("newPassword")
                        ? `border-red-500 focus:border-red-500 focus:ring-0
                          focus:outline-none focus:ring-secondary`
                        : `border-gray-300 focus:border-secondary
                        focus:outline-none focus:ring-0 focus:ring-secondary`,
                      `relative block w-full appearance-none rounded-md border
                      px-3 py-2 text-gray-900 placeholder-gray-500`
                    )}
                    placeholder="Enter new password"
                    onChange={(e) => setNewPassword(e.target.value)}
                    value={newPassword}
                  ></input>
                </div>
                <div className="mb-4">
                  <label htmlFor="confirmNewPassword" className="text-gray-900">
                    Confirm new password
                  </label>
                  <input
                    id="confirmNewPassword"
                    type="password"
                    className={classNames(
                      isFieldInError("confirmNewPassword")
                        ? `border-red-500 focus:border-red-500 focus:ring-0
                          focus:outline-none focus:ring-secondary`
                        : `border-gray-300 focus:border-secondary
                          focus:outline-none focus:ring-0 focus:ring-secondary`,
                      `relative block w-full appearance-none rounded-md border
                        px-3 py-2 text-gray-900 placeholder-gray-500`
                    )}
                    placeholder="Enter confirm password"
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    value={confirmNewPassword}
                  ></input>
                </div>
              </fieldset>
            </div>
            <div>
              <button
                type="submit"
                className={classNames(
                  loadingUpdate
                    ? "bg-gray-400 text-gray-400 hover:bg-gray-400"
                    : `bg-secondary hover:bg-ternary focus:outline-none
                    focus:ring-primary text-white`,
                  `group flex justify-center rounded border
                  border-transparent py-2 px-4 font-medium focus:ring-0 relative
                  focus:ring-offset-2`
                )}
                disabled={loadingUpdate || !isFormValid}
              >
                {loadingUpdate ? (
                  <div className="absolute text-black">
                    <LoadingBox />
                  </div>
                ) : null}
                Enregistrer les modifications
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
}
