import React from "react";
import HeaderGroupActions from "../../headergroupactions/header-group-actions";
import HeaderSearch from "../../headergroupactions/header-search";
import HeaderLanguage from "../../headergroupactions/header-langauge";
import HeaderMenu from "../../headermenu/header-horizontal-menu";
import HeaderLogo from "../../headerlogo/header-logo";

/**
 * Full sticky header component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function FullStickyHeader(): JSX.Element {
  return (
    <div
      className="bg-white shadow hidden lg:block xl:block border-b
        border-gray-200"
    >
      <header className="relative bg-white">
        <div
          className="flex py-2 items-center grid grid-cols-12 gap-2 px-4
            sm:px-6 lg:px-8"
        >
          <div className="col-span-2">
            <HeaderLogo />
          </div>
          <div className="col-span-6 w-full flex items-center">
            <nav aria-label="Top">
              <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center">
                  {/* Menus */}
                  <HeaderMenu textColor="text-black" />
                </div>
              </div>
            </nav>
          </div>
          <div className="col-span-4 flex justify-end items-center">
            <span className="mr-4">
              <HeaderLanguage />
            </span>
            <span className="mr-4">
              <HeaderSearch />
            </span>
            <HeaderGroupActions />
          </div>
        </div>
      </header>
    </div>
  );
}
