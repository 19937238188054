import { Observable, defer, map } from "rxjs";
import { AxiosResponse } from "axios";
import axiosInstance from "../../../utils/http-client.util";

/**
 * Product service
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * class ProductService
 */
class ProductService {
  public lang = "en";

  /**
   * Get all products
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-30
   *
   * @param {any} productId the product's id
   * @returns {Promise<void>} the eventual completion or failure
   */
  public showAll({
    page = "",
    seller = "",
    name = "",
    category = "",
    tag = "",
    order = "",
    min = 0,
    max = 0,
    rating = 0,
    featured = "",
    promotional = "",
  }): Observable<any> {
    const url = `/v1/${this.lang}/products?page=${page}&seller=${seller}
        &name=${name}&category=${category}&tag=${tag}&min=${min}&max=${max}
        &rating=${rating}&order=${order}&featured=${featured}
        &promotional=${promotional}`;

    return defer(() => axiosInstance.get(url.replace(/\s/g, ""))).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Get product by id
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-02
   *
   * @param {any} productId the product's id
   * @returns {Observable<any>} the eventual completion or failure
   */
  public getById(productId: any): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/product/${productId}`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Create review
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-02
   *
   * @param {any} productId the product's id
   * @param {any} review the review data
   * @returns {Observable<any>} the eventual completion or failure
   */
  public createReview(productId: any, review: any): Observable<any> {
    return defer(() =>
      axiosInstance.post(
        `/v1/${this.lang}/product/${productId}/reviews`,
        review
      )
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }
}

const productService = new ProductService();
export default productService;
