import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import LoadingBox from "../others/loading-box";
import ProductType from "../../api/product-service/product/product.type";
import compareService from "../../api/product-service/compare/compare.service";

/**
 * Add to compare component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @param {AddToCompareProps} props the shared properties
 * @returns {JSX.Element} the javascript xml element
 */
export default function AddToCompare(props: AddToCompareProps): JSX.Element {
  const product = props.product;
  const [open, setOpen] = useState(false);
  const [compareItems, setCompareItems] = useState<any>(
    localStorage.getItem("compareItems")
      ? JSON.parse(localStorage.getItem("compareItems") as any)
      : []
  );
  const [compareAddState, setCompareAddState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Add to compare
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {string} id the product's id
   * @returns {void}
   */
  const addToCompareHandler = (id: string): void => {
    // Start loader
    setIsLoading(true);

    if (compareItems.some((prod: ProductType) => prod.name == product.name)) {
      compareService.removeFromCompare(id).subscribe({
        next: (res) => {
          setCompareItems(res);

          // Open Dialog Panel
          setOpen(true);

          // Stop loader
          setIsLoading(false);

          setCompareAddState(1);
        },
        error: () => {
          setIsLoading(false);
          // setError(e);
        },
      });
    } else {
      compareService.addToCompare(id).subscribe({
        next: (res) => {
          setCompareItems(res);

          // Open Dialog Panel
          setOpen(true);

          // Stop loader
          setIsLoading(false);

          setCompareAddState(0);
        },
        error: () => {
          setIsLoading(false);
          // setError(e);
        },
      });
    }
  };

  return (
    <Fragment>
      <div
        className="flex -space-x-2 justify-center"
        onClick={() => addToCompareHandler(product._id)}
      >
        {isLoading ? (
          <div
            className="flex items-center justify-center inline-block
                bg-white h-10 w-10 border border-gray-200 shadow transition
                hover:border-secondary hover:bg-secondary hover:text-white
                rounded-full pt-1 cursor-pointer duration-500"
          >
            <LoadingBox />
          </div>
        ) : (
          <i
            className="fa fa-random inline-block text-xs bg-white h-10 w-10
                border border-gray-200 shadow transition hover:border-secondary
                hover:bg-secondary hover:text-white rounded-full p-3
                cursor-pointer duration-500"
            aria-hidden="true"
          ></i>
        )}
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 hidden bg-black bg-opacity-75
                transition-opacity md:block"
            />
          </Transition.Child>

          <div className="fixed inset-0 z-30 overflow-y-auto">
            <div
              className="flex min-h-full items-stretch justify-center
                text-center md:items-center md:px-2 lg:px-4"
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                enterTo="opacity-100 translate-y-0 md:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 md:scale-100"
                leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              >
                <Dialog.Panel
                  className="flex w-full transform text-left
                    text-base transition md:max-w-2xl lg:max-w-2xl"
                >
                  <div
                    className="relative flex w-full items-center
                    overflow-hidden bg-white shadow-2xl sm:px-4 sm:pt-4 md:p-4
                    lg:p-4"
                  >
                    <button
                      type="button"
                      className="absolute top-4 right-4 text-gray-400
                        hover:text-gray-500 sm:right-6 md:right-6 lg:right-8"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    <div className="flex justify-center w-full pr-6">
                      <p>
                        {compareAddState ? (
                          <span className="text-primary">
                            Le produit a été retiré de la liste comparer.
                          </span>
                        ) : (
                          <span className="text-primary">
                            Le produit a été ajouté à la liste comparer.
                          </span>
                        )}
                        <Link to="#" className="ml-1">
                          Voir la liste comparer
                        </Link>
                        .
                      </p>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
}

type AddToCompareProps = {
  product: ProductType;
};
