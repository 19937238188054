import React from "react";
import { Route, Routes } from "react-router-dom";
import CartPage from "../pages/cart.page";

/**
 * Cart routes
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-27
 *
 * @returns {JSX.Element} the jsx element
 */
export default function CartRoute(): JSX.Element {
  return (
    <Routes>
      <Route path="/cart" element={<CartPage />} />
    </Routes>
  );
}
