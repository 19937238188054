import React from "react";
import { Route, Routes } from "react-router-dom";
import SchoolProgramPage from "../pages/school-program.page";

/**
 * Blog route
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-06
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function SchoolProgramRoute(): JSX.Element {
  return (
    <Routes>
      <Route path="/school-program" element={<SchoolProgramPage />}>
      </Route>
    </Routes>
  );
}
