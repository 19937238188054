import React from "react";
import { Route, Routes } from "react-router-dom";
import ContactPage from "../pages/contact.page";

/**
 * Contact us route
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-10
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function ContactRoute(): JSX.Element {
  return (
    <Routes>
      <Route path="/contact-us" element={<ContactPage />}></Route>
    </Routes>
  );
}
