import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles/home.style.scss";
import HomeSlider from "../components/home-slider.component";
import HomeInfo from "../components/home-info.component";
import HomeShopByCategory from "../components/home-shop-by-category.component";
import HomeTabProduct from "../components/home-tab-product.component";
import HomeFromBlog from "../components/home-from-blog.component";
import HomeSpecialProduct from "../components/home.special-product.component";

/**
 * Home page
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function HomePage(): JSX.Element {
  return (
    <div>
      {/* Home slider */}
      <div className="-mx-8 -mt-8">
        <HomeSlider />
      </div>

      {/* Home info */}
      <div className="-mx-8 -mt-4 mb-8">
        <HomeInfo />
      </div>
      {/* Shop By Category */}
      <div className="mt-11 mb-4">
        <HomeShopByCategory />
      </div>
      {/* Featured, On Sell, Best rate Products */}
      <div>
        <HomeTabProduct />
      </div>
      {/* Special Products */}
      <div className="mt-11 -mx-8">
        <HomeSpecialProduct />
      </div>
      {/* From our blog */}
      <div>
        <HomeFromBlog />
      </div>
    </div>
  );
}
