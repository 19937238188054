import { Transition } from "@headlessui/react";
import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";

/**
 * Home Slider component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function HomeSlider(): JSX.Element {
  // const [firstText, setFirstText] = useState(true);
  const [secondText, setsecondText] = useState(true);
  const [thirdText, setThirdText] = useState(true);
  const navigate = useNavigate();

  /**
   * Redirect to shop page
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @return {void}
   */
  const shopPage = () => {
    navigate(`/shop`);
  };

  /**
   * Redirect to shop page
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} e the react mouse event
   * @return {void}
   */
  const slideChange = (e: any): void => {
    if (e == 0) {
      //   setFirstText(true);

      setTimeout(() => {
        setsecondText(true);
      }, 500);

      setTimeout(() => {
        setThirdText(true);
      }, 1500);
    } else {
      //   setFirstText(false);
      setTimeout(() => {
        setsecondText(false);
      }, 500);

      setTimeout(() => {
        setThirdText(false);
      }, 1500);
    }
  };

  return (
    <Carousel
      autoPlay={true}
      infiniteLoop={true}
      useKeyboardArrows={true}
      stopOnHover={true}
      swipeable={true}
      showArrows={false}
      showThumbs={false}
      showStatus={false}
      preventMovementUntilSwipeScrollTolerance={true}
      transitionTime={400}
      // animationHandler="fade"
      interval={10000}
      onChange={(e) => slideChange(e)}
      className="h-98 -mt-4 mb-4">
      <div className="home-slider h-full">
        <img src="/assets/images/slides/slider3.jpg" className="h-full object-cover"/>
        <div className="absolute top-0 h-full w-full flex items-center">
          <div className="text-justify">
            {/* <Transition> */}
            {/* <div className='h-6 flex items-start'>
                    <Transition
                    appear={true}
                    show={firstText}
                    enter="transition ease-out duration-1000 transform"
                    enterFrom="translate-x-0"
                    enterTo="translate-x-32"
                    >
                    <div className='text-lg font-bold'>
                        STOCK UP YOUR DESK
                    </div>
                    </Transition>
                    </div> */}
            <div className="xl:h-48 lg:h-48 md:h-36 sm:h-32 h-32 flex items-start">
              <Transition
                appear={true}
                show={secondText}
                enter="transition ease-out duration-700 transform"
                enterFrom="translate-x-0 skew-x-12"
                enterTo={`xl:translate-x-32 lg:translate-x-32 md:translate-x-20 sm:translate-x-16 translate-x-10`}>
                <div className="greatest-richmond-font xl:text-6xl lg:text-6xl md:text-4xl sm:text-3xl text-3xl mt-4
                    text-black xl:leading-15 lg:leading-15 md:leading-11 sm:leading-11 xs:leading-15">
                  Trouvez
                  <span className="text-primary">
                    Tout Ce
                    <br />
                    Dont
                  </span>
                  Votre Bureau
                  <br />A Besoin
                </div>
              </Transition>
            </div>
            <div className="h-24 flex items-start">
              <Transition
                appear={true}
                show={thirdText}
                enter="transition ease-out duration-700 transform"
                enterFrom="translate-x-0 skew-x-12"
                enterTo={`xl:translate-x-32 lg:translate-x-32 md:translate-x-20 sm:translate-x-16 translate-x-10`} >
                <div className="text-lg mt-10">
                  <button
                    className="rounded-full text-white bg-primary py-4 px-6 flex justify-start items-end
                    hover:bg-secondary hover:text-white text-xs"
                    onClick={shopPage} >
                    Acheter Maintenant
                    <span className="ml-2" aria-hidden="true">
                      &rarr;
                    </span>
                  </button>
                </div>
              </Transition>
            </div>
            {/* </Transition> */}
          </div>
        </div>
      </div>
      <div className="home-slider h-full">
        <img src="/assets/images/slides/slider8.jpg" className="h-full" />
        {/* <div className="absolute top-0 h-full w-full
                flex items-center">
            <div className='text-justify ml-32 text-black'>
            <div className='text-lg font-bold'>STOCK UP YOUR DESK</div>
            <div className='text-5xl font-bold mt-4'>With
                <span className='text-primary'>Our 3 For 2 </span><br/>
                Stationary</div>
            <div className='mt-10'>
                <button className='rounded-full text-white bg-primary
                    border border-primary py-2 px-4 flex justify-start
                    items-end hover:bg-primary hover:text-white'>
                SHOP NOW
                <span className='ml-2' aria-hidden="true"> &rarr;</span>
                </button>
                </div>
            </div>
            </div> */}
        <div className="absolute top-0 h-full w-full flex items-center">
          <div className="text-justify">
            {/* <Transition> */}
            {/* <div className='h-6 flex items-start'>
                <Transition
                appear={true}
                show={firstText}
                enter="transition ease-out duration-1000 transform"
                enterFrom="translate-x-0"
                enterTo="translate-x-32"
                >
                <div className='text-lg font-bold'>
                    STOCK UP YOUR DESK
                </div>
                </Transition>
                </div> */}
            <div className="xl:h-48 lg:h-48 md:h-36 sm:h-32 h-26 flex items-start">
              <Transition
                appear={true}
                show={!secondText}
                enter="transition ease-out duration-700 transform"
                enterFrom="translate-x-0 skew-x-12"
                enterTo={`xl:translate-x-56 lg:translate-x-56 md:translate-x-42 sm:translate-x-32 translate-x-10`} >
                <div
                  className="greatest-richmond-font xl:text-6xl lg:text-6xl md:text-4xl sm:text-3xl text-3xl mt-4
                    text-black xl:leading-15 lg:leading-15 md:leading-11 sm:leading-11 xs:leading-15">
                  Préparer
                  <span className="text-primary">
                    La
                    <br />
                    Rentrée Scolaire
                  </span>
                  Des <br />
                  Vos Enfants Avec Kitecole
                </div>
              </Transition>
            </div>
            <div className="h-24 flex items-start">
              <Transition
                appear={true}
                show={!thirdText}
                enter="transition ease-out duration-700 transform"
                enterFrom="translate-x-0 skew-x-12"
                enterTo={`xl:translate-x-56 lg:translate-x-56 md:translate-x-42 sm:translate-x-32 translate-x-10`} >
                <div className="text-lg mt-10">
                  <button
                    className="rounded-full text-white bg-primary py-4 px-6 flex justify-start items-end text-xs
                      hover:bg-secondary hover:text-white"
                    onClick={shopPage} >
                    Acheter Maintenant
                    <span className="ml-2" aria-hidden="true">
                      &rarr;
                    </span>
                  </button>
                </div>
              </Transition>
            </div>
            {/* </Transition> */}
          </div>
        </div>
        {/* <p className="legend">Legend 2</p> */}
      </div>
      {/* <div> */}
      {/* <img src="assets/images/slides/slide_3.png" /> */}
      {/* <p className="legend">Legend 3</p> */}
      {/* </div> */}
    </Carousel>
  );
}
