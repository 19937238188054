import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import {
  AdjustmentsHorizontalIcon,
  CheckIcon,
  ChevronUpDownIcon,
  ListBulletIcon,
  Squares2X2Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronDoubleLeft, ChevronDoubleRight } from "heroicons-react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import "../styles/shop.style.scss";
import Banner from "../../../shared/components/banners/banner";
import ProductFilter from "../components/product-filter.component";
import LoadingBox from "../../../shared/components/others/loading-box";
import MessageBox from "../../../shared/components/others/message-box";
import ProductItems from "../components/product-items.component";
import { classNames } from "../../../shared/utils/helper.util";
import productService from "../../../shared/api/product-service/product/product.service";
import ProductType from "../../../shared/api/product-service/product/product.type";

const inputFilter = [
  {
    id: 1,
    title: "Les plus récents",
    value: "newest",
  },
  {
    id: 2,
    title: "Prix : Du plus bas au plus élevé",
    value: "lowest",
  },
  {
    id: 3,
    title: "Prix : Du plus élevé au plus bas",
    value: "highest",
  },
  {
    id: 4,
    title: "Le plus populaire",
    value: "toprated",
  },
];

/**
 * Shop page
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function ShopPage(): JSX.Element {
  const [open, setOpen] = useState(false);
  const [stateCategory, setStateCategory] = useState("all");
  const [stateMin, setStateMin] = useState(-1);
  const [stateMax, setStateMax] = useState(-1);
  const [stateTag, setStateTag] = useState("all");
  const [selected, setSelected] = useState(inputFilter[0]);
  const [productIsLoading, setProductIsLoading] = useState(true);
  const [products, setProducts] = useState<ProductType[]>([]);
  const [productError, setProductError] = useState();
  const [pages, sePages] = useState(0);
  const [previousPage, setPreviousPage] = useState(null);
  const [perPage, setPerPager] = useState(0);
  const [allProducts, setAllProducts] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [nextPage, setNextPage] = useState();
  const navigate = useNavigate();
  const { category, tag, page } = useParams();
  const [search] = useSearchParams();
  const name = search.get("name") || "";
  const min = search.get("min") as any;
  const max = search.get("max") as any;
  const rating = search.get("rating") as any;
  const order = search.get("order") || "";
  const layout = search.get("layout") || "grid";

  useEffect(() => {
    productService
      .showAll({
        page,
        name,
        category,
        tag,
        min,
        max,
        rating,
        order,
      })
      .subscribe({
        next: (data) => {
          // Stop loading
          setProductIsLoading(false);

          setProducts(data.products);
          sePages(data.pages);
          setPreviousPage(data.previousPage);
          setPerPager(data.perPage);
          setAllProducts(data.allProducts);
          setCurrentPage(data.currentPage);
          setNextPage(data.nextPage);
        },
        error: (e) => {
          setProductIsLoading(false);
          setProductError(e);
        },
      });
  }, [page, name, category, tag, min, max, rating, order]);

  /**
   * Get filter url
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} filter the filter parameters
   * @returns {string} the shop page url
   */
  const getFilterUrl = (filter: any): string => {
    const filterName = filter.name || name;
    const filterRating = filter.rating || rating;
    const filterLayout = filter.layout || layout;
    const sortOrder = filter.order || order;

    const filterMin = filter.min ? filter.min : filter.min === 0 ? 0 : min;

    const filterMax = filter.max ? filter.max : filter.max === 0 ? 0 : max;

    const filterPage =
      filter.category || filter.tag ? undefined : filter.page || page;

    const filterCategory = filter.tag ? "" : filter.category || category;

    const filterTag = filter.category ? "" : filter.tag || tag;

    let url = "/shop";
    let params = "";

    params +=
      (filterName &&
        (params ? `&name=${filterName}` : `?name=${filterName}`)) ||
      "";

    params +=
      (sortOrder && (params ? `&order=${sortOrder}` : `?order=${sortOrder}`)) ||
      "";

    params +=
      (filterMin && (params ? `&min=${filterMin}` : `?min=${filterMin}`)) || "";

    params +=
      (filterMax && (params ? `&max=${filterMax}` : `?max=${filterMax}`)) || "";

    params +=
      (filterRating &&
        (params ? `&rating=${filterRating}` : `?rating=${filterRating}`)) ||
      "";

    params +=
      (filterLayout &&
        (params ? `&layout=${filterLayout}` : `?layout=${filterLayout}`)) ||
      "";

    url += (filterCategory && `/category/${filterCategory}`) || "";
    url += (filterTag && `/tag/${filterTag}`) || "";
    url += (filterPage && `/page/${filterPage}`) || "";

    url += params;

    return url;
  };

  /**
   * Handle category change
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} category the product's category
   * @returns {void}
   */
  const handleCategoryChange = (category: any): void => {
    setStateCategory(category.category);
  };

  useEffect(() => {
    if (stateCategory !== "all") {
      navigate(getFilterUrl({ category: stateCategory }));
      setStateCategory("all");

      if (open) setOpen(false);
    }
  }, [navigate, stateCategory]);

  /**
   * Handle MinMax change
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} minMax the min and max param
   * @returns {void}
   */
  const handleMinMaxChange = (minMax: any): void => {
    setStateMin(minMax.min);
    setStateMax(minMax.max);
  };

  useEffect(() => {
    if (stateMin !== -1 && stateMax !== -1) {
      navigate(getFilterUrl({ min: stateMin, max: stateMax }));
      setStateMin(-1);
      setStateMax(-1);

      if (open) setOpen(false);
    }
  }, [navigate, stateMin, stateMax]);

  /**
   * Handle tag change
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} tag the product's tag
   * @returns {void}
   */
  const handleTagChange = (tag: any): void => {
    setStateTag(tag.tag);
  };

  useEffect(() => {
    if (stateTag !== "all") {
      navigate(getFilterUrl({ tag: stateTag }));
      setStateTag("all");

      if (open) setOpen(false);
    }
  }, [navigate, stateTag]);

  return (
    <div>
      <div className="">
        <Banner title="boutique" />
      </div>
      <div
        className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3
        grid-cols-3 mt-8"
      >
        <div className="col-span-1 hidden xl:block lg:block pr-10">
          <ProductFilter
            onCategoryChange={handleCategoryChange}
            onMinMaxChange={handleMinMaxChange}
            onTagChange={handleTagChange}
          />
        </div>
        <div className="col-span-3">
          <div
            className="xl:flex lg:flex md:flex sm:flex xs:flex-none
                justify-between items-cente xl:border-b lg:border-b md:border-b
                sm:border-b border-b-gray-200"
          >
            {productIsLoading ? (
              <LoadingBox></LoadingBox>
            ) : productError ? (
              <MessageBox variant="danger">{productError}</MessageBox>
            ) : (
              <div
                className="flex xl:justify-start lg:justify-start
                    md:justify-start sm:justify-start justify-between
                    items-center mb-4"
              >
                <div className="flex items-center">
                  <AdjustmentsHorizontalIcon
                    className="h-5 w-5 mr-2 cursor-pointer block xl:hidden
                        lg:hidden"
                    onClick={() => setOpen(true)}
                  />
                  <Squares2X2Icon
                    className={classNames(
                      layout == "grid" ? "text-secondary" : "text-gray-400",
                      "h-5 w-5 mr-2 hover:text-secondary cursor-pointer"
                    )}
                    onClick={() => {
                      navigate(getFilterUrl({ layout: "grid" }));
                    }}
                  />
                  <ListBulletIcon
                    className={classNames(
                      layout == "list" ? "text-secondary" : "text-gray-400",
                      "h-5 w-5 mr-2 hover:text-secondary cursor-pointer"
                    )}
                    onClick={() => {
                      navigate(getFilterUrl({ layout: "list" }));
                    }}
                  />
                </div>

                <p>
                  Affichage de {products.length ? 1 : 0} à{" "}
                  {products.length ? perPage : 0} sur {allProducts}
                </p>
              </div>
            )}
            <div
              className="flex items-center xl:justify-start lg:justify-start
              md:justify-start sm:justify-start justify-between mb-4"
            >
              <div className="mr-4 hidden xl:block lg:block md:block">
                Trier par:{" "}
              </div>
              <Listbox value={selected} onChange={setSelected}>
                {({ open }) => (
                  <>
                    <div
                      className="relative mt-1 w-full xl:w-64 lg:w-64 md:w-64
                        sm:w-64"
                    >
                      <Listbox.Button
                        className="relative w-full cursor-default
                        rounded-md border border-gray-300 bg-white py-2 pr-10
                        text-left shadow-sm focus:border-gray-300 sm:text-sm
                        focus:outline-none focus:ring-0 focus:ring-0 pl-3"
                      >
                        <span className="flex items-center">
                          <span className="block truncate">
                            {selected.title}
                          </span>
                        </span>
                        <span
                          className="pointer-events-none absolute inset-y-0
                            right-0 ml-3 flex items-center pr-2"
                        >
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options
                          className="absolute z-20 mt-1 max-h-56
                            w-full overflow-auto rounded-md bg-white py-1 ring-1
                            text-base shadow-lg ring-black ring-opacity-5
                            focus:outline-none sm:text-sm"
                        >
                          {inputFilter.map((filter) => (
                            <Listbox.Option
                              key={filter.id}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "text-white bg-secondary"
                                    : "text-gray-900",
                                  `relative cursor-default select-none py-2
                                    pl-3 pr-9`
                                )
                              }
                              value={filter}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div className="flex items-center">
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "block truncate"
                                      )}
                                      onClick={() => {
                                        navigate(
                                          getFilterUrl({
                                            order: filter.value,
                                            page: 1,
                                          })
                                        );
                                      }}
                                    >
                                      {filter.title}
                                    </span>
                                  </div>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? "text-white"
                                          : "text-secondary",
                                        `absolute inset-y-0 right-0 flex
                                            items-center pr-4`
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>
          <div className="mt-6">
            {productIsLoading ? (
              <LoadingBox></LoadingBox>
            ) : productError ? (
              <MessageBox variant="danger">{productError}</MessageBox>
            ) : (
              <>
                {products.length === 0 && (
                  <MessageBox>Aucun produit trouvé !</MessageBox>
                )}
                <div className="">
                  <ProductItems data={products} layout={layout} />
                </div>
                {allProducts > perPage && (
                  <div className="flex justify-center mt-8">
                    {previousPage ? (
                      <button
                        onClick={() => {
                          navigate(getFilterUrl({ page: previousPage }));
                        }}
                        className={classNames(
                          productIsLoading
                            ? "text-gray-400"
                            : "hover:text-secondary",
                          `flex justify-center items-center bg-transparent
                            font-bold mr-4`
                        )}
                        disabled={productIsLoading ? true : false}
                      >
                        <ChevronDoubleLeft
                          className="h-3 w-3 chevron -mt-px pb-px
                            duration-500"
                        />
                        Précedent
                      </button>
                    ) : null}

                    {[...Array(pages).keys()].map((x) => (
                      <Link
                        className={classNames(
                          x + 1 === currentPage
                            ? "bg-secondary text-white border-secondary"
                            : "bg-white border-gray-200 hover:bg-secondary",
                          `flex justify-center items-center rounded-full
                                border hover:text-white h-8 w-8 text-center mr-2
                                pt-1`
                        )}
                        key={x + 1}
                        to={getFilterUrl({ page: x + 1 })}
                      >
                        {x + 1}
                      </Link>
                    ))}

                    {nextPage ? (
                      <button
                        onClick={() => {
                          navigate(getFilterUrl({ page: nextPage }));
                        }}
                        className={classNames(
                          productIsLoading
                            ? "text-gray-400"
                            : "hover:text-secondary",
                          `flex justify-center items-center bg-transparent
                                font-bold ml-2`
                        )}
                        disabled={productIsLoading ? true : false}
                      >
                        Suivant
                        <ChevronDoubleRight
                          className="h-3 w-3 chevron -mt-px
                            pb-px duration-500"
                        />
                      </button>
                    ) : null}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {/* Mobile Filter */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel
                className="relative flex w-full max-w-xs flex-col
                    overflow-y-auto bg-white pb-12 shadow-xl"
              >
                <div className="flex px-4 pt-5 pb-2">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center
                        rounded-md p-2 text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="border-t border-gray-200 px-4">
                  <ProductFilter
                    onCategoryChange={handleCategoryChange}
                    onMinMaxChange={handleMinMaxChange}
                    onTagChange={handleTagChange}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
