import React, { Fragment } from "react";
import FooterInfo from "./footer-info";
import FooterMenu from "./footer-menu";
import ChatBox from "../others/chat-box";
import FooterSticky from "./footersticky/footer-sticky";
import useLocalStorage from "../../hooks/use-localstorage";

/**
 * Footer component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function Footer(): JSX.Element {
  const [userInfo] = useLocalStorage("userInfo", null);

  return (
    <Fragment>
      <footer className="bg-primary text-white pb-24 lg:pb-10 xl:pb-10">
        <div className="px-8 pt-10 pb-4">
          <FooterInfo />
        </div>
        <div
          className="mx-6 px-4 py-10 border border-opacity-5 border-x-0
            border-y-primary-100"
        >
          <FooterMenu />
        </div>
        {userInfo && !userInfo.isAdmin && <ChatBox userInfo={userInfo} />}
        <div className="text-center text-gray-300 mt-10">
          Copyright &copy; 2022 <span className="text-secondary">Kitecole</span>
          . Tous droits réservés.
        </div>
      </footer>
      <div
        className="bg-white w-full z-20 sm:block xs:block md:block lg:hidden
        xl:hidden footer-sticky"
      >
        <FooterSticky />
      </div>
    </Fragment>
  );
}
