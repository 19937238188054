import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/place-order.style.scss";
import CheckoutForm from "../components/checkout-form.component";
import CheckoutDetails from "../components/checkout-details.component";
import PaymentMethod from "../components/payment-method.component";
import PlaceOrderButton from "../components/place-order-button.component";

/**
 * Place order page
 *
 * @author Valentin magde <valentinmage@gmail.com>
 * @since 2023-10-02
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function PlaceOrderPage(): JSX.Element {
  const [checkoutFormData, setCheckoutFormData] = useState(null);
  const [checkoutDetailsData, setCheckoutDetailsData] = useState(null);
  const [checkoutPaymentMethodData, setCheckoutPaymentMethodData] =
    useState(null);
  const [selectedCountry, setSelectedCountry] = useState("") as any;
  const [selectedState, setSelectedState] = useState("") as any;
  const [isFormValidationTrigger, setIsFormValidationTrigger] =
    useState<boolean>(false);
  const [paymentMethodIsLoading, setPaymentMethodIsLoading] = useState(false);
  const [invalidPaymentForm, setInvalidPaymentForm] = useState(false);
  const [invalidCheckoutForm, setInvalidCheckoutForm] = useState(false);

  return (
    <div>
      <div className="mt-10 xl:mt-16 lg:mt-16 md:mt-16 sm:mt-16 mb-10">
        <div
          className="grid grid-cols-1 gap-12 xl:grid-cols-5 lg:grid-cols-5
          md:grid-cols-5"
        >
          <div className="col-span-1 xl:col-span-3 lg:col-span-3 md:col-span-3">
            <div className="mb-4">
              <h2 className="text-xl font-bold tracking-tight text-primary">
                Détails de facturation
              </h2>
            </div>
            <CheckoutForm
              triggerFormValidation={isFormValidationTrigger}
              checkoutFormData={(e: any) => setCheckoutFormData(e)}
              selectedCountry={(e: any) => setSelectedCountry(e)}
              selectedState={(e: any) => setSelectedState(e)}
              invalidCheckoutForm={(e: any) => setInvalidCheckoutForm(e)}
            />
          </div>
          <div
            className="col-span-1 xl:col-span-2 lg:col-span-2 md:col-span-2 flex
              border-6 border-gray-200 px-4 sm:px-6 lg:px-8"
          >
            <div className="w-full space-y-8">
              <div>
                <h2
                  className="text-xl font-bold tracking-tight text-primary
                  pt-6"
                >
                  Votre commande
                </h2>
              </div>
              <CheckoutDetails
                triggerFormValidation={isFormValidationTrigger}
                checkoutDetailsData={(e: any) => setCheckoutDetailsData(e)}
                country={selectedCountry}
                state={selectedState}
              />
              <div>
                <h2 className="text-xl text-primary font-bold">
                  Mode de paiement
                </h2>
                <PaymentMethod
                  triggerFormValidation={isFormValidationTrigger}
                  checkoutFormData={checkoutFormData}
                  checkoutPaymentMethodData={(e: any) =>
                    setCheckoutPaymentMethodData(e)
                  }
                  paymentMethodIsLoading={(e: any) =>
                    setPaymentMethodIsLoading(e)
                  }
                  invalidPaymentForm={(e: any) => setInvalidPaymentForm(e)}
                  country={selectedCountry}
                />
              </div>
              <div className="text-justify text-sm leading-6">
                Vos données personnelles seront utilisées pour traiter votre
                commande, soutenir votre expérience sur ce site web et à
                d&apos;autres fins décrites dans notre
                <Link to="#" className="text-secondary">
                  {" "}
                  politique de confidentialité.
                </Link>
              </div>
              <div>
                <PlaceOrderButton
                  triggerFormValidation={(e: boolean) =>
                    setIsFormValidationTrigger(e)
                  }
                  checkoutFormData={checkoutFormData}
                  checkoutDetailsData={checkoutDetailsData}
                  checkoutPaymentMethodData={checkoutPaymentMethodData}
                  paymentMethodIsLoading={paymentMethodIsLoading}
                  invalidCheckoutForm={invalidCheckoutForm}
                  invalidPaymentForm={invalidPaymentForm}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
