import React, { Fragment, useEffect } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import MessageBox from "../../../shared/components/others/message-box";
import WishlistItems from "../components/wishlist-items.component";
import { toast } from "react-toastify";
import useLocalStorage from "../../../shared/hooks/use-localstorage";
import Banner from "../../../shared/components/banners/banner";
import cartService from "../../../shared/api/product-service/cart/cart.service";

/**
 * Wishlist page
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-27
 *
 * @returns {JSX.Element} the jsx element
 */
export default function WishlistPage(): JSX.Element {
  const { id } = useParams();
  const [search] = useSearchParams();
  const qty = search.get("qty") ? search.get("qty") : 1;
  const [wishlistItems] = useLocalStorage("wishlistItems", []);
  const naviget = useNavigate();

  useEffect(() => {
    if (id) {
      cartService.addToCart(id, qty).subscribe({
        next: () => {
          toast.success("Produit ajouté avec succès au panier!", {
            theme: "colored",
          });
        },
        error: () => {
          toast.error("Error !", {
            theme: "colored",
          });
        },
      });
    }
  }, [id, qty]);

  /**
   * Redirect to checkout page
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-27
   *
   * @returns {void}
   */
  const checkoutHandler = () => {
    naviget("/signin?redirect=checkout");
  };

  return (
    <Fragment>
      <Banner title="Wishlist" />
      {!wishlistItems.length ? (
        <div className="mt-8">
          <MessageBox>
            La liste des souhaits est vide.
            <Link to="/shop" className="text-secondary">
              {" "}
              Aller à la boutique
            </Link>
          </MessageBox>
        </div>
      ) : (
        <div className="mt-16">
          <WishlistItems wishlistItems={wishlistItems} />
        </div>
      )}
    </Fragment>
  );
}
