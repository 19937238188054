import React from "react";
import FullStickyHeader from "./full-sticky-header";
import ContainStickyHeader from "./contain-sticky-header";

/**
 * Sticky header component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function StickyHeader(): JSX.Element {
  return (
    <>
      <div className="sm:hidden xs:hidden md:hidden lg:block xl:block">
        <FullStickyHeader />
      </div>
      <div className="bg-white sm:block xs:block md:block lg:hidden xl:hidden">
        <ContainStickyHeader />
      </div>
    </>
  );
}
