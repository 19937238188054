import React, { Fragment, useEffect } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Banner from "../../../shared/components/banners/banner";
import MessageBox from "../../../shared/components/others/message-box";
import CartItems from "../components/cart-items.component";
import { toast } from "react-toastify";
import useLocalStorage from "../../../shared/hooks/use-localstorage";
import cartService from "../../../shared/api/product-service/cart/cart.service";
import EmptyCart from "../components/empty-cart";

/**
 * Cart page
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-27
 *
 * @returns {JSX.Element} the jsx element
 */
function CartPage(): JSX.Element {
  const { id } = useParams();
  const [search] = useSearchParams();
  const qty = search.get("qty") ? search.get("qty") : 1;
  const [cartItems] = useLocalStorage("cartItems", []);
  const naviget = useNavigate();

  useEffect(() => {
    if (id) {
      cartService.addToCart(id, qty).subscribe({
        next: () => {
          toast.success("Produit ajouté avec succès au panier!", {
            theme: "colored",
          });
        },
        error: () => {
          toast.error("Error !", {
            theme: "colored",
          });
        },
      });
    }
  }, [id, qty]);

  /**
   * Redirect to checkout page
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-27
   *
   * @returns {void}
   */
  const checkoutHandler = () => {
    naviget("/signin?redirect=checkout");
  };

  return (
    <Fragment>
      <Banner title="Panier" />
      {!cartItems.length ? (
        <div className="my-24">
          <EmptyCart />
        </div>
      ) : (
        <div
          className="grid grid-cols-1 gap-4 xl:grid-cols-3
                lg:grid-cols-3
                mt-10 xl:mt-16 lg:mt-16 md:mt-16 sm:mt-16 mb-6"
        >
          <div className="col-span-1 xl:col-span-2 lg:col-span-2 mb-10">
            <CartItems cartItems={cartItems} />
          </div>
          <div className="col-span-1">
            <div
              className="px-6 py-3 border-6 border-gray-200 font-bold
                        text-primary ml-0 xl:ml-8 lg:ml-0 md:ml-0 sm:ml-0"
            >
              <h2
                className="uppercase font-bold text-lg text-primary
                        border-b-2 border-gray-200 pb-2"
              >
                Total du panier
              </h2>
              <div
                className="flex py-6 justify-between border-b
                        border-gray-200"
              >
                <p>
                  Sous-total (
                  {cartItems.reduce(
                    (a: any, c: any) => parseInt(a) + parseInt(c.qty),
                    0
                  )}
                  )
                </p>
                <p>
                  {cartItems.reduce((a: any, c: any) => a + c.price * c.qty, 0)}{" "}
                  FCFA
                </p>
              </div>
              <div className="flex py-6 justify-between">
                <p>Total</p>
                <p className="text-lg text-secondary">
                  {cartItems.reduce((a: any, c: any) => a + c.price * c.qty, 0)}{" "}
                  FCFA
                </p>
              </div>
              <button
                type="button"
                onClick={checkoutHandler}
                className="w-full rounded-md my-6 py-2 bg-secondary
                            hover:bg-ternary block text-white"
                disabled={!cartItems.length}
              >
                Commander
              </button>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default CartPage;
