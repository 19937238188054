import { ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "./breadcrumb";

/**
 * Banner component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @param {BannerProps} props the report handler
 * @returns {JSX.Element} the javascript xml element
 */
export default function Banner(props: BannerProps): JSX.Element {
  const title = props.title;

  return (
    <div className="flex items-center banner-height -mx-8 -mt-8">
      <img
        src="/assets/images/banners/banner-1.jpg"
        alt="Banner"
        className="h-full w-full object-cover"
      />
      <div className="absolute ml-8">
        <p className="text-primary text-5xl font-bold capitalize-first">
          {title}
        </p>
        <div className="flex items-center mt-4 normal-case">
          <Breadcrumb />
        </div>
      </div>
    </div>
  );
}

type BannerProps = {
  title: string;
};
