import {
  CalendarIcon,
  ChatBubbleLeftRightIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { _date, getWindowSize } from "../../../shared/utils/helper.util";
import PostType from "../../../shared/api/blog-service/post/post.type";
import postService from "../../../shared/api/blog-service/post/post.service";
import LoadingBox from "../../../shared/components/others/loading-box";
import MessageBox from "../../../shared/components/others/message-box";

const responsive = {
  largeScreens: {
    breakpoint: { max: 3000, min: 1200 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },
  smallScreens: {
    breakpoint: { max: 1200, min: 855 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 855, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

/**
 * Home blog component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function HomeFromBlog(): JSX.Element {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [lastPosts, setLastPosts] = useState<Array<PostType>>([]);
  const [lastPostsError, setLastPostsError] = useState("");
  const [lastPostsIsLoading, setLastPostsIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    /**
     * Handle window size
     *
     * @author Valentin magde <valentinmagde@gmail.com>
     * @since 2023-06-28
     *
     * @returns {void}
     */
    function handleWindowResize(): void {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize]);

  // Get three last posts
  useEffect(() => {
    postService.showAll({ order: "newest", perPage: 3 }).subscribe({
      next: (data) => {
        // Stop loading
        setLastPostsIsLoading(false);

        setLastPosts(data.posts);
      },
      error: (e) => {
        // Stop loading
        setLastPostsIsLoading(false);

        setLastPostsError(e);
      },
    });
  }, []);

  console.log(windowSize.innerWidth);
  return (
    <div className="xl:mx-16 lg:mx-16 pt-24">
      <div className="text-center">
        <p
          className="greatest-richmond-font text-secondary
            lxl:text-2xl lg:text-2xl md:text-2xl sm:text-xl text-xl
            our-offers-title"
        >
          Depuis Notre Blog
        </p>
        <p
          className="font-bold text-primary mt-2 xl:text-4xl
            lg:text-4xl md:text-3xl sm:text-2xl text-2xl"
        >
          Dernières Mises à Jour
        </p>
      </div>
      <div className="mt-6">
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={windowSize.innerWidth < 1200 ? true : false}
          arrows={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={false}
          autoPlaySpeed={5000}
          keyBoardControl={true}
          // customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          // deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style"
          // renderDotsOutside={true}
          itemClass="carousel-item-padding-40-px"
          className="w-full grid grid-cols-1 mx-auto"
        >
          {lastPostsIsLoading ? (
            <LoadingBox></LoadingBox>
          ) : lastPostsError ? (
            <MessageBox variant="danger">{lastPostsError}</MessageBox>
          ) : (
            lastPosts.map((post: PostType) => (
              <div key={post._id} className="flex justify-center mx-2">
                <div>
                  <div className="flex items-end">
                    <Link to={`/blog/post/${post._id}`}>
                      <img src={post.image} alt={post.name} />
                    </Link>
                    {post.categories.length && (
                      <button
                        className="absolute bg-secondary hover:bg-ternary
                            rounded-md px-2 py-1 text-xs text-white uppercase
                            mb-4 ml-2"
                        onClick={() =>
                          navigate(
                            `/blog/post/category/${post.categories[0].slug}`
                          )
                        }
                      >
                        {post.categories[0].name}
                      </button>
                    )}
                  </div>
                  <div className="mt-4">
                    <div className="flex items-center text-primary">
                      <CalendarIcon className="h-4 w-4 mr-1 mb-1" />
                      <Link to={`/blog/post/${post._id}`}>
                        <p className="underline">{_date(post.created_at)}</p>
                      </Link>
                      <p> &nbsp;/&nbsp; </p>
                      <UserIcon className="h-4 w-4 mr-1 mb-1" />
                      <p>
                        par&nbsp;
                        <Link to="#">
                          <span className="underline">l&apos;admin</span>
                        </Link>
                      </p>
                      <p
                        className="hidden xl:block lg:block md:block
                            sm:block"
                      >
                        &nbsp;/&nbsp;
                      </p>
                      <ChatBubbleLeftRightIcon
                        className="h-4 w-4 mr-1 mb-1 hidden xl:block lg:block
                            md:block sm:block"
                      />
                      <p className="hidden xl:block lg:block md:block sm:block">
                        0
                      </p>
                    </div>
                    <div className="mt-4">
                      <Link
                        to="#"
                        className="text-sm xl:text-lg lg:text-lg md:text-md
                            text-justify font-bold"
                      >
                        {post.name}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </Carousel>
      </div>
    </div>
  );
}
