import { Observable, defer, map } from "rxjs";
import { AxiosResponse } from "axios";
import axiosInstance from "../../../utils/http-client.util";

/**
 * Email service
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-10
 *
 * class EmailService
 */
class EmailService {
  public lang = "en";

  /**
   * Send email
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-10-10
   *
   * @returns {Promise<void>} the eventual completion or failure
   */
  public sendEmail({
    senderName = "",
    senderEmail = "",
    subject = "",
    body = "",
  }): Observable<any> {
    return defer(() =>
      axiosInstance.post(`/v1/${this.lang}/email/send`, {
        senderName,
        senderEmail,
        subject,
        body,
      })
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }
}

const emailService = new EmailService();
export default emailService;
