import React from "react";
import { Link } from "react-router-dom";
import Facebook from "../socialicons/facebook";
import Youtube from "../socialicons/youtube";
import Twitter from "../socialicons/twitter";

/**
 * Footer follow us component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function FooterFollowUs(): JSX.Element {
  return (
    <div>
      <p className="font-bold">SUIVEZ-NOUS</p>
      <div className="flex justify-between items-center text-secondary">
        <Link
          to="https://www.facebook.com/KitContact"
          target="_blank"
          className=""
        >
          <Facebook />
        </Link>
        <Link to="#">
          <Youtube />
        </Link>
        <Link to="https://twitter.com/kitecole1" target="_blank">
          <Twitter />
        </Link>
      </div>
    </div>
  );
}
