import React from "react";
import HeaderCallUs from "../header/headercallus/header-call-us";
import FooterFollowUs from "./footer-follow-us";
import FooterPaymentMethod from "./footer-payment-method";

/**
 * Footer info component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function FooterInfo(): JSX.Element {
  return (
    <div
      className="flex-none justify-between items-center xl:flex lg:flex
        md:flex"
    >
      <div
        className="grid xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2
            md:grid-cols-2 grid-cols-1"
      >
        <div
          className="col-span-1 pb-6 text-center xl:text-left lg:text-left
            md:text-left"
        >
          <HeaderCallUs />
        </div>
        <div
          className="col-span-1 flex justify-center pb-6 mt-10 text-center
            xl:text-left lg:text-left md:text-left xl:mt-0 lg:mt-0 md:mt-0"
        >
          <FooterFollowUs />
        </div>
      </div>
      <div
        className="flex justify-center pb-6 xl:justify-start lg:justify-start
        md:justify-start"
      >
        <FooterPaymentMethod />
      </div>
    </div>
  );
}
