import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../../../shared/components/route/private-route";
import CustomerPage from "../pages/customer.page";
import customerAccountMenuItems from "../../../resources/navigations/customer-account-menu-items.json";
import LoadCustomerComponent from "../components/load.component";
import OrderDetails from "../../../shared/components/order/order-details";
import OrderList from "../components/order-list.component";
import AddressDetails from "../components/address-details.component";
import AddressForm from "../components/address-form-component";

/**
 * Customer route
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-07
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function CustomerRoute(): JSX.Element {
  return (
    <Routes>
      <Route
        path="/my-account"
        element={
          <PrivateRoute path="/my-account">
            <CustomerPage />
          </PrivateRoute>
        }
      >
        {customerAccountMenuItems.map((item: any) => (
          <Fragment key={item.slug}>
            {item.slug !== "logout" &&
              (item.slug === "dashboard" ? (
                <Route
                  index
                  element={<LoadCustomerComponent slug={item.slug} />}
                />
              ) : item.slug === "orders" ? (
                <Route
                  path={item.slug}
                  element={<LoadCustomerComponent slug={item.slug} />}
                >
                  <Route index element={<OrderList />} />
                  <Route path="view-order/:id" element={<OrderDetails />} />
                </Route>
              ) : item.slug === "address" ? (
                <Route
                  key={item.slug}
                  path={item.slug}
                  element={<LoadCustomerComponent slug={item.slug} />}
                >
                  <Route index element={<AddressDetails />} />
                  <Route path="edit-billing" element={<AddressForm />} />
                  <Route path="edit-delivery" element={<AddressForm />} />
                </Route>
              ) : (
                <Route
                  key={item.slug}
                  path={item.slug}
                  element={<LoadCustomerComponent slug={item.slug} />}
                />
              ))}
          </Fragment>
        ))}
      </Route>
    </Routes>
  );
}
