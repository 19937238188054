import { Observable, defer, map } from "rxjs";
import { AxiosResponse } from "axios";
import axiosInstance from "../../../utils/http-client.util";

/**
 * Shipping zone service
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-08-14
 *
 * class PaymentMethodService
 */
class PaymentMethodService {
  public lang = "en";

  /**
   * Get all payment methods
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-14
   *
   * @returns {Promise<void>} the eventual completion or failure
   */
  public getPaymentMethods(): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/paymentMethods`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Get payment methods by system
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-14
   *
   * @param {any} systemId the system's id
   * @returns {Observable<any>} the eventual completion or failure
   */
  public getPaymentMethodsBySystem(systemId: any): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/paymentMethods/system/${systemId}`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Get payment method by id
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-08-14
   *
   * @param {any} paymentMethodId the payment method's id
   * @returns {Observable<any>} the eventual completion or failure
   */
  public getPaymentMethodById(paymentMethodId: any): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/paymentMethod/${paymentMethodId}`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }
}

const paymentMethodService = new PaymentMethodService();
export default paymentMethodService;
