import { Observable, defer, map } from "rxjs";
import { AxiosResponse } from "axios";
import axiosInstance from "../../../utils/http-client.util";

/**
 * Category service
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-07
 *
 * class CategoryService
 */
class CategoryService {
  public lang = "en";

  /**
   * Get all categories
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-10-07
   *
   * @returns {Promise<void>} the eventual completion or failure
   */
  public getAll(): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/blog/categories`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Get categorie by id
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-10-07
   *
   * @param {any} categoryId the category's id
   * @returns {Observable<any>} the eventual completion or failure
   */
  public getById(categoryId: any): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/blog/category/${categoryId}`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }
}

const categoryService = new CategoryService();
export default categoryService;
