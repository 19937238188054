import React, { useState } from "react";

import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../../utils/helper.util";

/**
 * Language switcher component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function LanguageSwitcher(): JSX.Element {
  const [language, setLanguage] = useState("fr");

  return (
    <Menu as="div" className="relative flex text-left">
      <Menu.Button>
        <span className="flex items-center">
          <span className="flex items-center block hover:text-secondary">
            {language === "fr" ? (
              <span>Français</span>
            ) : (
              <span>English (UK)</span>
            )}
            <ChevronDownIcon className="h-3 w-3 ml-1" />
          </span>
        </span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute mt-1 z-20 right-0 w-40 origin-top-right
            divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black
            ring-opacity-5 focus:outline-none top-6"
        >
          <Menu.Item>
            {({ active }) => (
              <Link
                to="#"
                onClick={() => setLanguage("en")}
                className={classNames(
                  active ? "bg-gray-100" : "",
                  `block px-4 py-3 border border-x-0 border-t-0
                    border-b-gray-100 text-gray-700 hover:text-secondary`
                )}
              >
                <span className="flex items-center">
                  <span className="ml-3 block">English (UK)</span>
                </span>
              </Link>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <Link
                to="#"
                onClick={() => setLanguage("fr")}
                className={classNames(
                  active ? "bg-gray-100" : "",
                  `block px-4 py-3 border border-x-0 border-t-0
                    border-b-gray-100 text-gray-700 hover:text-secondary`
                )}
              >
                <span className="flex items-center">
                  {/* <img
                            src="/assets/flags/fr.svg"
                            alt=""
                            className="block h-5 w-5 flex-shrink-0"
                            /> */}
                  <span className="ml-3 block">Français</span>
                </span>
              </Link>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
