import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { classNames } from "../../../../shared/utils/helper.util";
import LoadingBox from "../../../../shared/components/others/loading-box";
import orderService from "../../../../shared/api/order-service/order/order.service";
import { toast } from "react-toastify";
import cartService from "../../../../shared/api/product-service/cart/cart.service";

/**
 *  Checkout form
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-27
 *
 * @param {PlaceOrderButtonProps} props the shared properties
 * @returns {JSX.Element} the javaScriptXML element
 */
export default function PlaceOrderButton(
  props: PlaceOrderButtonProps
): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const {
    checkoutFormData,
    checkoutDetailsData,
    checkoutPaymentMethodData,
    paymentMethodIsLoading,
    invalidCheckoutForm,
    invalidPaymentForm,
  } = props;
  const navigate = useNavigate();

  /**
   * Submit the form
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-27
   *
   * @param {any} e the react mouse event
   * @returns {void}
   */
  const handleSubmit = async (e: any): Promise<void> => {
    e.preventDefault();

    props.triggerFormValidation(true);
  };

  /**
   * Place an order
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-27
   *
   * @param {any} data the order data
   * @returns {void}
   */
  const placeOrder = (data: any = null): void => {
    // Start loader
    if (!isLoading) setIsLoading(true);

    orderService.createOrder(data).subscribe({
      next: (order) => {
        toast.success("Order created successfully !", {
          theme: "colored",
        });

        setIsLoading(false);
        props.triggerFormValidation(false);

        // Remove products from cart
        cartService.removeAllFromCart().subscribe({
          next: () => {
            navigate(`/checkout/order-receive/${order._id}`);
          },
          error: (e) => {
            toast.error(e, {
              theme: "colored",
            });
          },
        });
      },
      error: (e) => {
        toast.error(e, {
          theme: "colored",
        });

        setIsLoading(false);
        props.triggerFormValidation(false);
      },
    });
  };

  useEffect(() => {
    console.log(
      checkoutFormData,
      checkoutDetailsData,
      checkoutPaymentMethodData,
      paymentMethodIsLoading
    );
    if (checkoutFormData && checkoutDetailsData && checkoutPaymentMethodData) {
      const orderData = {
        billing_details: checkoutFormData.billingDetails,
        shipping_address: checkoutFormData.shippingAddress,
        delivery_note: checkoutFormData.deliveryNote,
        user: checkoutFormData.user,
        order_items: checkoutDetailsData.orderItems,
        shipping_method: checkoutDetailsData.shippingMethod,
        items_price: checkoutDetailsData.itemsPrice,
        shipping_price: checkoutDetailsData.shippingPrice,
        tax_price: checkoutDetailsData.taxPrice,
        total_price: checkoutDetailsData.totalPrice,
        currency: "XAF",
        payment_method: checkoutPaymentMethodData.paymentMethod,
        payment_description: checkoutPaymentMethodData.paymentDescription,
        payment_data: checkoutPaymentMethodData.paymentData,
      };

      console.log(orderData);

      placeOrder(orderData);
    }
    // else if(
    //   !paymentMethodIsLoading
    // ) {
    //   props.triggerFormValidation(false);
    // }
    // else {
    //   props.triggerFormValidation(false);
    // }
  }, [checkoutFormData, checkoutDetailsData, checkoutPaymentMethodData]);

  useEffect(() => {
    if (paymentMethodIsLoading) {
      setIsLoading(paymentMethodIsLoading);
      // props.triggerFormValidation(paymentMethodIsLoading);
    }
  }, [paymentMethodIsLoading]);

  useEffect(() => {
    console.log(invalidPaymentForm, invalidCheckoutForm);
    if (invalidPaymentForm || invalidCheckoutForm) {
      props.triggerFormValidation(false);
    }
  }, [invalidPaymentForm, invalidCheckoutForm]);

  return (
    <button
      onClick={(e) => handleSubmit(e)}
      type="submit"
      className={classNames(
        isLoading
          ? "bg-gray-400 text-gray-400 hover:bg-gray-400"
          : `bg-secondary hover:bg-ternary focus:outline-none
          focus:ring-primary text-white`,
        `w-full group flex justify-center rounded border
        border-transparent mb-8 py-2 px-4 font-medium focus:ring-0 relative
        focus:ring-offset-2`
      )}
      disabled={isLoading}
    >
      {isLoading ? (
        <div className="absolute text-black">
          <LoadingBox className="text-white" />
        </div>
      ) : null}
      Passer la commande
    </button>
  );
}

type PlaceOrderButtonProps = {
  triggerFormValidation: any;
  checkoutFormData: any;
  checkoutDetailsData: any;
  checkoutPaymentMethodData: any;
  paymentMethodIsLoading: any;
  invalidCheckoutForm: boolean;
  invalidPaymentForm: boolean;
};
