import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../../shared/utils/helper.util";
import { toast } from "react-toastify";
import RemoveFromCartButton from "../../../shared/components/product/remove-from-cart-button";
import cartService from "../../../shared/api/product-service/cart/cart.service";
import CartType from "../../../shared/api/product-service/cart/cart.type";

/**
 * Display cart items
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-27
 *
 * @param {CartItemsProps} props the props properties
 * @returns {JSX.Element} the jsx element
 */
export default function CartItems(props: CartItemsProps): JSX.Element {
  const cartItems = props.cartItems;

  const [selected, setSelected] = useState(() => cartItems.map((x) => x.qty));

  /**
   * Add product to cart
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-07-04
   *
   * @param {string} id the product's id
   * @param {number} qty the product's quantity
   * @returns {void}
   */
  const addToCartHandler = (id: string, qty: number): void => {
    cartService.addToCart(id, qty).subscribe({
      next: () => {
        toast.success("Produit ajouté avec succès au panier!", {
          theme: "colored",
        });
      },
      error: () => {
        toast.error("Error !", {
          theme: "colored",
        });
      },
    });
  };

  return (
    <div>
      <div
        className="grid grid-cols-7 py-4 border-b-2 border-gray-200 uppercase
          text-xs text-primary font-bold hidden xl:grid lg:grid md:grid"
      >
        <div className="col-span-4 text-center">Produit</div>
        <div className="col-span-1">Prix</div>
        <div className="col-span-1">Quantite</div>
        <div className="col-span-1">Sous-total</div>
      </div>
      {cartItems.map((item, index) => (
        <div key={item._id}>
          <div
            className="items-center grid grid-cols-1 border-gray-200 py-2
              xl:grid-cols-7 lg:grid-cols-7 md:grid-cols-7
              sm:grid-cols-2 xl:border-b lg:border-b md:border-b sm:border-b"
          >
            <div
              className="col-span-1 xl:col-span-2 lg:col-span-2 md:col-span-2
              sm:col-span-1"
            >
              <div className="flex justify-between">
                {/* <button
                  type="button"
                  onClick={() => removeFromCartHandler(item._id)}
                  className="flex items-center hidden xl:block lg:block
                    md:block">
                  <XCircleIcon
                    className="h-4 w-4 text-gray-500 hover:text-secondary mr-4"
                    aria-hidden="true"
                  />
                </button> */}
                <div
                  className="flex items-center hidden xl:flex lg:flex
                  md:flex"
                >
                  <RemoveFromCartButton product={item} />
                </div>
                <img
                  className="xl:w-24 lg:w-24 md:w-24 sm:w-24 mx-auto mb-2
                    border border-gray-200 xl:mb-0 lg:mb-0 md:mb-0 sm:mb-0
                    xl:border-0 lg:border-0 md:border-0 sm:border-0"
                  src={item.image}
                  alt={item.name}
                />
              </div>
            </div>
            <div
              className="items-center gap-4 grid grid-cols-1 border-gray-200
                py-4 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-1
                col-span-1 xl:col-span-5 lg:col-span-5 md:col-span-5
                sm:col-span-1"
            >
              <div
                className="col-span-1 flex justify-between items-center
                  font-bold text-center xl:text-left lg:text-left md:text-left
                  sm:text-left xl:col-span-2 lg:col-span-2 md:col-span-2
                  sm:col-span-1 mb-3 xl:mb-0 lg:mb-0 md:mb-0"
              >
                <Link
                  to={`/product/${item._id}`}
                  className="text-primary xl:text-black lg:text-black
                    md:text-black"
                >
                  {item.name}
                </Link>
                {/* <button
                  type="button"
                  onClick={() => removeFromCartHandler(item._id)}
                  className="flex items-center block xl:hidden lg:hidden
                    md:hidden">
                  <XCircleIcon
                    className="h-5 w-5 sm:h-4 sm:w-4 text-gray-500
                      hover:text-secondary"
                    aria-hidden="true"
                  />
                </button> */}
                <div
                  className="flex items-center xl:hidden lg:hidden
                  md:hidden"
                >
                  <RemoveFromCartButton
                    product={item}
                    className="h-5 w-5 text-gray-500 hover:text-secondary"
                  />
                </div>
              </div>
              <div
                className="col-span-1 flex justify-between items-center mb-3
                  xl:mb-0 lg:mb-0 md:mb-0"
              >
                <p className="font-bold block xl:hidden lg:hidden md:hidden">
                  Prix
                </p>
                {item.price} FCFA
              </div>
              <div
                className="col-span-1 flex justify-between items-center
                xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-1
                mb-3 xl:mb-0 lg:mb-0 md:mb-0"
              >
                <p className="font-bold block xl:hidden lg:hidden md:hidden">
                  Quantité
                </p>
                <Listbox
                  value={selected[index]}
                  onChange={(e) =>
                    setSelected(selected.map((x, i) => (i === index ? e : x)))
                  }
                >
                  {({ open }) => (
                    <>
                      <div className="relative">
                        <Listbox.Button
                          className="relative w-20 cursor-default rounded-full
                          border border-gray-300 bg-white py-2 pl-3 pr-10
                          shadow-sm focus:border-gray-300 focus:outline-none
                          focus:ring-0 focus:ring-0 sm:text-sm"
                        >
                          <span className="flex items-center">
                            <span className="block truncate">
                              {selected[index]}
                            </span>
                          </span>
                          <span
                            className="pointer-events-none absolute
                            inset-y-0 right-0 ml-3 flex items-center pr-2"
                          >
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options
                            className="absolute z-40 mt-1
                            max-h-56 w-20 overflow-auto rounded-md bg-white
                            py-1 text-base shadow-lg ring-1 ring-black
                            ring-opacity-5 focus:outline-none sm:text-sm"
                          >
                            {[...Array(item.current_stock).keys()].map((x) => (
                              <Listbox.Option
                                key={x + 1}
                                onClick={() =>
                                  addToCartHandler(item._id, Number(x + 1))
                                }
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "text-white bg-secondary"
                                      : "text-gray-900",
                                    `relative cursor-default select-none py-2
                                      pl-3 pr-9`
                                  )
                                }
                                value={x + 1}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <div className="flex items-center">
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                      >
                                        {x + 1}
                                      </span>
                                    </div>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-secondary",
                                          `absolute inset-y-0 right-0 flex
                                            items-center pr-4`
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>
              <div
                className="col-span-1 flex justify-between items-center
                  xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-1"
              >
                <p className="font-bold block xl:hidden lg:hidden md:hidden">
                  Sous-total
                </p>
                {item.price * item.qty} FCFA
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

type CartItemsProps = {
  cartItems: Array<CartType>;
};
