import React, { useEffect, useState } from "react";
import LoadingBox from "../../../shared/components/others/loading-box";
import { classNames } from "../../../shared/utils/helper.util";
import { toast } from "react-toastify";
import userService from "../../../shared/api/user-service/user/user.service";
import MessageBox from "../../../shared/components/others/message-box";
import UserType from "../../../shared/api/user-service/user/user.type";

/**
 * Contact info component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-10
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function ContactInfo(): JSX.Element {
  const [user, setUser] = useState<UserType>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  // Get kitecole yaounde user
  useEffect(() => {
    // Start loader
    setIsLoading(true);

    userService.showByUsername("kitecole_yaounde").subscribe({
      next: (data) => {
        // Stop loading
        setIsLoading(false);

        setUser(data);
      },
      error: (e) => {
        console.log(e);
        // Stop loading
        setIsLoading(false);

        setError(e);
      },
    });
  }, []);

  return (
    <div
      className="grid grid-cols-1 gap-4 xl:grid-cols-2 lg:grid-cols-2
            md:grid-cols-2"
    >
      <div className="col-span-1">
        <div className="mb-8">
          <h2 className="font-bold uppercase">Notre magasin</h2>
          {isLoading ? (
            <LoadingBox />
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : user?.vendor ? (
            <p className="mt-4 leading-7 italic">
              {user?.vendor?.store_info?.address1 && (
                <span>
                  {user?.vendor?.store_info?.address1}
                  <br />
                </span>
              )}
              {user?.vendor?.store_info?.state?.name && (
                <span>{user?.vendor?.store_info?.state?.name}, &nbsp;</span>
              )}
              {user?.vendor?.store_info?.country?.name && (
                <span>{user?.vendor?.store_info?.country?.name}</span>
              )}
            </p>
          ) : (
            <p className="mt-4 italic">
              Ce type d&apos;adresse n&apos;est pas encore défini.
            </p>
          )}
        </div>
        <div>
          <h2 className="font-bold uppercase">Informations de contact</h2>
          {isLoading ? (
            <LoadingBox />
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : user?.vendor ? (
            <p className="mt-4 leading-7 italic">
              {user?.vendor?.store_info?.phone && (
                <span>
                  {user?.vendor?.store_info?.phone}
                  <br />
                </span>
              )}
              {user?.vendor?.store_info?.email && (
                <span>{user?.vendor?.store_info?.email}</span>
              )}
            </p>
          ) : (
            <p className="mt-4 italic">
              Ce type d&apos;information n&apos;est pas encore défini.
            </p>
          )}
        </div>
      </div>
      <div className="col-span-1">
        <div className="">
          <h2 className="mb-4 font-bold uppercase">
            Nos heures d&apos;ouverture
          </h2>
          {user?.vendor?.store_info?.business_hour &&
            user?.vendor?.store_info?.business_hour.map((elt) => (
              <p className="leading-7 italic" key={elt._id}>
                <span>{elt.day}:</span>&nbsp;
                <span>
                  {elt.start_time} - {elt.end_time}
                </span>
              </p>
            ))}
        </div>
      </div>
    </div>
  );
}
