import React from "react";
import { Menu } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import HeaderMegamenu from "../headermegamenu/header-megamenu";

/**
 * Header category menu component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function HeaderCategoryMenu(): JSX.Element {
  // const [isOpen, setIsOpen] = useState(false);

  return (
    <Menu as="div" className="relative ml-3 hidden lg:block">
      <div>
        <Menu.Button className="">
          <div
            className="flex rounded-full py-3 px-4 lg:ml-0 bg-secondary
            hover:bg-ternary items-center hidden lg:block"
          >
            <p
              className="rounded-md text-white px-4 flex justify-start
                items-end"
            >
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              <span className="ml-2 font-bold">Toutes les categories</span>
            </p>
          </div>
        </Menu.Button>
      </div>
      <Menu.Items
        className="absolute w-full z-10 mt-2 origin-top-left bg-white py-1
          shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div
          className="absolute w-4 -mt-4 ml-4 overflow-hidden
          inline-block"
        >
          <div
            className="h-3 w-3 bg-white border border-gray-200 rotate-45
              transform origin-bottom-left"
          ></div>
        </div>
        <Menu.Item>
          {({ close }) => <HeaderMegamenu onClose={close} />}
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
}
