import React, { useState } from "react";
import {
  BuildingStorefrontIcon,
  HeartIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import FooterSearch from "./footer-search";
import useLocalStorage from "../../../hooks/use-localstorage";
import { NavLink } from "react-router-dom";

/**
 * Footer sticky component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function FooterSticky(): JSX.Element {
  const [whishlistItems] = useLocalStorage("whishlistItems", []);
  const [clicked, setClicked] = useState(false);

  /**
   * Button event
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {boolean} value cliked value
   * @returns {void}
   */
  const navClicked = (value: boolean): void => {
    setClicked(value);
  };

  return (
    <div
      className="grid grid-cols-4 gap-3 flex text-primary items-center
            bg-white shadow border-t border-gray-200"
    >
      <div
        className="col-span-1 flex justify-center items-center
                    border-r border-gray-200 p-2 cursor-pointer"
      >
        <div>
          <NavLink
            to="/shop"
            className={({ isActive }) =>
              isActive ? `text-secondary` : `text-primary-100`
            }
            onClick={() => navClicked(true)}
          >
            <div className="flex justify-center">
              <BuildingStorefrontIcon className="h-6 w-6" />
            </div>
            <div className="flex justify-center font-bold text-xs">
              Boutique
            </div>
          </NavLink>
        </div>
      </div>
      <div className="col-span-1 flex justify-center items-center border-r border-gray-200 p-2 cursor-pointer">
        <NavLink
          to="/my-account"
          className={({ isActive }) =>
            isActive ? `text-secondary` : `text-primary-100`
          }
          onClick={() => navClicked(true)}
        >
          <div>
            <div className="flex justify-center">
              <UserIcon className="h-6 w-6" />
            </div>
            <div className="flex justify-center font-bold text-xs">Compte</div>
          </div>
        </NavLink>
      </div>
      <div
        className="col-span-1 flex justify-center items-center border-r border-gray-200 p-2"
        onClick={() => navClicked(false)}
      >
        <FooterSearch navClicked={clicked} />
      </div>
      <div className="col-span-1 flex justify-center items-center p-2 cursor-pointer">
        <div>
          <NavLink
            to="/wishlist"
            className={({ isActive }) =>
              isActive ? `text-secondary` : `text-primary-100`
            }
            onClick={() => navClicked(true)}
          >
            <div className="ml-7 -mt-1 flex justify-center items-center h-3 w-3 bg-red-600 rounded-full text-white p-3
            absolute">
              <span>{whishlistItems.length}</span>
            </div>
            <div className="flex justify-center">
              <HeartIcon className="h-6 w-6" />
            </div>
            <div className="flex justify-center font-bold text-xs">
              WishList
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
