import React from "react";
import Banner from "../../../shared/components/banners/banner";
import ContactForm from "../components/contact-form.component";
import ContactInfo from "../components/contact-info.component";

/**
 * Contact page
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-27
 *
 * @returns {JSX.Element} the jsx element
 */
export default function ContactPage(): JSX.Element {
  return (
    <>
      <Banner title="Nous contacter" />
      <div
        className="mt-14 grid grid-cols-1 xl:grid-cols-7 lg:grid-cols-7
        md:grid-cols-7 xl:gap-12 lg:gap-12 md:gap-12"
      >
        <div className="col-span-3 mb-8">
          <iframe
            width="100%"
            height="100%"
            frameBorder="0"
            referrerPolicy="no-referrer-when-downgrade"
            src={`https://www.google.com/maps/embed/v1/place?q=Biyem-Assi,\
              +Yaoundé,+Cameroon&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8`}
            allowFullScreen={true}
          ></iframe>
        </div>
        <div className="col-span-4">
          <div>
            <h2 className="text-primary font-bold text-3xl mb-4">
              Contactez-nous pour en savoir plus
            </h2>
            <p className="mb-8 leading-7 text-justify">
              Contactez-nous directement pour plus d&apos;informations ou
              laissez un message ci-dessous et nous vous répondrons dans les
              plus brefs délais.
            </p>
          </div>
          <div className="border-b border-gray-200 pb-8 mb-8">
            <ContactInfo />
          </div>
          <div>
            <h2 className="text-primary font-bold text-3xl mb-8">
              Laisser un message
            </h2>
            <ContactForm />
          </div>
        </div>
      </div>
    </>
  );
}
