import React, { useState } from "react";
import Banner from "../../../shared/components/banners/banner";
import PdfViewer from "../components/pdf-viewer";

/**
 * Blog page
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-06
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function SchoolProgramPage(): JSX.Element {
  const [stateCategory, setStateCategory] = useState("all");
  const [stateTag, setStateTag] = useState("all");

  /**
   * Handle category change
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} category the product's category
   * @returns {void}
   */
  const handleCategoryChange = (category: any): void => {
    setStateCategory(category.category);
  };

  /**
   * Handle tag change
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-06-28
   *
   * @param {any} tag the product's tag
   * @returns {void}
   */
  const handleTagChange = (tag: any): void => {
    setStateTag(tag.tag);
  };

  return (
    <div>
      <Banner title="Programme scolaire" />
      <div className="grid grid-cols-7 mt-12">
        <div
          className="col-span-7 w-full xl:col-span-5 xl:col-start-2 lg:col-span-5 lg:col-start-2
          md:col-span-5 md:col-start-2">
          <h2 className="font-bold text-primary text-3xl uppercase">
            Liste officielle des manuels scolaires
          </h2>
          <div className="mt-8">
            <PdfViewer />
          </div>
        </div>
        {/* <div className="col-span-2 pl-8 hidden xl:block lg:block">
          <div className="mb-10">
            <SearchBox />
          </div>
          <div>
            <PostFilter
              onCategoryChange={handleCategoryChange}
              onTagChange={handleTagChange}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}
