import React, { useEffect, useState } from "react";
import HeaderLogo from "../../headerlogo/header-logo";
import HeaderCart from "../../headergroupactions/header-cart";
import HeaderVerticalMenu from "../../headermenu/header-vertical-menu";

/**
 * Contain sticky header component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function ContainStickyHeader(): JSX.Element {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    /**
     * Listen to Scroll
     *
     * @author Valentin magde <valentinmagde@gmail.com>
     * @since 2023-07-29
     *
     * @return {void}
     */
    const listenToScroll = () => {
      if (window.pageYOffset > 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [scrolled]);

  return (
    <div className="flex items-center bg-white shadow border-b border-gray-200">
      <header className="relative w-full">
        <div
          className="flex py-4 justify-between items-center grid grid-cols-3
          gap-4 px-4 sm:px-6 lg:px-8"
        >
          <div className="col-span-1 flex items-center">
            <HeaderVerticalMenu />
          </div>
          <div className="col-span-1 w-full flex justify-center items-center">
            <HeaderLogo />
          </div>
          <div className="col-span- flex justify-end items-center">
            <HeaderCart />
          </div>
        </div>
      </header>
    </div>
  );
}
