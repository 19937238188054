import React from "react";
import { Routes } from "react-router-dom";
// import SellerRoute from "../components/route/SellerRoute";

/**
 * Seller route
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-27
 *
 * @returns {JSX.Element} the javaScriptXML element
 */
export default function SellerRoutes(): JSX.Element {
  return (
    <Routes>
      {/* <Route
              path="/productlist/seller"
              element={
                <SellerRoute>
                  <ProductListPage />
                </SellerRoute>
              }
            />
            <Route
              path="/orderlist/seller"
              element={
                <SellerRoute>
                  <OrderListPage />
                </SellerRoute>
              }
            /> */}
    </Routes>
  );
}
