import React from "react";
import HeaderAccount from "./header-account";
import HeaderWishlist from "./header-wishlist";
import HeaderCart from "./header-cart";

/**
 * Header group actions component
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function HeaderGroupActions(): JSX.Element {
  return (
    <div className="flex">
      <div className="grid grid-cols-3 gap-4 flex items-center">
        <div className="col-span-1">
          <HeaderAccount />
        </div>
        <div className="col-span-1">
          <HeaderWishlist />
        </div>
        <div className="col-span-1">
          <HeaderCart />
        </div>
      </div>
    </div>
  );
}
