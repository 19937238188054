import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingBox from "../../../../shared/components/others/loading-box";
import MessageBox from "../../../../shared/components/others/message-box";
import OrderReceiveType from "../types/order-receive.type";
import orderService from "../../../../shared/api/order-service/order/order.service";
import paymentMethodService from "../../../../shared/api/pament-service/payment-method/payment-method.service";
import userService from "../../../../shared/api/user-service/user/user.service";
import { _date, numberFormatter } from "../../../../shared/utils/helper.util";
import PaymentMethodType from "../../../../shared/api/pament-service/payment-method/payment-method.type";
import StateType from "../../../../shared/api/user-service/state/state.type";
import CountryType from "../../../../shared/api/user-service/country/country.type";
import countryService from "../../../../shared/api/user-service/country/country.service";
import stateService from "../../../../shared/api/user-service/state/state.service";

/**
 * Order receive page
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-06-28
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function OrderReceivePage(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [order, setOrder] = useState<any>();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>();
  const [state, setState] = useState<StateType>();
  const [country, setCountry] = useState<CountryType>();
  const [user, setUser] = useState<any>();
  const params = useParams();
  const { id: orderId } = params;

  // Get order by id
  useEffect(() => {
    if (orderId) {
      orderService.getOrderById(orderId).subscribe({
        next: (data) => {
          // Stop loader
          setIsLoading(false);

          setOrder(data);
        },
        error: (e) => {
          // Stop loader
          setIsLoading(false);

          setError(e);
        },
      });
    }
  }, [orderId]);

  // Get payment method by id
  useEffect(() => {
    if (order) {
      paymentMethodService
        .getPaymentMethodById(order.payment_method)
        .subscribe({
          next: (data) => setPaymentMethod(data),
          error: (e) => console.error(e),
        });
    }
  }, [order]);

  // Get user by id
  useEffect(() => {
    if (order && order.user) {
      userService.profile(order.user).subscribe({
        next: (data) => setUser(data),
        error: (e) => console.error(e),
      });
    }
  }, [order]);

  // Get country by id
  useEffect(() => {
    if (order) {
      countryService.getCountryById(order.shipping_address.country).subscribe({
        next: (data) => setCountry(data),
        error: (e) => console.error(e),
      });
    }
  }, [order]);

  // Get State by id
  useEffect(() => {
    if (order) {
      stateService.getStateById(order.shipping_address.state).subscribe({
        next: (data) => setState(data),
        error: (e) => console.error(e),
      });
    }
  }, [order]);

  return isLoading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div>
      {/* <h1>Order {order._id}</h1> */}
      <div className="mt-12 py-16 bg-gray-100">
        <h2
          className="flex justify-center items-center text-primary text-xl
              font-bold w-full"
        >
          Merci. Votre commande a été reçue.
        </h2>
      </div>
      <div className="mt-8 grid grid-cols-8">
        <div className="col-start-2 col-span-6">
          <div
            className="relative w-full grid xl:grid-cols-5 lg:grid-cols-5
            md:grid-cols-5 sm:grid-cols-1 grid-cols-1 break-words"
          >
            <div className="col-span-1 flex items-center">
              <div
                className="xl:flex lg:flex md:flex flex-none
                    justify-center items-center px-4 py-5 w-full"
              >
                <div
                  className="text-center xl:text-left lg:text-left
                        md:text-left w-full"
                >
                  <div className="text-gray-500 leading-6">
                    Numéro de commande
                  </div>
                  <div className="font-medium leading-5">
                    {order?.order_number}
                  </div>
                </div>
              </div>
              <span
                className="h-6 w-px xl:block lg:block md:hidden
                        sm:hidden hidden border border-y-transparent
                        border-x-gray-400 border-dashed"
                aria-hidden="true"
              />
            </div>
            <div className="col-span-1 flex items-center">
              <div
                className="xl:flex lg:flex md:flex flex-none
                    justify-center items-center px-4 py-5 w-full"
              >
                <div
                  className="text-center xl:text-left lg:text-left
                        md:text-left"
                >
                  <div className="text-gray-500 leading-6">Date</div>
                  <div className="font-medium leading-5">
                    {_date(order?.created_at)}
                  </div>
                </div>
              </div>
              <span
                className="h-6 w-px xl:block lg:block md:hidden
                        sm:hidden hidden border border-y-transparent
                        border-x-gray-400 border-dashed"
                aria-hidden="true"
              />
            </div>
            <div className="col-span-1 flex items-center">
              <div
                className="xl:flex lg:flex md:flex flex-none
                    justify-center items-center px-4 py-5 w-full"
              >
                <div
                  className="text-center xl:text-left lg:text-left
                        md:text-left w-full"
                >
                  <div className="text-gray-500 leading-6">E-mail</div>
                  <div className="font-medium leading-5">
                    {order?.shipping_address?.email}
                  </div>
                </div>
              </div>
              <span
                className="h-6 w-px xl:block lg:block md:hidden
                        sm:hidden hidden border border-y-transparent
                        border-x-gray-400 border-dashed"
                aria-hidden="true"
              />
            </div>
            <div className="col-span-1 flex items-center">
              <div
                className="xl:flex lg:flex md:flex flex-none
                    justify-center items-center px-4 py-5 w-full"
              >
                <div
                  className="text-center xl:text-left lg:text-left
                        md:text-left w-full"
                >
                  <div className="text-gray-500 leading-6">Total</div>
                  <div className="font-medium leading-5">
                    {numberFormatter(order?.total_price)}
                  </div>
                </div>
              </div>
              <span
                className="h-6 w-px xl:block lg:block md:hidden
                        sm:hidden hidden border border-y-transparent
                        border-x-gray-400 border-dashed"
                aria-hidden="true"
              />
            </div>
            <div className="col-span-1 flex items-center">
              <div
                className="xl:flex lg:flex md:flex flex-none
                    justify-start items-center px-4 py-5 w-full"
              >
                <div
                  className="text-center xl:text-left lg:text-left
                        md:text-left w-full"
                >
                  <div className="text-gray-500 leading-6">
                    Moyen de paiement
                  </div>
                  <div className="font-medium leading-5">
                    {paymentMethod?.name}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-14">
            <h2 className="text-center text-primary text-xl font-bold mb-4">
              Détails de la commande
            </h2>
            <div
              className="flex justify-between font-bold border-gray-200
              border-b"
            >
              <div>Produit</div>
              <div>Total</div>
            </div>
            {order?.order_items.map((item: OrderReceiveType) => (
              <div key={item._id}>
                <div
                  className="grid grid-cols-2 items-center py-4
                  border-gray-200 border-b"
                >
                  <div className="col-span-1">
                    <Link to={`/product/${item._id}`} className="mr-1">
                      {item.name}
                    </Link>
                    <span className="font-bold">x {item.qty}</span>
                  </div>
                  <div className="col-span-1 text-right">
                    {numberFormatter(item.qty * item.price)}
                  </div>
                </div>
              </div>
            ))}
            <div className="font-bold">
              <div
                className="grid grid-cols-2 items-center py-4
                border-gray-200 border-b"
              >
                <div className="col-span-1">Sous-total</div>
                <div className="col-span-1 text-right">
                  {numberFormatter(order?.items_price)}
                </div>
              </div>

              <div
                className="grid grid-cols-2 items-center py-4
                border-gray-200 border-b"
              >
                <div className="col-span-1">Expédition</div>
                <div className="col-span-1 text-right">
                  {numberFormatter(order?.shipping_price)}
                  {order.shipping_method && (
                    <span className="font-normal text-xs">
                      &nbsp;via {order?.shipping_method?.name}
                    </span>
                  )}
                </div>
              </div>

              <div
                className="grid grid-cols-2 items-center py-4
                border-gray-200 border-b"
              >
                <div className="col-span-1">Tax</div>
                <div className="col-span-1 text-right">
                  {numberFormatter(order?.tax_price)}
                </div>
              </div>

              <div
                className="grid grid-cols-2 items-center py-4
                border-gray-200 border-b"
              >
                <div className="col-span-1">Total</div>
                <div className="col-span-1 text-xl text-secondary text-right">
                  {numberFormatter(order?.total_price)}
                </div>
              </div>
            </div>
          </div>
          <div
            className="grid grid-cols-1 gap-6 xl:grid-cols-2 lg:grid-cols-2
            md:grid-cols-2 mt-12"
          >
            {order?.shipping_address && (
              <div className="col-span-1">
                <h2 className="text-primary text-xl font-bold mb-4">
                  Adresse de facturation
                </h2>
                <div className="leading-8 italic">
                  <p>{order?.shipping_address?.full_name}</p>
                  <p>{order?.shipping_address?.company}</p>
                  <p>{order?.shipping_address?.address}</p>
                  <p>{state?.name}</p>
                  <p>{order?.shipping_address?.city}</p>
                  <p>{order?.shipping_address?.postal_code}</p>
                  <p>{country?.name}</p>
                </div>
              </div>
            )}
            {user?.delivery_address && (
              <div className="col-span-1">
                <h2 className="text-primary text-xl font-bold mb-4">
                  Adresse de livraison
                </h2>
                <div className="leading-8 italic">
                  <p>
                    {user?.delivery_address?.first_name && (
                      <span>{user?.delivery_address?.first_name}&nbsp;</span>
                    )}
                    {user?.delivery_address?.last_name && (
                      <span>{user?.delivery_address?.last_name}</span>
                    )}
                  </p>
                  <p>{user?.delivery_address?.company}</p>
                  <p>{user?.delivery_address?.address1}</p>
                  <p>{user?.delivery_address?.state?.name}</p>
                  <p>{user?.delivery_address?.city}</p>
                  <p>{user?.delivery_address?.postal_code}</p>
                  <p>{user?.delivery_address?.country?.name}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
