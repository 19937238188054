import { Observable, defer, map } from "rxjs";
import { AxiosResponse } from "axios";
import axiosInstance from "../../../utils/http-client.util";

/**
 * Tag service
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-10-07
 *
 * class TagService
 */
class TagService {
  public lang = "en";

  /**
   * Get all tags
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-10-07
   *
   * @returns {Promise<void>} the eventual completion or failure
   */
  public getAll(): Observable<any> {
    return defer(() => axiosInstance.get(`/v1/${this.lang}/blog/tags`)).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }

  /**
   * Get tag by id
   *
   * @author Valentin magde <valentinmagde@gmail.com>
   * @since 2023-10-07
   *
   * @param {any} tagId the tag's id
   * @returns {Observable<any>} the eventual completion or failure
   */
  public getById(tagId: any): Observable<any> {
    return defer(() =>
      axiosInstance.get(`/v1/${this.lang}/blog/tag/${tagId}`)
    ).pipe(
      map((axiosResponse: AxiosResponse) => {
        return axiosResponse.data;
      })
    );
  }
}

const tagService = new TagService();
export default tagService;
