import React from "react";
import Banner from "../../../shared/components/banners/banner";
import ForgotPassword from "../../../shared/components/others/forgot-password";
/**
 * Forgot password page
 *
 * @author Valentin magde <valentinmagde@gmail.com>
 * @since 2023-07-22
 *
 * @returns {JSX.Element} the javascript xml element
 */
export default function ForgotPasswordPage(): JSX.Element {
  return (
    <div className="h-full">
      <div className="">
        <Banner title="Connexion" />
      </div>
      <div className="flex h-fit items-center justify-center pt-10">
        <div className="w-full max-w-lg space-y-4">
          <h2
            className="text-xl font-bold tracking-tight
                        text-primary"
          >
            Mot de passe oublié
          </h2>
          <p className="tracking-tight text-primary">
            Mot de passe perdu ? Veuillez saisir votre adresse e-mail. Vous
            recevrez un lien par e-mail pour créer un nouveau mot de passe.
          </p>
          <ForgotPassword />
        </div>
      </div>
    </div>
  );
}
